import { Box, Typography } from "@mui/material";
import { RetroButton } from "components/RetroButton";
import { DarkDrawer } from "components/TradeDrawer";
import { MIN_ENTRY_USD } from "consts";
import theme from "theme";

export const AmountTooSmallDrawer = ({ isOpen, close }: { isOpen: boolean; close: () => void }) => {
  return (
    <DarkDrawer open={isOpen} anchor="bottom" onClose={() => {}} sx={{ zIndex: 200 }}>
      <Box>
        <Typography variant="title-h2">Amount is too small</Typography>
      </Box>
      <Box marginTop={theme.spacing(1)}>
        <Typography variant="body-md" color={theme.palette.text.secondary}>
          The minimum amount per trade is ${MIN_ENTRY_USD}
        </Typography>
      </Box>
      <Box marginTop={theme.spacing(1)}>
        <RetroButton onClick={close}>
          <Typography variant="title-h3" sx={{ paddingY: theme.spacing(1) }}>
            OK
          </Typography>
        </RetroButton>
      </Box>
    </DarkDrawer>
  );
};
