import axiosInstance from "./axiosInstance"; // Import your configured instance
import axiosService from "./axios";

export type UserData = {
  userData: unknown;
};

// let accessToken: string | null = localStorage.getItem("accessToken");
// let refreshToken: string | null = localStorage.getItem("refreshToken");
// let refreshSubscribers: ((token: string) => void)[] = [];

let loggedInUser = localStorage.getItem("logged_in_user");

const setLoggedIn = (
  userName: string | null,
  accessToken?: string | null,
  refreshToken?: string | null,
) => {
  if (!userName) {
    localStorage.removeItem("logged_in_user");
  } else {
    localStorage.setItem("logged_in_user", userName);
  }
  // accessToken = access;
  // refreshToken = refresh;
  if (!accessToken || !refreshToken) {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
  } else {
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("accessToken", accessToken);
  }
};

const getLoggedInUser = () => loggedInUser || localStorage.getItem("logged_in_user");

const tgAuth = async (userData: UserData) => {
  try {
    const response = await axiosInstance.post(
      process.env.REACT_APP_API_URL + "/v1/auth/register",
      userData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    const { message, status, username } = response.data;

    if (username) {
      setLoggedIn(username);
    } else if (status === 404) {
      throw new Error(message);
    } else {
      throw new Error("Failed to authenticate");
    }

    return response.data;
  } catch (error) {
    try {
      const response = await axiosInstance.post(
        "/v1/auth/login", // No need for full URL since baseURL is configured
        userData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      const { message, status, user, tokens } = response.data;

      if (user.tgUserName) {
        setLoggedIn(user.tgUserName, tokens.access.token, tokens.refresh.token);
      } else if (status === 404) {
        throw new Error(message);
      } else {
        throw new Error("Failed to authenticate");
      }

      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

const getUser = async (tgUserId: string) => {
  try {
    return await axiosService.getUser(tgUserId);
  } catch (error) {
    throw new Error("Failed to authenticate");
  }
};

const removeTokens = () => {
  setLoggedIn(null);
};

export { setLoggedIn, getLoggedInUser, tgAuth, removeTokens, getUser };
