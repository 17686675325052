import { useTradeContext } from "contexts/TradeContext";
import { useEffect, useState } from "react";
import { useSignalAbort } from "./useSignalAbort";
import { useQuery } from "@tanstack/react-query";
import axiosService from "services/axios";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import useToastStore from "store/toast-store/useToastStore";
import useTradeStore from "store/trade-store.ts/useTradeStore";
import { NumpadKey } from "components/TradeDrawer";
import { InsufficientPriceHint } from "components/CrossChainSwapDrawerV2/priceHint";
import { CHAIN_CONFIG, EVM_CHAINS } from "chainConfigs";
import { Mode, TRADING_MODE_CONFIG_MAP } from "tradeConfigs";
import { useTxnSign } from "./useTxnSign";
import { SOLANA, TON } from "consts";
import { TradeType } from "lib/utils";

export const useTradeDrawer = ({
  tgGroupId,
  isFromTrendingCall,
  defaultInputUsd,
  onConfirm,
  onProcessing,
  onProcessed,
  onClose,
  onFail,
  propsMode,
}: {
  tgGroupId?: number;
  isFromTrendingCall?: boolean;
  defaultInputUsd?: number;
  onConfirm?: (message?: string) => void;
  onProcessing?: () => void;
  onProcessed?: () => void;
  onClose?: () => void;
  onFail?: (message?: string) => void;
  propsMode?: Mode;
}) => {
  const {
    // Mode
    mode: tradeMode,
    // Native Token
    selectedNativeChain,
    nativeTokenPrice,
    nativeBalanceUsd,
    // Target Token
    selectedTargetChain,
    targetTokenConfig,
    targetTokenPrice,
    targetBalanceUsd,
    nativeToTargetTokenRatio,
    // Others
    senderAddress,
    isTrading,
    destinationAddress,
    getNativePriceByChain,
    refetchTargetTokenBalance,
    handleSetMode,
    handleSetIsTrading,
    targetBalanceAmount,
    // Polling
    setIsPolling,
    setIsTonBoardcasting,
    setTxnHash,
  } = useTradeContext();

  // Input Value
  const [inputUsdString, setInputUsdString] = useState("0"); // (USD)
  const [targetAmount, setTargetAmount] = useState(0); // (amount)
  const [nativeAmount, setNativeAmount] = useState(0); // (amount)

  // Reminder Drawer
  const [isOpenConfirmDrawer, setIsOpenConfirmDrawer] = useState(false);

  // Flags
  const [isInsufficientBalance, setIsInsufficientBalance] = useState(false);
  const [isSellAll, setIsSellAll] = useState(false);

  // Toast
  const { showToast } = useToastStore();
  const {
    isLoading: isNativeBalanceLoading,
    updateUserBalance,
    getUserBalance,
    nativeToken,
  } = useTradeStore();

  const { signAndBroadcastTxn, signAndBroadcastSolanaTxn, signAndBroadcastJettonTxn } =
    useTxnSign();

  const mode = tradeMode === "SWAP" ? "BUY" : tradeMode;
  const tradeModeConfig = TRADING_MODE_CONFIG_MAP[mode];
  const gasBuffer =
    selectedNativeChain === selectedTargetChain
      ? tradeModeConfig.minimumGasBufferUSD![selectedNativeChain] ?? 0
      : tradeModeConfig.minimumCrossChainGasBufferUSD![selectedNativeChain] ?? 0;

  const isTraddingWrappedToken =
    CHAIN_CONFIG[selectedTargetChain].wrappedTokenAddress ===
    targetTokenConfig[selectedTargetChain]?.token;

  // Init
  useEffect(() => {
    if (mode === "BUY") {
      setInputUsdString(defaultInputUsd ? defaultInputUsd.toString() : "0");
    } else {
      setInputUsdString("0");
    }

    setIsSellAll(false);
    setIsInsufficientBalance(false);
  }, [mode, selectedNativeChain, defaultInputUsd]);

  // Update Input Amount from USD
  useEffect(() => {
    if (targetTokenPrice) {
      setTargetAmount(+inputUsdString / targetTokenPrice);
    }
    if (nativeTokenPrice) {
      setNativeAmount(+inputUsdString / nativeTokenPrice);
    }
  }, [inputUsdString, targetTokenPrice, nativeTokenPrice]);

  // Check Insufficient Balance
  useEffect(() => {
    if (mode === "BUY") {
      const MIN_AMOUNT = 0;
      const clampedAmount = Math.max(MIN_AMOUNT, +nativeBalanceUsd! - (gasBuffer ?? 0));

      setIsInsufficientBalance(+inputUsdString !== 0 && +inputUsdString > clampedAmount);
    }

    if (mode === "SELL") {
      const targetNativeBalanceUsd = getUserBalance(selectedTargetChain, "usd")!;
      const balance = isTraddingWrappedToken ? targetNativeBalanceUsd : targetBalanceUsd;
      setIsInsufficientBalance(
        (+inputUsdString !== 0 && +inputUsdString > +balance!) ||
          targetNativeBalanceUsd < gasBuffer,
      );
    }
  }, [
    selectedNativeChain,
    inputUsdString,
    nativeToken,
    gasBuffer,
    mode,
    nativeBalanceUsd,
    getUserBalance,
    selectedTargetChain,
    isTraddingWrappedToken,
    targetBalanceUsd,
  ]);

  // Start Trading
  const handleConfirm = (slippage?: number) => {
    // Checks
    if (!slippage && selectedTargetChain !== TON) {
      console.error("Slippage is not set");
      return;
    }

    handleConfirmClick?.(slippage);
    setIsOpenConfirmDrawer(false);
    handleSetMode("BUY");
  };

  // Confirm
  const handleOpenConfirmDrawer = (isOpen: boolean) => {
    setIsOpenConfirmDrawer(isOpen);
  };

  // Close Drawer
  const handleOnClose = () => {
    if (mode === "BUY") {
      setInputUsdString(defaultInputUsd ? defaultInputUsd.toString() : "0");
    } else {
      setInputUsdString("0");
    }
    setTargetAmount(0);
    setNativeAmount(0);
    setIsSellAll(false);
    setIsInsufficientBalance(false);
    handleSetMode("BUY");
    onClose?.();
  };

  // Numpad Press
  const handleNumpadPress = (key: NumpadKey) => {
    setIsSellAll(false);
    if (key === "delete") {
      setInputUsdString("0");
    } else if (key === ".") {
      if (!inputUsdString.includes(".")) {
        setInputUsdString(inputUsdString + key);
      }
    } else {
      setInputUsdString(inputUsdString + key);
    }
  };

  // Percent Press
  const handlePercentPress = (percent: number) => {
    if (mode === "BUY") {
      const MIN_AMOUNT = 0;
      const clampedAmount = Math.max(MIN_AMOUNT, (+nativeBalanceUsd! - (gasBuffer ?? 0)) * percent);
      setInputUsdString(`${+clampedAmount}`);
      setIsInsufficientBalance(
        +clampedAmount > +nativeBalanceUsd! ||
          (+nativeBalanceUsd! - (gasBuffer ?? 0)) * percent < 0,
      );
    } else {
      const balance = isTraddingWrappedToken
        ? getUserBalance(selectedTargetChain, "usd")
        : targetBalanceUsd;

      // For handling the 18 decimal places issue on EVMs 100% sell
      if (percent === 1) {
        setIsSellAll(true);
        setInputUsdString(`${+balance! * percent}`);
      } else {
        setIsSellAll(false);
        setInputUsdString(`${+balance! * percent}`);
      }
    }
  };

  // Handle Confirm
  const handleConfirmClick = async (slippage?: number) => {
    try {
      const isEnoughBalance = getUserBalance(selectedNativeChain, "usd")! >= (gasBuffer ?? 0);

      if (!isEnoughBalance) {
        onFail?.("Insufficient balance to pay for gas");
        onProcessed?.();
        onClose?.();
        return;
      }

      handleSetIsTrading(true);
      showToast({
        variant: "info",
        message:
          "Your transaction is being broadcasted. This typically takes around 30 seconds. Please hold tight!",
      });
      onProcessing?.();
      onClose?.();

      let result;
      let chain = TON;
      let masterAddress = targetTokenConfig[selectedTargetChain].token!;
      if (selectedTargetChain === TON && mode === "BUY") {
        // Buy Jetton
        if (selectedTargetChain === selectedNativeChain) {
          // console.debug('tonResult: ', tonResult);

          // calculate quantity before execute
          const tonAmount = nativeAmount;
          const isEnoughBalance = getUserBalance(selectedNativeChain, "amount")! > tonAmount;

          if (!senderAddress) {
            onFail?.("Please connect your wallet");
            onProcessed?.();
            onClose?.();
            return;
          }

          if (!isEnoughBalance) {
            onFail?.("Insufficient balance to buy Jetton");
            onProcessed?.();
            onClose?.();
            return;
          }
          const signedTxn = await signAndBroadcastJettonTxn(
            masterAddress,
            tonAmount,
            TradeType.BUY,
          );
          if (signedTxn) {
            const payload = {
              tonAmount: tonAmount,
              masterAddress: masterAddress,
              tgGroupId: tgGroupId!,
              tradeType: TradeType.BUY,
              walletAddress: senderAddress,
              boc: signedTxn.boc,
              fee: signedTxn.fee,
            };
            result = await axiosService.buyTonJetton(payload);
          }
        } else {
          // Bridge
          const payload = {
            tonAmt: (nativeAmount * nativeTokenPrice!) / getNativePriceByChain(TON)!,
            chain: selectedNativeChain,
            tgGroupId: tgGroupId!,
            masterAddress,
          };

          result = await axiosService.buyJettonFromBridge(payload);
          chain = selectedNativeChain;
        }
      } else if (selectedTargetChain === TON && mode === "SELL") {
        // Sell Jetton
        const masterAddress = targetTokenConfig[selectedTargetChain].token!;
        const jettonAmt =
          isSellAll && targetBalanceAmount
            ? targetBalanceAmount
            : nativeAmount * nativeToTargetTokenRatio!;

        const signedTxn = await signAndBroadcastJettonTxn(masterAddress, jettonAmt, TradeType.SELL);

        if (signedTxn) {
          const payload = {
            jettonAmt,
            masterAddress,
            tgGroupId: tgGroupId!,
            tradeType: TradeType.SELL,
            walletAddress: senderAddress,
            boc: signedTxn.boc,
            fee: signedTxn.fee,
          };
          result = await axiosService.sellTonJetton(payload);
        }
      } else if (selectedTargetChain !== TON) {
        chain = tradeModeConfig.from.isNative ? selectedNativeChain : selectedTargetChain;
        const payload: {
          srcToken: string;
          destToken: string;
          senderAddress: string;
          nativeSpent: number;
          slippage: number;
          srcChain: string;
          destChain: string;
          tradeType: string;
          isSellAll: boolean;
          priceUsd: number;
          tgGroupId?: number;
          isFromTrendingCall?: boolean;
          destinationAddress?: string;
          dstChainOrderAuthorityAddress?: string;
          isSwap?: boolean;
        } = {
          srcToken: tradeModeConfig.from.isNative
            ? CHAIN_CONFIG[selectedNativeChain].nativeTokenAddress!
            : targetTokenConfig[selectedTargetChain].token!,
          destToken: tradeModeConfig.to.isNative
            ? CHAIN_CONFIG[selectedNativeChain].nativeTokenAddress!
            : targetTokenConfig[selectedTargetChain].token!,
          senderAddress,
          nativeSpent: tradeModeConfig.from.isNative ? nativeAmount : targetAmount,
          slippage: slippage!,
          tradeType: mode,
          isSellAll,
          srcChain: chain,
          destChain: tradeModeConfig.from.isNative ? selectedTargetChain : selectedNativeChain,
          priceUsd: +inputUsdString,
          ...(tgGroupId ? { tgGroupId } : {}),
          ...(isFromTrendingCall ? { isFromTrendingCall } : {}),
          ...(destinationAddress && selectedTargetChain !== selectedNativeChain
            ? { destinationAddress }
            : {}),
          ...(destinationAddress && selectedTargetChain !== selectedNativeChain
            ? { dstChainOrderAuthorityAddress: destinationAddress }
            : {}),
          ...(propsMode === "SWAP" ? { isSwap: true } : {}),
        };

        result = await axiosService.crossChainTrade(payload);
      }

      if (result.status < 300) {
        if (selectedTargetChain === TON && selectedTargetChain === selectedNativeChain) {
          showToast({ variant: "success", message: result.message });
        } else {
          let callData;
          try {
            callData = JSON.parse(result.callData);
          } catch (e) {
            callData = { calldatas: result.callData };
          }
          // console.debug("callData: ", callData);

          let hash;
          let signedTxn;

          if (chain === SOLANA) {
            signedTxn = await signAndBroadcastSolanaTxn(senderAddress, callData.calldatas);
          } else {
            hash = await signAndBroadcastTxn(senderAddress, chain, callData);
          }
          // console.debug("hash: ", hash);
          // console.debug("signedTxn: ", signedTxn);

          if (hash || signedTxn) {
            setTxnHash(hash);

            if (propsMode !== "SWAP") {
              showToast({
                variant: "info",
                message: "Your transaction is broadcasted. Pending confirmation.",
              });
              // call update trade
              const res = await axiosService.updateTrade({
                hash,
                signedTxn,
                tradeId: result.tradeId,
              });

              let variant: "success" | "error" = "success";
              if (res.status >= 300) variant = "error";
              onProcessed?.();
              return showToast({ variant, message: res.message });
            }

            showToast({ variant: "success", message: "Your transaction is executed." });
          }
        }

        // Add polling logic for balance update
        const initialBalance = targetBalanceAmount;
        let attempts = 0;
        const maxAttempts = 10;
        const pollInterval = 3000; // 3 seconds

        setIsPolling(true);

        if (propsMode !== "SWAP") {
          setIsTonBoardcasting(tradeModeConfig.from.isNative);

          const pollForBalanceUpdate = async () => {
            while (attempts < maxAttempts) {
              const newBalance = await refetchTargetTokenBalance();
              if (newBalance?.data?.quantity !== initialBalance) {
                break;
              }
              attempts++;
              await new Promise((resolve) => setTimeout(resolve, pollInterval));
            }
          };

          await pollForBalanceUpdate();
          setIsPolling(false);
        }

        onProcessed?.();
      } else {
        if (result.message.includes("Execution reverted")) {
          showToast({
            variant: "error",
            message: "Broadcast unsuccessful. Please retry.",
            duration: 10000,
          });
        } else {
          showToast({ variant: "error", message: result.message, duration: 10000 });
        }
        onFail?.(result.message);
        console.error("Transaction Failed: ", result);
        onProcessed?.();
        onClose?.();
      }
    } catch (error) {
      console.error(error);
      showToast({ variant: "error", message: "Transaction Failed", duration: 10000 });
      onFail?.("Something went wrong. Please try again.");
    } finally {
      handleSetIsTrading(false);
      updateUserBalance();
    }
  };

  return {
    // Sum
    inputUsd: +inputUsdString,
    targetAmount: targetAmount,
    nativeAmount: nativeAmount,
    shouldConfirmDisabled: isInsufficientBalance || isTrading || isNativeBalanceLoading,
    isSellAll,
    isOpenConfirmDrawer,
    handleOpenConfirmDrawer: handleOpenConfirmDrawer,
    onConfirm: handleConfirm,
    onNumpadPress: handleNumpadPress,
    onClose: handleOnClose,
    onPercentPress: handlePercentPress,
    priceHint: isInsufficientBalance ? <InsufficientPriceHint /> : null,
  };
};

export const useApproval = ({ targetAmount }: { targetAmount: number }) => {
  const { mode, targetTokenConfig, selectedNativeChain, selectedTargetChain, senderAddress } =
    useTradeContext();
  const tradeModeConfig = TRADING_MODE_CONFIG_MAP[mode];

  // Approval Logic
  const [tempAllowanceAmount, setTempAllowanceAmount] = useState(0);
  const [isApproving, setIsApproving] = useState(false);
  const [isApproveSucess, setIsApproveSucess] = useState(false);
  const { approveAllowance } = useTxnSign();

  const { showToast } = useToastStore();

  // Check if approval is needed to update
  useEffect(() => {
    // Reset approval status if token amount changes
    if (tempAllowanceAmount !== undefined) {
      if (tempAllowanceAmount < targetAmount) {
        resetAllowance();
      } else {
        setIsApproveSucess(true);
      }
    }
  }, [mode, targetAmount, tempAllowanceAmount]);

  // Fetch Current Allowance (EVMS Only)
  const {
    data: currentAllowanceAmount,
    refetch: refetchCurrentAllowance,
    isLoading: isGetCurrentAllowanceLoading,
  } = useQuery({
    queryKey: [
      "get-current-allowance",
      mode,
      CHAIN_CONFIG[selectedNativeChain]?.nativeTokenAddress,
      targetTokenConfig[selectedTargetChain]?.token,
    ],
    queryFn: () =>
      axiosService.getCurrentAllowance({
        srcToken: targetTokenConfig[selectedTargetChain].token!,
        destToken: CHAIN_CONFIG.ethereum.nativeTokenAddress,
        senderAddress: senderAddress,
        chain: selectedTargetChain,
      }),
    enabled: !!senderAddress && EVM_CHAINS.includes(selectedTargetChain) && mode === "SELL",
  });

  // Update Temp Allowance Amount from API
  useEffect(() => {
    if (currentAllowanceAmount !== undefined) {
      setTempAllowanceAmount(currentAllowanceAmount);
    } else {
      setTempAllowanceAmount(0);
    }
  }, [currentAllowanceAmount]);

  // Handle Approval
  const handleApproveAllowance = async () => {
    try {
      setIsApproving(true);
      showToast({ variant: "info", message: "Approving spending..." });

      const tokenAddress = tradeModeConfig.from.isNative
        ? CHAIN_CONFIG[selectedNativeChain].nativeTokenAddress!
        : targetTokenConfig[selectedTargetChain].token!;

      const res = await axiosService.approveAllowance({
        chain: selectedTargetChain,
        srcToken: tokenAddress,
        destToken: tradeModeConfig.to.isNative
          ? CHAIN_CONFIG[selectedNativeChain].nativeTokenAddress!
          : targetTokenConfig[selectedTargetChain].token!,
        senderAddress,
        amount: targetAmount,
      });
      const spenderAddress = res.spenderAddress;

      if (spenderAddress) {
        const amountToApprove = res.amountToApprove;
        const hash = await approveAllowance(
          selectedTargetChain,
          tokenAddress,
          senderAddress,
          spenderAddress,
          amountToApprove,
        );

        if (hash) {
          showToast({
            variant: "success",
            message: "Spending approved. Please proceed to trade.",
            duration: 5000,
          });
          setTempAllowanceAmount(targetAmount);
          return true;
        }
      } else {
        showToast({ variant: "error", message: res.message, duration: 5000 });
        throw new Error("Approval failed: ", res.message);
      }
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setIsApproving(false);
    }
  };

  // Reset Approval Status
  const resetAllowance = () => {
    setIsApproving(false);
    setIsApproveSucess(false);
  };

  return {
    isApproving,
    isApproveSucess,
    isGetCurrentAllowanceLoading,
    resetAllowance,
    setIsApproving,
    setIsApproveSucess,
    refetchCurrentAllowance,
    handleApproveAllowance,
  };
};

export const usePriceImpact = ({
  isSellAll,
  targetAmount,
  nativeAmount,
}: {
  isSellAll: boolean;
  targetAmount: number;
  nativeAmount: number;
}) => {
  const {
    mode: tradeMode,
    nativeTokenPrice,
    targetTokenPrice,
    selectedNativeChain,
    selectedTargetChain,
    targetTokenConfig,
    senderAddress,
    tgGroupId,
    destinationAddress,
  } = useTradeContext();

  const { getUserBalance } = useTradeStore();

  const { preferences = {} } = useUserStoreV2();
  const { slippage } = preferences;
  const mode = tradeMode === "SWAP" ? "BUY" : tradeMode;
  const tradeModeConfig = TRADING_MODE_CONFIG_MAP[mode];
  const gasBuffer =
    selectedNativeChain === selectedTargetChain
      ? tradeModeConfig.minimumGasBufferUSD![selectedNativeChain] ?? 0
      : tradeModeConfig.minimumCrossChainGasBufferUSD![selectedNativeChain] ?? 0;

  // Price Impact
  const [priceImpact, setPriceImpact] = useState<number | null>(null);
  const [noValidRouteMessage, setNoValidRouteMessage] = useState<string | null>(null);

  // Signal
  const { getSignal } = useSignalAbort();

  const {
    data: priceImpactData,
    refetch: refetchPriceImpact,
    isLoading: isLoadingPriceImpact,
    isRefetching: isRefetchingPriceImpact,
  } = useQuery({
    queryKey: ["priceImpact", mode, nativeAmount, targetAmount, slippage],
    queryFn: () => {
      if ((mode === "BUY" && nativeAmount === 0) || (mode === "SELL" && +targetAmount === 0)) {
        return {
          inputAmount: 0,
          inputDecimals: 0,
          outputAmount: 0,
          outputDecimals: 0,
        };
      }

      let spent;
      if (mode === "BUY") {
        spent = nativeAmount;
      } else {
        spent = +targetAmount;
      }

      const srcChain = mode === "BUY" ? selectedNativeChain : selectedTargetChain;
      const destChain = mode === "BUY" ? selectedTargetChain : selectedNativeChain;

      const tokenTradePayload = {
        srcToken: tradeModeConfig.from.isNative
          ? CHAIN_CONFIG[selectedNativeChain].nativeTokenAddress!
          : targetTokenConfig[selectedTargetChain].token!,
        destToken: tradeModeConfig.to.isNative
          ? CHAIN_CONFIG[selectedNativeChain].nativeTokenAddress!
          : targetTokenConfig[selectedTargetChain].token!,
        senderAddress,
        nativeSpent: spent,
        slippage: slippage ?? 0,
        tradeType: mode,
        isSellAll,
        srcChain,
        destChain,
        ...(tgGroupId ? { tgGroupId } : {}),
        ...(destinationAddress && srcChain !== destChain ? { destinationAddress } : {}),
        ...(destinationAddress && srcChain !== destChain
          ? { dstChainOrderAuthorityAddress: destinationAddress }
          : {}),
      };

      return axiosService.tokenTradePreview(tokenTradePayload, getSignal());
    },
    enabled:
      !!mode && !!selectedNativeChain && !!selectedTargetChain && !!slippage && !!senderAddress,
  });

  useEffect(() => {
    if (
      priceImpactData &&
      (priceImpactData.inputAmount === 0 || priceImpactData.outputAmount === 0)
    ) {
      setPriceImpact(null);
      setNoValidRouteMessage(null);
    }

    if (priceImpactData && priceImpactData.inputAmount) {
      const { inputAmount, inputDecimals, outputAmount, outputDecimals } = priceImpactData;
      const isEnoughBalance = getUserBalance(selectedNativeChain, "usd")! >= (gasBuffer ?? 0);

      if (!isEnoughBalance) {
        return setNoValidRouteMessage("Insufficient balance to pay for gas");
      } else if (priceImpactData.message) {
        return setNoValidRouteMessage(priceImpactData.message);
      }

      let inputPrice, outputPrice;

      const nativePrice = nativeTokenPrice!;
      const tokenPrice = targetTokenPrice!;

      if (mode === "BUY") {
        inputPrice = (inputAmount / 10 ** inputDecimals) * nativePrice;
        outputPrice = (outputAmount / 10 ** outputDecimals) * tokenPrice;
      } else {
        inputPrice = (inputAmount / 10 ** inputDecimals) * tokenPrice;
        outputPrice = (outputAmount / 10 ** outputDecimals) * nativePrice;
      }

      const priceImpact = inputPrice - outputPrice;

      setPriceImpact(priceImpact);
      setNoValidRouteMessage(null);
    } else {
      if (priceImpactData && priceImpactData.status !== 200 && priceImpactData.message) {
        console.error(priceImpactData.message);
        setNoValidRouteMessage(priceImpactData.message);
        setPriceImpact(null);
      } else {
        if (priceImpactData && priceImpactData.message) {
          console.error(priceImpactData?.message);
        }
        setPriceImpact(null);
      }
    }
  }, [priceImpactData]);

  return {
    priceImpact,
    noValidRouteMessage,
    isLoadingPriceImpact,
    isRefetchingPriceImpact,
    refetchPriceImpact,
  };
};
