import { Typography } from "@mui/material";
import { ProfileAvatar, ProfileContainer } from "./styles";
import { Person } from "@mui/icons-material";

export const HeaderProfile = ({
  onClick,
  name,
  avatar,
}: {
  onClick?: () => void;
  name: string;
  avatar?: string;
}) => {
  return (
    <ProfileContainer onClick={onClick}>
      {/* {avatar ? <ProfileAvatar src={avatar} alt={name} /> : <ProfileAvatar as={Person} />} */}
      <Typography
        variant="subtitle"
        sx={{
          minWidth: "64px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}>
        {name}
      </Typography>
    </ProfileContainer>
  );
};
