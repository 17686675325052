import { Portfolio } from "components/portfolio";
import { AuthCheckBlocker } from "components/Blockers";
import { PortfolioProvider } from "contexts/PortfolioContext";

const PortfolioPage = () => {
  return (
    <AuthCheckBlocker withCard>
      <PortfolioProvider>
        <Portfolio />
      </PortfolioProvider>
    </AuthCheckBlocker>
  );
};

export { PortfolioPage };
