import SolanaIcon from "assets/icons/chains/solana.png";
import EthereumIcon from "assets/icons/chains/ethereum.png";
import TonIcon from "assets/icons/chains/ton.png";
import BaseIcon from "assets/icons/chains/base.png";
import BeraIcon from "assets/icons/chains/bera.png";
import BinanceIcon from "assets/icons/chains/bsc.png";

export type ChainConfig = {
  name: "ton" | "solana" | "ethereum" | "base" | "berachain" | "bsc";
  symbol: "TON" | "SOL" | "ETH" | "BASE" | "BERA" | "BSC";
  currency: "TON" | "SOL" | "ETH" | "BERA" | "BNB";
  wrappedCurrency?: "WSOL" | "WETH" | "WBNB" | "WBERA";
  walletName: "tonAddress" | "solAddress" | "ethAddress";
  nativePriceName: "tonPrice" | "solPrice" | "ethPrice" | "beraPrice" | "bnbPrice";
  nativeTokenAddress: string;
  wrappedTokenAddress?: string;
  isEvm: boolean;
  imageUrl: string;
};
export type Chain = ChainConfig["name"];
export type ChainSymbol = ChainConfig["symbol"];
export type ChainCurrency = ChainConfig["currency"];
export type WrappedCurrency = ChainConfig["wrappedCurrency"];
export type ChainWalletName = ChainConfig["walletName"];
export type ChainNativePriceName = ChainConfig["nativePriceName"];

const CHAIN_CONFIG: Record<Chain, ChainConfig> = {
  ton: {
    name: "ton",
    symbol: "TON",
    currency: "TON",
    walletName: "tonAddress",
    nativePriceName: "tonPrice",
    nativeTokenAddress: "EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c",
    isEvm: false,
    imageUrl: TonIcon,
  },
  solana: {
    name: "solana",
    symbol: "SOL",
    currency: "SOL",
    walletName: "solAddress",
    nativePriceName: "solPrice",
    nativeTokenAddress: "So11111111111111111111111111111111111111112",
    wrappedCurrency: "WSOL",
    wrappedTokenAddress: "So11111111111111111111111111111111111111112",
    isEvm: false,
    imageUrl: SolanaIcon,
  },
  ethereum: {
    name: "ethereum",
    symbol: "ETH",
    currency: "ETH",
    walletName: "ethAddress",
    nativePriceName: "ethPrice",
    nativeTokenAddress: "0xEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE",
    wrappedCurrency: "WETH",
    wrappedTokenAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    isEvm: true,
    imageUrl: EthereumIcon,
  },
  base: {
    name: "base",
    symbol: "BASE",
    currency: "ETH",
    walletName: "ethAddress",
    nativePriceName: "ethPrice",
    nativeTokenAddress: "0x4200000000000000000000000000000000000006",
    wrappedCurrency: "WETH",
    wrappedTokenAddress: "0x4200000000000000000000000000000000000006",
    isEvm: true,
    imageUrl: BaseIcon,
  },
  bsc: {
    name: "bsc",
    symbol: "BSC",
    currency: "BNB",
    walletName: "ethAddress",
    nativePriceName: "bnbPrice",
    nativeTokenAddress: "0xEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE",
    wrappedCurrency: "WBNB",
    wrappedTokenAddress: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    isEvm: true,
    imageUrl: BinanceIcon,
  },
  berachain: {
    name: "berachain",
    symbol: "BERA",
    currency: "BERA",
    walletName: "ethAddress",
    nativePriceName: "beraPrice",
    nativeTokenAddress: "0xEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE",
    wrappedCurrency: "WBERA",
    wrappedTokenAddress: "0x6969696969696969696969696969696969696969",
    isEvm: true,
    imageUrl: BeraIcon,
  },
};

const CHAINS: Record<Chain, Chain> = Object.entries(CHAIN_CONFIG).reduce((acc, [key]) => {
  acc[key as Chain] = key as Chain;
  return acc;
}, {} as Record<Chain, Chain>);

const CHAIN_CHECK_LIST: Chain[] = Object.values(CHAINS);
const NATIVE_CURRENCY_CHECK_LIST: ChainCurrency[] = [
  ...Object.values(CHAIN_CONFIG).reduce((acc, chain) => {
    acc.add(chain.currency);
    return acc;
  }, new Set() as Set<ChainCurrency>),
];
const WRAPPED_CURRENCY_CHECK_LIST: WrappedCurrency[] = [
  ...Object.values(CHAIN_CONFIG).reduce((acc, chain) => {
    if (chain.wrappedCurrency) {
      acc.add(chain.wrappedCurrency);
    }
    return acc;
  }, new Set() as Set<WrappedCurrency>),
];

const EVM_CHAINS = Object.values(CHAIN_CONFIG).reduce((acc, chain) => {
  if (chain.isEvm) {
    acc.push(chain.name);
  }
  return acc;
}, [] as Chain[]);

const CHAIN_WALLETS_MAP = Object.values(CHAIN_CONFIG).reduce((acc, chain) => {
  acc[chain.name] = chain.walletName;
  return acc;
}, {} as Record<Chain, string>);

export {
  CHAIN_CONFIG,
  CHAINS,
  CHAIN_CHECK_LIST,
  NATIVE_CURRENCY_CHECK_LIST,
  WRAPPED_CURRENCY_CHECK_LIST,
  EVM_CHAINS,
  CHAIN_WALLETS_MAP,
};
