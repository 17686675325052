import { getNetwork } from "./lib/hooks/useNetwork";

const APP_VERSION = "1.0.0";
const ROUTES = {
  gated: "/",
  explorer: "/explorer",
  deployer: "/deployer",
  cabal: "/cabal",
  cabalId: "/cabal/:id",
  profile: "/profile",
  faq: "/faq",
  onboarding: "/onboarding",
  quest: "/quest",
  call: "/call",
  portfolio: "/portfolio",
  create: "/create",
  referral: "/referral",
  tokenId: "/token/:id", // should be the token address
  trade: "/trade", // should be the token address
  design: "/design", // design system page
};

const APP_GRID = 1156;

const LOCAL_STORAGE_PROVIDER = "wallet_provider";

const APP_DISPLAY_NAME = "Cabal.town";

const JETTON_DEPLOYER_CONTRACTS_GITHUB = "https://github.com/";

const EXAMPLE_ADDRESS =
  getNetwork(new URLSearchParams(window.location.search)) === "testnet"
    ? "EQBP4L9h4272Z0j_w9PE2tjHhi8OwkrRbTmatKszMyseis05"
    : "EQD-LkpmPTHhPW68cNfc7B83NcfE9JyGegXzAT8LetpQSRSm";

const SEARCH_HISTORY = "searchHistory";

const DECIMAL_SCALER = 1000000000;

const SCALING_FACTOR = 100000;

const DEFAULT_DECIMALS = 9;

/* V2 */
const MIN_ENTRY_AMOUNT = 10000;

const MIN_ENTRY_USD = 10.0;
const MIN_ENTRY_USD_BUFFER = 0.001;

const MAX_SLIPPAGE = 50;

const MIN_SLIPPAGE = 0.1;

/* Chain */
const WRAPPED_NATIVE_TOKENS_ADDRESS = {
  solana: "So11111111111111111111111111111111111111112",
  base: "0x4200000000000000000000000000000000000006",
  ethereum: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  bsc: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
};

const RPC_ENDPOINT: Record<string, string> = {
  ethereum: process.env.REACT_APP_ETHEREUM_RPC_ENDPOINT!,
  berachain: process.env.REACT_APP_BERA_RPC_ENDPOINT!,
  solana: process.env.REACT_APP_SOLANA_RPC_ENDPOINT!,
  base: process.env.REACT_APP_BASE_RPC_ENDPOINT!,
  ton: process.env.REACT_APP_TON_RPC_ENDPOINT!,
  bsc: process.env.REACT_APP_BSC_RPC_ENDPOINT!,
};

const BERACHAIN = "berachain";
const ETHEREUM = "ethereum";
const SOLANA = "solana";
const BASE = "base";
const TON = "ton";
const BSC = "bsc";

const TON_DECIMAL_SCALER = 1000000000;
const BUY_NETWORK_FEE = 50000000;
const SELL_NETWORK_FEE = 30000000; // 0.03 TON

const CHAIN_DECIMAL = {
  berachain: 1e18,
  ethereum: 1e18,
  solana: 1e9,
  base: 1e18,
  ton: 1e9,
  bsc: 1e18,
} as const;

const CHAIN_EXPLORER = {
  berachain: `https://berascan.com/tx`,
  ethereum: `https://etherscan.io/tx`,
  base: `https://basescan.org/tx`,
  solana: `https://solscan.io/tx`,
  ton: `https://tonviewer.com`,
  bsc: `https://bscscan.com/tx`,
};

export {
  ROUTES,
  LOCAL_STORAGE_PROVIDER,
  APP_GRID,
  JETTON_DEPLOYER_CONTRACTS_GITHUB,
  APP_DISPLAY_NAME,
  EXAMPLE_ADDRESS,
  SEARCH_HISTORY,
  DECIMAL_SCALER,
  SCALING_FACTOR,
  DEFAULT_DECIMALS,
  MIN_ENTRY_AMOUNT,
  MIN_ENTRY_USD_BUFFER,
  MIN_ENTRY_USD,
  MAX_SLIPPAGE,
  MIN_SLIPPAGE,
  WRAPPED_NATIVE_TOKENS_ADDRESS,
  RPC_ENDPOINT,
  BERACHAIN,
  ETHEREUM,
  SOLANA,
  BASE,
  TON,
  BSC,
  TON_DECIMAL_SCALER,
  BUY_NETWORK_FEE,
  SELL_NETWORK_FEE,
  CHAIN_DECIMAL,
  CHAIN_EXPLORER,
  APP_VERSION,
};
