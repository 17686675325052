import { VersionedTransaction } from "@solana/web3.js";
import axios from "axios";
import bs58 from "bs58";

const getSolanaTransactions = async (calldatas: string | string[] | { data: string }[]) => {
  const transactions = [];

  // Handle different input formats
  const normalizedCalldata = Array.isArray(calldatas)
    ? calldatas.map((calldata) => (typeof calldata === "string" ? { data: calldata } : calldata))
    : [{ data: calldatas as string }];

  for (let i = 0; i < normalizedCalldata.length; i++) {
    const calldata = normalizedCalldata[i];
    const messageBuffer = Buffer.from(calldata.data, "base64");
    const transaction = VersionedTransaction.deserialize(new Uint8Array(messageBuffer));
    transactions.push(transaction);
  }
  return transactions;
};

async function sendJitoBundle(signedTransactions: VersionedTransaction[]) {
  try {
    const base58Transactions = signedTransactions.map((tx) => bs58.encode(tx.serialize()));
    const response = (
      await axios.post(
        "https://mainnet.block-engine.jito.wtf/api/v1/bundles",
        {
          jsonrpc: "2.0",
          id: 1,
          method: "sendBundle",
          params: [base58Transactions],
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
    ).data;

    return response.result;
  } catch (error) {
    console.error(error);
  }
}

export { getSolanaTransactions, sendJitoBundle };
