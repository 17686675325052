import { Box, Typography } from "@mui/material";
import { RetroCard } from "components/RetroCard";
import { ReactComponent as AddBotIcon } from "assets/icons/create-cabal-add-bot.svg";
import { RetroButton } from "components/RetroButton";
import { useEffect, useState } from "react";

export const CreateCabalAddBot = ({
  onNextPage,
  onPreviousPage,
}: // handleOnSkip,
{
  onNextPage: () => void;
  onPreviousPage: () => void;
  // handleOnSkip: () => void;
}) => {
  const onAddBotToGroupClicked = () => {
    window.open(`https://t.me/${process.env.REACT_APP_TG_BOT_NAME}?startgroup=true`);
    onNextPage();
  };

  const onAddBotToChannelClicked = () => {
    window.open(
      `https://t.me/${process.env.REACT_APP_TG_BOT_NAME}?startchannel&admin=post_messages+edit_messages+delete_messages+manage_chat`,
    );
    onNextPage();
  };

  return (
    <RetroCard>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          height: "calc(100vh - 92px)",
          width: "100%",
          maxWidth: "762px",
          padding: "20px",
          gap: "16px",
          margin: "0 auto",
          textAlign: "center",
        }}>
        <Box sx={{ height: "24px" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "16px",
          }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "120px",
              height: "120px",
            }}>
            <AddBotIcon />
          </Box>
          <Box>
            <Typography variant="title-h2">ADD BOT</Typography>
          </Box>
          <Box>
            <Typography variant="body-default" color="text.secondary">
              To start trading with friends in your communities, add <b>@cabaltown_bot</b> to the
              group / channel.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            gap: "12px",
          }}>
          <Box sx={{ width: "100%" }}>
            <RetroButton onClick={onAddBotToGroupClicked}>
              <Box sx={{ padding: "12px" }}>
                <Typography variant="title-h3">ADD BOT TO GROUP</Typography>
              </Box>
            </RetroButton>
          </Box>
          <Box sx={{ width: "100%" }}>
            <RetroButton onClick={onAddBotToChannelClicked}>
              <Box sx={{ padding: "12px" }}>
                <Typography variant="title-h3">ADD BOT TO CHANNeL</Typography>
              </Box>
            </RetroButton>
          </Box>
        </Box>
      </Box>
    </RetroCard>
  );
};

const FreeModeCreateCabal = () => {};
