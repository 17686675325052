import { Box, Typography, useTheme } from "@mui/material";
import { RetroCard } from "components/RetroCard";
import { ReactComponent as GroupModeIcon } from "assets/icons/create-cabal-group-mode.svg";
import { useState } from "react";
import { RetroButton } from "components/RetroButton";

export type GroupMode = "free" | "paid";

const groupModeOptions = [
  {
    value: "free",
    title: <Typography variant="subtitle">SIMPLE</Typography>,
    description: (
      <Box>
        <Typography variant="body-default" color="brand.primary">
          <b>Best for existing communities. </b>
        </Typography>
        <Typography variant="body-default" color="text.secondary">
          Trade instantly with friends in groups.
        </Typography>
      </Box>
    ),
  },
  {
    value: "paid",
    title: (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="subtitle">Advanced -</Typography>
        <Typography variant="subtitle">Initial cabal Offering</Typography>
      </Box>
    ),
    description: (
      <Box>
        <Typography variant="body-default" color="brand.primary">
          <b>Best for building a new community. </b>
        </Typography>
        <Typography variant="body-default" color="text.secondary">
          Launch your own group token on a bonding curve.
        </Typography>
      </Box>
    ),
  },
];

export const CreateCabalMenu = ({
  onNextPage,
}: {
  onNextPage: (selectedGroupMode: GroupMode) => void;
}) => {
  const [selected, setSelected] = useState<GroupMode>("free");

  const onSelected = (value: string) => {
    setSelected(value as GroupMode);
  };

  return (
    <RetroCard>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          height: "calc(100vh - 92px)",
          width: "100%",
          maxWidth: "762px",
          padding: "0 20px",
          gap: "16px",
          margin: "0 auto 10px",
        }}>
        <Box sx={{ height: "56px" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "16px",
          }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "120px",
              height: "120px",
            }}>
            <GroupModeIcon />
          </Box>
          <Box>
            <Typography variant="title-h2">SELECT YOUR MODE</Typography>
          </Box>
          <CreateCabalRadioSelects
            selected={selected}
            onSelected={onSelected}
            options={groupModeOptions}
          />
        </Box>
        <Box sx={{ width: "100%", marginTop: "8px" }}>
          <RetroButton onClick={() => onNextPage(selected)}>
            <Box sx={{ padding: "12px" }}>
              <Typography variant="title-h3">NEXT</Typography>
            </Box>
          </RetroButton>
        </Box>
      </Box>
    </RetroCard>
  );
};

const CreateCabalRadioSelects = ({
  selected,
  onSelected,
  options,
}: {
  selected: string;
  onSelected: (value: string) => void;
  options: { value: string; title: JSX.Element; description: JSX.Element }[];
}) => {
  return (
    <>
      {options.map((option) => (
        <CreateCabalRadioSelect
          key={option.value}
          selected={selected}
          value={option.value}
          title={option.title}
          description={option.description}
          onSelected={onSelected}
        />
      ))}
    </>
  );
};

const CreateCabalRadioSelect = ({
  selected,
  value,
  title,
  description,
  onSelected,
}: {
  selected: string;
  value: string;
  title: JSX.Element;
  description: JSX.Element;
  onSelected: (value: string) => void;
}) => {
  const theme = useTheme();

  return (
    <Box
      onClick={() => onSelected(value)}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "12px",
        borderRadius: "16px",
        padding: "12px",
        width: "100%",
        border: `1px solid ${theme.palette.border.dark}`,
        background: theme.palette.surface.silver,
        cursor: "pointer",
      }}>
      <Box>
        <Box
          sx={{
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            border: `2px solid ${
              selected === value ? theme.palette.border.dark : theme.palette.border.light
            }`,
            background: selected === value ? theme.palette.brand.primary : "transparent",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          flex: "1",
        }}>
        <Typography variant="subtitle">{title}</Typography>
        <Typography variant="body-default">
          <b>{description}</b>
        </Typography>
      </Box>
    </Box>
  );
};
