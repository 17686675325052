import { Box, Typography, useTheme } from "@mui/material";
import { DarkDrawer } from "components/TradeDrawer";
import { MAX_SLIPPAGE, MIN_SLIPPAGE } from "consts";
import { useRef, useState } from "react";
import useUserStoreV2, { DEFAULT_CACHED_SLIPPAGE } from "store/user-store-v2/useUserStoreV2";
import { StyledInputV2 } from "./styled";
import { RetroButton } from "components/RetroButton";
import axiosService from "services/axios";

export const SlippageDrawer = ({ isOpen, close }: { isOpen: boolean; close: () => void }) => {
  const { preferences = { slippage: DEFAULT_CACHED_SLIPPAGE }, setSlippage } = useUserStoreV2();
  const { slippage } = preferences;

  const theme = useTheme();
  const [isSlippageValid, setIsSlippageValid] = useState(!!slippage);
  const slippageRef = useRef<HTMLInputElement>(null);

  const handleSlippageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const value = parseFloat(event.target.value);
      setIsSlippageValid(() => !isNaN(value) && value >= MIN_SLIPPAGE && value <= MAX_SLIPPAGE);
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmSlippage = async () => {
    try {
      if (!slippageRef.current) {
        return;
      }

      const value = parseFloat(slippageRef.current.value);
      await axiosService.updateUserSlippage({ slippage: value });

      setSlippage(value);
      close();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <DarkDrawer open={isOpen} anchor="bottom" onClose={() => {}} sx={{ zIndex: 200 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Typography variant="title-h2">Slippage</Typography>
      </Box>
      <Box
        sx={{
          marginTop: "32px",
        }}>
        <Box sx={{ position: "relative" }}>
          <StyledInputV2
            type="number"
            min={MIN_SLIPPAGE}
            max={MAX_SLIPPAGE}
            step="0.1"
            ref={slippageRef}
            onChange={handleSlippageChange}
            defaultValue={slippage}
          />
          <Typography
            variant="body-md"
            color={theme.palette.text.secondary}
            sx={{
              position: "absolute",
              right: "12px",
              top: "50%",
              transform: "translateY(-50%)",
            }}>
            %
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: "32px",
        }}>
        <RetroButton variant="white" onClick={handleConfirmSlippage} disabled={!isSlippageValid}>
          <Typography variant="title-h3" sx={{ paddingY: theme.spacing(1) }}>
            DONE
          </Typography>
        </RetroButton>
      </Box>
      <Box
        sx={{
          marginTop: "64px",
        }}
      />
    </DarkDrawer>
  );
};
