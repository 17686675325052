import { useCallback, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { tradeStateAtom } from ".";
import axiosService from "services/axios";
import { Chain } from "chainConfigs";

function useTradeStore() {
  const [state, setState] = useRecoilState(tradeStateAtom);
  const [isLoading, setIsLoading] = useState(false);
  const loadingRef = useRef(false);

  const updateUserBalance = async () => {
    try {
      if (loadingRef.current || isLoading) return;

      loadingRef.current = true;
      setIsLoading(true);

      const result = await axiosService.getUserBalance();

      const formattedResult = Object.entries(result).reduce(
        (acc, cur) => {
          const [key, value] = cur;

          let chain, keyType;
          if (key.includes("Balance")) {
            chain = key.split("Balance")[0];
            keyType = "amount";
          } else {
            chain = key.split("UsdValue")[0] as Chain;
            keyType = "usd";
          }

          if (chain === "eth") {
            chain = "ethereum";
          } else if (chain === "sol") {
            chain = "solana";
          } else if (chain === "ton") {
            chain = "ton";
          } else if (chain === "base") {
            chain = "base";
          } else if (chain === "bera") {
            chain = "berachain";
          } else if (chain === "bnb") {
            chain = "bsc";
          }

          acc[keyType as "amount" | "usd"][chain as Chain] = value;
          return acc;
        },
        {
          amount: {
            solana: 0,
            ethereum: 0,
            base: 0,
            ton: 0,
            berachain: 0,
            bsc: 0,
          },
          usd: {
            solana: 0,
            ethereum: 0,
            base: 0,
            ton: 0,
            berachain: 0,
            bsc: 0,
          },
        },
      );

      setState((prevState) => {
        return {
          ...prevState,
          nativeToken: formattedResult,
        };
      });

      return formattedResult;
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      loadingRef.current = false;
    }
  };

  const getUserBalance = useCallback(
    (chain: string, type: "amount" | "usd") => {
      return state.nativeToken[type][chain as Chain];
    },
    [updateUserBalance],
  );

  return {
    ...state,
    isLoading,
    updateUserBalance,
    getUserBalance,
  };
}

export default useTradeStore;
