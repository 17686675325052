import { useInViewInfiniteQuery } from "hooks/useInViewInfiniteQuery";
import axiosService from "services/axios";
import { createContext, useContext } from "react";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";

export interface IPortfolioContext {
  earningsData: any;
  isEarningsLoading: boolean;
  refetchEarningsAndTotalAssets: () => Promise<any>;
  isEarningsRefetching: boolean;
  earningsRef: any;
  hasAllFetchedEarnings: boolean;
  cabalGroupsData: any;
  isCabalGroupsLoading: boolean;
  refetchCabalGroupsAndTotalAssets: () => Promise<any>;
  isCabalGroupsRefetching: boolean;
  cabalGroupsRef: any;
  hasAllFetchedCabalGroups: boolean;
  assetsData: any;
  isAssetsLoading: boolean;
  refetchAssetsAndTotalAssets: () => Promise<any>;
  isAssetsRefetching: boolean;
  assetsRef: any;
  hasAllFetchedAssets: boolean;
  userAllPortfolioAssetsData: any;
  isUserAllPortfolioAssetsLoading: boolean;
  refetchUserAllPortfolioAssets: () => Promise<any>;
  isUserAllPortfolioAssetsRefetching: boolean;
  refetchFullAllData: () => void;
}

export const PortfolioContext = createContext<IPortfolioContext>({
  earningsData: [],
  isEarningsLoading: false,
  refetchEarningsAndTotalAssets: () => Promise.resolve(),
  isEarningsRefetching: false,
  earningsRef: null,
  hasAllFetchedEarnings: false,
  cabalGroupsData: [],
  isCabalGroupsLoading: false,
  refetchCabalGroupsAndTotalAssets: () => Promise.resolve(),
  isCabalGroupsRefetching: false,
  cabalGroupsRef: null,
  hasAllFetchedCabalGroups: false,
  assetsData: [],
  isAssetsLoading: false,
  refetchAssetsAndTotalAssets: () => Promise.resolve(),
  isAssetsRefetching: false,
  assetsRef: null,
  hasAllFetchedAssets: false,
  userAllPortfolioAssetsData: null,
  isUserAllPortfolioAssetsLoading: false,
  refetchUserAllPortfolioAssets: () => Promise.resolve(),
  isUserAllPortfolioAssetsRefetching: false,
  refetchFullAllData: () => Promise.resolve(),
});

export interface IPortfolioProviderProps {
  children: React.ReactNode;
}

export const PortfolioProvider = (props: IPortfolioProviderProps) => {
  const { hasWallet } = useUserStoreV2();

  // Assets
  const assetsQueryResult = useInfiniteQuery({
    queryKey: ["portfolio-assets", { page: 1, hasWallet }],
    queryFn: async ({ pageParam }) => {
      return axiosService.getUserPortfolioAssets({ limit: 10, page: pageParam });
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.page + 1;
    },
    getPreviousPageParam: (firstPage, pages) => {
      return firstPage.page - 1;
    },
    initialPageParam: 1,
  });

  const {
    data: assetsData,
    isLoading: isAssetsLoading,
    refetch: refetchAssets,
    isRefetching: isAssetsRefetching,
  } = assetsQueryResult;
  const { ref: assetsRef, hasAllFetched: hasAllFetchedAssets } =
    useInViewInfiniteQuery(assetsQueryResult);

  // Earnings
  const queryResult = useInfiniteQuery({
    queryKey: ["portfolio-earnings", { page: 1 }],
    queryFn: async ({ pageParam }) => {
      return axiosService.getUserPortfolioEarning({ limit: 10, page: pageParam });
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.page + 1;
    },
    getPreviousPageParam: (firstPage, pages) => {
      return firstPage.page - 1;
    },
    initialPageParam: 1,
  });

  const {
    data: earningsData,
    isLoading: isEarningsLoading,
    refetch: refetchEarnings,
    isRefetching: isEarningsRefetching,
  } = queryResult;
  const { ref: earningsRef, hasAllFetched: hasAllFetchedEarnings } =
    useInViewInfiniteQuery(queryResult);

  // Cabal Groups
  const cabalGroupsQueryResult = useInfiniteQuery({
    queryKey: ["portfolio-groups", { page: 1 }],
    queryFn: async ({ pageParam }) => {
      return axiosService.getUserPortfolioGroups({ limit: 10, page: pageParam });
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.page + 1;
    },
    getPreviousPageParam: (firstPage, pages) => {
      return firstPage.page - 1;
    },
    initialPageParam: 1,
  });

  const {
    data: cabalGroupsData,
    isLoading: isCabalGroupsLoading,
    refetch: refetchCabalGroups,
    isRefetching: isCabalGroupsRefetching,
  } = cabalGroupsQueryResult;
  const { ref: cabalGroupsRef, hasAllFetched: hasAllFetchedCabalGroups } =
    useInViewInfiniteQuery(cabalGroupsQueryResult);

  const refetchEarningsAndTotalAssets = async () => {
    await Promise.all([refetchEarnings(), refetchAssets()]);
  };

  const refetchAssetsAndTotalAssets = async () => {
    await Promise.all([refetchAssets()]);
  };

  const refetchCabalGroupsAndTotalAssets = async () => {
    await Promise.all([refetchCabalGroups(), refetchAssets()]);
  };

  const refetchFullAllData = async () => {
    await Promise.all([
      // refetchUserAllPortfolioAssets(),
      refetchAssets(),
      refetchEarnings(),
      refetchCabalGroups(),
    ]);
  };

  return (
    <PortfolioContext.Provider
      value={{
        earningsData,
        isEarningsLoading,
        refetchEarningsAndTotalAssets,
        isEarningsRefetching,
        earningsRef,
        hasAllFetchedEarnings,
        cabalGroupsData,
        isCabalGroupsLoading,
        refetchCabalGroupsAndTotalAssets,
        isCabalGroupsRefetching,
        cabalGroupsRef,
        hasAllFetchedCabalGroups,
        assetsData,
        isAssetsLoading,
        refetchAssetsAndTotalAssets,
        isAssetsRefetching,
        assetsRef,
        hasAllFetchedAssets,
        userAllPortfolioAssetsData: assetsData?.pages[0],
        isUserAllPortfolioAssetsLoading: isAssetsLoading,
        refetchUserAllPortfolioAssets: refetchAssets,
        isUserAllPortfolioAssetsRefetching: isAssetsRefetching,
        refetchFullAllData,
      }}>
      {props.children}
    </PortfolioContext.Provider>
  );
};

export const usePortfolioContext = () => useContext(PortfolioContext);
