import { Box, Typography, useTheme } from "@mui/material";
import { RetroButton } from "components/RetroButton";
import { RetroCard } from "components/RetroCard";
import { ContentCard, RowContainer, TapsContainer } from "./styled";
import { abbreviateNumber, toFixedIfNecessary } from "utils";
import { Group } from "@mui/icons-material";
import { ScrollableBox } from "components/ScrollableBox";
import { LoadMoreRow } from "components/LoadMoreRow";
import { ReferralTabs } from "pages/referral";
import { LoadingBlock } from "components/LoadingScreen";

export const ReferralPageBody = ({
  openHowItWorksModal,
  onInviteNow,
  selectedTab,
  setSelectedTab,
  data,
  isLoading,
  hasAllFetched,
  inViewRef,
  referralOverviewData,
  isReferralOverviewLoading,
}: {
  openHowItWorksModal: () => void;
  onInviteNow: () => void;
  selectedTab: number;
  setSelectedTab: (tab: number) => void;
  data: any[];
  isLoading: boolean;
  hasAllFetched: boolean;
  inViewRef: any;
  referralOverviewData: any;
  isReferralOverviewLoading: boolean;
}) => {
  const theme = useTheme();

  return (
    <>
      {/* Referral Stats */}
      <RetroCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            padding: "20px",
          }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "21px",
            }}>
            <Typography variant="title-h3">Referral Stats</Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "4px",
              }}>
              <RetroButton variant="white" onClick={openHowItWorksModal}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "4px",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                  }}>
                  <Typography variant="button-md">How it works</Typography>
                </Box>
              </RetroButton>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}>
            <ContentCard title="LIFETIME EARNING">
              <Box>
                <Typography variant="title-h1">
                  {referralOverviewData?.totalReferredEarning > 0
                    ? referralOverviewData?.totalReferredEarning < 0.01
                      ? "< $0.01"
                      : `$${toFixedIfNecessary(referralOverviewData?.totalReferredEarning, 2)}`
                    : "$0"}
                </Typography>
              </Box>
            </ContentCard>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "8px",
              }}>
              <ContentCard title="USERS" sx={{ flex: 1 }}>
                <Box>
                  <Typography variant="title-h1">
                    {referralOverviewData?.totalReferredUser}
                  </Typography>
                </Box>
              </ContentCard>
              <ContentCard title="GROUPS" sx={{ flex: 1 }}>
                <Box>
                  <Typography variant="title-h1">
                    {referralOverviewData?.totalReferredGroup}
                  </Typography>
                </Box>
              </ContentCard>
            </Box>
          </Box>
        </Box>
      </RetroCard>

      {/* MY REFERRALS */}
      <RetroCard>
        <Box
          sx={{
            padding: "20px",
          }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}>
              <Typography variant="title-h3">Your REFERRALS</Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: `1px solid ${theme.palette.border.dark}`,
                  borderRadius: "8px",
                  padding: "2px 6px",
                  bgcolor: theme.palette.surface["container-bg"],
                  gap: "4px",
                }}>
                <Group fontSize="small" />
                <Typography variant="body-sm" color="text.secondary">
                  {referralOverviewData?.totalReferredUser +
                    referralOverviewData?.totalReferredGroup}
                </Typography>
              </Box>
            </Box>
            {/* Button Group */}

            <TapsContainer>
              {ReferralTabs.map((tab, index) => {
                return (
                  <RetroButton
                    key={tab.toUpperCase() + index}
                    disabled={selectedTab === index}
                    onClick={() => setSelectedTab(index)}>
                    <Box
                      sx={{
                        padding: "4px",
                      }}>
                      <Typography
                        variant="button-md"
                        color={selectedTab === index ? "text.primary" : "text.brand"}>
                        {tab}
                      </Typography>
                    </Box>
                  </RetroButton>
                );
              })}
            </TapsContainer>
            <Box>
              <TabLoadingSuspense isLoading={isReferralOverviewLoading || isLoading}>
                <ScrollableBox>
                  {data?.length > 0 ? (
                    data.map((item, index) => {
                      const { _id, isGroupTrade, userName, referralEarningUsd, groupName } = item;
                      return (
                        <ReferralUserRow
                          key={`my-referrals-${selectedTab}-${_id}`}
                          name={isGroupTrade ? groupName : userName}
                          feeEarned={referralEarningUsd}
                        />
                      );
                    })
                  ) : selectedTab !== 2 ? (
                    <AllEmptyView onInviteNow={onInviteNow} />
                  ) : (
                    <GroupEmptyView />
                  )}
                  {!hasAllFetched && <LoadMoreRow ref={inViewRef} />}
                </ScrollableBox>
              </TabLoadingSuspense>
            </Box>
          </Box>
        </Box>
      </RetroCard>
    </>
  );
};

const ReferralUserRow = ({ name, feeEarned }: { name: string; feeEarned: number }) => {
  return (
    <RowContainer>
      <Box>
        <Typography variant="subtitle">{name}</Typography>
      </Box>
      <Box>
        <Typography variant="body-sm">
          {feeEarned > 0
            ? feeEarned < 0.01
              ? "< $0.01"
              : `$${toFixedIfNecessary(feeEarned.toString(), 2)}`
            : "$0"}
        </Typography>
      </Box>
    </RowContainer>
  );
};

const GroupEmptyView = () => {
  return (
    <RowContainer>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
          margin: "auto",
        }}>
        <Typography variant="title-h3">NO REFERRAL GROUPS</Typography>
        <Typography variant="body-default">You have no referred groups yet</Typography>
      </Box>
    </RowContainer>
  );
};

const AllEmptyView = ({ onInviteNow }: { onInviteNow: () => void }) => {
  return (
    <RowContainer>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
          margin: "auto",
        }}>
        <Typography variant="title-h3">NO REFERRALS</Typography>
        <Typography variant="body-default">You have no referrals yet</Typography>
        <Box>
          <RetroButton onClick={onInviteNow}>
            <Box sx={{ padding: "8px 16px", whiteSpace: "nowrap" }}>
              <Typography variant="button-md">INVITE NOW</Typography>
            </Box>
          </RetroButton>
        </Box>
      </Box>
    </RowContainer>
  );
};

const TabLoadingSuspense = ({ isLoading, children }: { isLoading: boolean; children: any }) => {
  return isLoading ? (
    <LoadingBlock isLoading={isLoading}>
      <Typography variant="title-h2">Loading...</Typography>
    </LoadingBlock>
  ) : (
    children
  );
};
