import { Box, Typography } from "@mui/material";
import { RetroButton } from "components/RetroButton";
import theme from "theme";
import { Mode, TRADING_MODE_CONFIG_MAP } from "tradeConfigs";

// Trade Mode Switch
export const BuySellSwitch = ({
  mode,
  handleSetMode,
}: {
  mode: Mode;
  handleSetMode: (mode: Mode) => void;
}) => {
  const modeConfig = TRADING_MODE_CONFIG_MAP[mode];

  return (
    <Box
      sx={{
        background: theme.palette.surface.container,
        borderRadius: theme.spacing(2),
        border: `1px solid ${theme.palette.border.dark}`,
        padding: theme.spacing(1),
        display: "flex",
        gap: theme.spacing(1),
        ...(!modeConfig.modeSwitch
          ? {
              opacity: 0,
              pointerEvents: "none",
            }
          : {}),
      }}>
      {modeConfig.modeSwitch ? (
        <>
          {modeConfig.modeSwitch.map((modeOption) => {
            return (
              <TradeModeSwitchButton
                currentMode={mode}
                mode={modeOption}
                handleSetMode={handleSetMode}
              />
            );
          })}
        </>
      ) : null}
    </Box>
  );
};

const TradeModeSwitchButton = ({
  currentMode,
  mode,
  handleSetMode,
}: {
  currentMode: Mode;
  mode: Mode;
  handleSetMode: (mode: Mode) => void;
}) => {
  return (
    <RetroButton onClick={() => handleSetMode(mode)} disabled={mode === currentMode}>
      <Box sx={{ padding: theme.spacing(1.5, 3) }}>
        <Typography variant="body-md">{mode}</Typography>
      </Box>
    </RetroButton>
  );
};
