import { AlphaPage } from "./Alpha";
import { AuthCheckBlocker } from "components/Blockers";

const CallPage = () => {
  return (
    <AuthCheckBlocker withCard>
      <AlphaPage />
    </AuthCheckBlocker>
  );
};

export { CallPage };
