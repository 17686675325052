/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { getUser, removeTokens, tgAuth } from "services/authV2";
import { userStateAtomV2 } from ".";
import useToastStore from "store/toast-store/useToastStore";
import axiosService from "services/axios";
import { decryptionStartParams } from "utils";
// const LOGIN_REDIRECT_URL = process.env.REACT_APP_TG_WIDGET_LOGIN_URL;

export const DEFAULT_CACHED_SLIPPAGE = 3;

function useUserStoreV2() {
  const location = useLocation();
  const isLogoutingRef = useRef(false);
  const [state, setState] = useRecoilState(userStateAtomV2);
  const [hasWallet, setHasWallet] = useState(
    state?.walletAddresses && Object.keys(state.walletAddresses).length > 0
      ? Object.values(state.walletAddresses).every((wallet) => wallet)
      : false,
  );
  const [skipOnboarding, setSkipOnboarding] = useState(!!localStorage.getItem("isOnboarded"));
  const { showToast } = useToastStore();

  useEffect(() => {
    setHasWallet(
      state?.walletAddresses && Object.keys(state.walletAddresses).length > 0
        ? Object.values(state.walletAddresses).every((wallet) => wallet)
        : false,
    );
  }, [state]);

  useEffect(() => {
    const isLogined =
      state.userId &&
      state.tgUserId &&
      state.tgUserName &&
      state.walletAddresses?.tonAddress &&
      state.walletAddresses?.solAddress &&
      state.walletAddresses?.ethAddress;

    if (!localStorage.getItem("logged_in_user") && isLogined) {
      logoutTgUserV2();
    }
  }, []);

  const logoutTgUserV2 = useCallback(() => {
    if (!isLogoutingRef.current) {
      isLogoutingRef.current = true;
      removeTokens();
      setState({
        userId: undefined,
        walletAddresses: {
          tonAddress: undefined,
          ethAddress: undefined,
          solAddress: undefined,
        },
        tgPhotoUrl: undefined,
        tgUserId: undefined,
        tgUserName: undefined,
        tonBalance: undefined,
        preferences: {
          slippage: undefined,
        },
        walletId: undefined,
        turnkeyUserId: undefined,
        organizationId: undefined,
        isExistingUser: false,
        email: undefined,
      });
      window.history.replaceState({}, document.title, window.location.pathname);
      window.location.href = window.location.href.split("?")[0];
      isLogoutingRef.current = false;
      localStorage.removeItem("@turnkey/session/v1");
      localStorage.removeItem("credentialBundle");
      localStorage.removeItem("encryptionBundle");
    }
  }, [setState]);

  const getTgUserIdV2 = useCallback(async (isTryLogin: boolean = true) => {
    try {
      const isFromMiniApp = checkIsFromMiniApp();
      // const accessToken = getAccessToken();

      // On Mount (TG app)
      if (isFromMiniApp) {
        // (1): Try to get tgUserId from window app
        return await _getTgUserIdByWindowApp(isTryLogin);
      }

      // On Mount (Web)
      // if (isTryLogin) {
      //   return window.open(LOGIN_REDIRECT_URL, "_self");
      // }

      return await _getTgUserIdFromCallback();
    } catch (error) {
      console.error("Failed to Login: ", error);

      showToast({
        variant: "error",
        message: (error as Error).message,
        duration: 5000,
      });
      throw error;
    }
  }, []);

  const _getTgUserIdByWindowApp = useCallback(
    async (isTryLogin: boolean = true) => {
      if (window.Telegram.WebApp) {
        // Initialize the Web App
        window.Telegram.WebApp.ready();
        console.debug(
          "window.Telegram.WebApp.initDataUnsafe: ",
          window.Telegram.WebApp.initDataUnsafe,
        );

        // Get user information
        const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;
        const user = initDataUnsafe.user;
        const userId = user?.id;
        const referredBy = localStorage.getItem("referredBy");

        if (userId) {
          const { user } = await tgAuth({
            userData: {
              ...initDataUnsafe,
              referredBy,
            },
          });

          // await checkHasEmail();
          const {
            id,
            tgUserId,
            tgUserName,
            tonAddress,
            solAddress,
            ethAddress,
            tgPhotoUrl,
            preferences,
            email,
          } = user;

          setState((prevState) => {
            return {
              ...prevState,
              userId: id,
              walletAddresses: {
                tonAddress,
                solAddress,
                ethAddress,
              },
              tgUserId,
              tgUserName,
              tgPhotoUrl,
              email,
              preferences: {
                ...preferences,
                slippage:
                  prevState?.preferences?.slippage ||
                  preferences?.slippage ||
                  DEFAULT_CACHED_SLIPPAGE,
              },
            };
          });
          return tgUserId && tgUserName;
        } else {
          throw new Error("Failed to get tgUserId from window app");
        }
      }
    },
    [setState],
  );

  const _getTgUserIdFromCallback = useCallback(async () => {
    try {
      const urlParams = new URLSearchParams(location.search);

      let tokenUri = urlParams.get("token");
      let _tgUserId = urlParams.get("tgUserId");

      const referralId = urlParams.get("referralId");
      const signature = urlParams.get("signature");

      if (referralId) {
        window.localStorage.setItem("referralId", referralId);
      }

      if (signature) {
        const decryptedSignature = decryptionStartParams(signature);
        const decryptedData = decryptedSignature?.split("&");
        _tgUserId = decryptedData?.[0].split("=")?.[1] ?? null;
        tokenUri = decryptedData?.[1].split("=")?.[1] ?? null;
      }

      if (tokenUri) {
        const token: { refresh: { token: string }; access: { token: string } } = JSON.parse(
          decodeURI(tokenUri),
        );
        window.localStorage.setItem("refreshToken", token.refresh.token);
        window.localStorage.setItem("accessToken", token.access.token);
        window.localStorage.removeItem("isLoggingIn");

        if (_tgUserId) {
          const user = await getUser(_tgUserId);

          // await checkHasEmail();
          const {
            _id,
            tgUserId,
            tgUserName,
            tonAddress,
            solAddress,
            ethAddress,
            tgPhotoUrl,
            preferences,
            organizationId,
            walletId,
            email,
          } = user;

          // Update state first
          setState((prevState) => {
            return {
              ...prevState,
              userId: _id,
              walletAddresses: {
                tonAddress,
                solAddress,
                ethAddress,
              },
              tgPhotoUrl,
              tgUserId,
              tgUserName,
              email,
              preferences: {
                ...preferences,
                slippage:
                  prevState?.preferences?.slippage ||
                  preferences?.slippage ||
                  DEFAULT_CACHED_SLIPPAGE,
              },
              organizationId,
              walletId,
            };
          });

          if (localStorage.getItem("cachedUrl")) {
            window.history.replaceState({}, document.title, localStorage.getItem("cachedUrl")!);
          } else {
            window.history.replaceState({}, document.title, window.location.pathname);
          }

          return tgUserId && tgUserName;
        } else {
          throw new Error("Failed to get tgUserId from callback");
        }
      }
    } catch (error) {
      console.error("Error in _getTgUserIdFromCallback:", error);
      throw error;
    }
  }, [setState]);

  const checkIsFromMiniApp = useCallback(() => {
    let userId = false;
    if (window.Telegram.WebApp) {
      // Initialize the Web App
      window.Telegram.WebApp.ready();
      console.debug(
        "window.Telegram.WebApp.initDataUnsafe: ",
        window.Telegram.WebApp.initDataUnsafe,
      );

      // Get user information
      const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;
      const user = initDataUnsafe?.user;
      userId = user?.id;
    }
    return !!userId;
  }, [setState]);

  const setSlippage = useCallback(
    (slippage: number) => {
      setState((prevState) => {
        return {
          ...prevState,
          preferences: {
            ...prevState?.preferences,
            slippage,
          },
        };
      });
    },
    [setState],
  );

  const createWallet = useCallback(
    async (email: string, subOrgId?: string) => {
      try {
        const result = await axiosService.createWallet({
          email,
          subOrgId,
        });

        if (result.organizationId) {
          setState((prevState) => {
            return {
              ...prevState,
              turnkeyUserId: result.turnkeyUserId,
              organizationId: result.organizationId,
              isExistingUser: result.isExistingUser,
            };
          });
        }
      } catch (error) {
        console.error("Failed to create wallet: ", error);
        throw error;
      }
    },
    [setState],
  );

  const handleOnboard = () => {
    if (skipOnboarding) return;

    localStorage.setItem("isOnboarded", "true");
    setSkipOnboarding(true);
  };

  const setIsExistingUser = useCallback(
    (isExistingUser: boolean) => {
      setState((prevState) => {
        return { ...prevState, isExistingUser };
      });
    },
    [setState],
  );

  const refreshLatestUserState = async () => {
    try {
      if (!state.tgUserId) return;
      const user = await getUser(state.tgUserId.toString());

      const {
        _id,
        tgUserId,
        tgUserName,
        tonAddress,
        solAddress,
        ethAddress,
        tgPhotoUrl,
        preferences,
        organizationId,
        walletId,
        email,
      } = user;

      // Update state first
      setState((prevState) => {
        return {
          ...prevState,
          userId: _id,
          walletAddresses: {
            tonAddress,
            solAddress,
            ethAddress,
          },
          tgPhotoUrl,
          tgUserId,
          tgUserName,
          email,
          preferences: {
            ...preferences,
            slippage:
              prevState?.preferences?.slippage || preferences?.slippage || DEFAULT_CACHED_SLIPPAGE,
          },
          organizationId,
          walletId,
        };
      });

      return user;
    } catch (error) {
      console.error("Failed to get email: ", error);
    }
  };

  return {
    ...state,
    skipOnboarding: skipOnboarding || location.pathname === "/",
    hasWallet: hasWallet,
    createWallet,
    getTgUserIdV2,
    logoutTgUserV2,
    setSlippage,
    checkIsFromMiniApp,
    handleOnboard,
    setIsExistingUser,
    setHasWallet,
    refreshLatestUserState,
  };
}

export default useUserStoreV2;
