import { Box, Typography } from "@mui/material";
import { RetroButton } from "components/RetroButton";
import { DarkDrawer } from "components/TradeDrawer";
import { Value } from "components/Value";
import { formatPrice, toPurePrecision } from "lib/utils";
import useUserStoreV2, { DEFAULT_CACHED_SLIPPAGE } from "store/user-store-v2/useUserStoreV2";
import theme from "theme";
import { Mode } from "tradeConfigs";
import { toFixedIfNecessary } from "utils";

export const ConfirmDrawer = ({
  mode,
  isOpen,
  inputUsd,
  targetTokenPrice,
  targetAmount,
  targetTokenSymbol,
  close,
  onConfirm,
}: {
  mode: Mode;
  isOpen: boolean;
  inputUsd: number;
  targetTokenPrice: number;
  targetAmount: number;
  targetTokenSymbol: string;
  close: () => void;
  onConfirm: (slippage: number | undefined) => void;
}) => {
  const { preferences = { slippage: DEFAULT_CACHED_SLIPPAGE } } = useUserStoreV2();
  const { slippage } = preferences;

  return (
    <DarkDrawer open={isOpen} anchor="bottom" onClose={() => {}} sx={{ zIndex: 200 }}>
      <Box>
        <Typography variant="title-h2">CONFIRM</Typography>
      </Box>
      <Box
        sx={{
          marginTop: "16px",
          marginBottom: "32px",
        }}>
        <Typography variant="body-md" color={theme.palette.text.secondary}>
          You are {mode === "SELL" ? "selling" : "purchasing"} ${targetTokenSymbol} tokens with a
          total value of $
          <Value variant="body-md" value={inputUsd} />{" "}
          <Value variant="body-md" value={targetAmount} /> ${targetTokenSymbol} @$
          <Value variant="body-md" value={targetTokenPrice} />{" "}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "10px",
        }}>
        <RetroButton variant="white" onClick={close}>
          <Typography variant="title-h3" sx={{ paddingY: theme.spacing(1) }}>
            NO
          </Typography>
        </RetroButton>
        <RetroButton onClick={() => onConfirm(slippage)}>
          <Typography variant="title-h3" sx={{ paddingY: theme.spacing(1) }}>
            YES
          </Typography>
        </RetroButton>
      </Box>
    </DarkDrawer>
  );
};
