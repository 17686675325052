import { Box, styled } from "@mui/material";

export const ScrollableBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "6px",
  height: "100%",

  flex: 1,
  minHeight: 0,
  overflowY: "scroll",

  "*::-webkit-scrollbar": {
    display: "none",
  },
  "*::-webkit-scrollbar-track": {
    display: "none",
  },
  "*::-webkit-scrollbar-thumb": {
    display: "none",
  },

  // /* width */
  // "&::-webkit-scrollbar": {
  //   width: "4px",
  //   display: "block",
  // },

  // /* Track */
  // "&::-webkit-scrollbar-track": {
  //   background: theme.palette.surface["container-bg"],
  //   display: "block",
  //   borderRadius: "4px",
  // },

  // /* Handle */
  // "&::-webkit-scrollbar-thumb": {
  //   background: theme.palette.surface.silver,
  //   display: "block",
  //   borderRadius: "4px",
  // },
}));
