import { Address, beginCell, storeMessageRelaxed, SendMode, TonClient } from "@ton/ton";

const queryPurchaseReturn = async (
  tonClient: any,
  jettonMasterAddress: string,
  reserveTokenReceived: number,
) => {
  try {
    const jettonAddress = Address.parse(jettonMasterAddress);
    let { stack } = await tonClient.runMethodWithError(jettonAddress, "get_purchase_return", [
      {
        type: "int",
        value: BigInt(reserveTokenReceived),
      },
    ]);
    // console.debug("stack: ", stack);
    let purchaseReturn = stack.readBigNumber();

    return purchaseReturn;
  } catch (error) {
    console.debug(
      `queryPurchaseReturn error: ${error} jettonMasterAddress: ${jettonMasterAddress}`,
    );
  }
};

const createWalletTransferV4WithTurnkey = async (
  messages: any,
  walletId: any,
  seqno: any,
  mode = SendMode.PAY_GAS_SEPARATELY,
) => {
  // Check number of messages
  if (messages.length > 4) {
    throw Error("Maximum number of messages in a single transfer is 4");
  }

  let signingMessageBuilder = beginCell().storeUint(walletId, 32);

  if (seqno === 0) {
    for (let i = 0; i < 32; i++) {
      signingMessageBuilder.storeBit(1); // Initial state for uninitialized wallet
    }
  } else {
    signingMessageBuilder.storeUint(Math.floor(Date.now() / 1e3) + 60, 32); // Default timeout: 60 seconds
  }

  signingMessageBuilder.storeUint(seqno, 32);
  signingMessageBuilder.storeUint(0, 8); // Simple order
  for (let m of messages) {
    signingMessageBuilder.storeUint(mode, 8);
    signingMessageBuilder.storeRef(beginCell().store(storeMessageRelaxed(m)));
  }

  const signingMessage = signingMessageBuilder.endCell().hash();

  return {
    signingMessage,
    signingMessageBuilder,
  };
};

export { queryPurchaseReturn, createWalletTransferV4WithTurnkey };
