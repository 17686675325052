import { Box, MenuItem, Typography, useTheme } from "@mui/material";
import { RetroCard } from "components/RetroCard";
import { useEffect, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import axiosService from "services/axios";
import { EmptyViewWithoutRetroCard } from "components/EmptyView";
import { SearchField } from "components/SearchField";
import { ScrollableBox } from "components/ScrollableBox";
import { LoadMoreRow } from "components/LoadMoreRow";
import { useInViewInfiniteQuery } from "hooks/useInViewInfiniteQuery";
import useLayoutConfigStore from "store/layout-config-store/useLayoutConfigStore";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { Search } from "@mui/icons-material";

import { SearchDrawer } from "./SearchDrawer";
import { RetroButton } from "components/RetroButton";
import { StyledSelect } from "./styles";
import { TradeCallRow } from "./TradeCallRow";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import { LoadingBlock, LoadingLottie } from "components/LoadingScreen";
import { AuthCheckBlocker } from "components/Blockers";
import { Chain, CHAIN_CONFIG, CHAINS } from "chainConfigs";
import { toCapitalize } from "lib/utils";

const chainSelectConfig = [
  {
    label: "All Chains",
    value: "all",
  },
  ...Object.entries(CHAINS)
    .filter(([key]) => key !== "ton")
    .map(([key, value]) => ({
      label: key === "bsc" ? "BSC" : toCapitalize(CHAIN_CONFIG[key as Chain].name),
      value: key,
    })),
];

const timePeriodSelectConfig = [
  {
    label: "5 minutes",
    value: "5m",
  },
  {
    label: "1 hour",
    value: "1h",
  },
  {
    label: "1 day",
    value: "1d",
  },
  {
    label: "7 days",
    value: "7d",
  },
];

const TradePage = () => {
  const theme = useTheme();

  const [isOpened, setIsOpened] = useState(false);

  const { isShowHeader, setIsShowHeader } = useLayoutConfigStore();

  useEffect(() => {
    if (!isShowHeader) {
      setIsShowHeader(true);
    }
  }, []);

  return (
    <AuthCheckBlocker withCard>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px", height: "100%" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Box
            onClick={() => setIsOpened(true)}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
              cursor: "pointer",
              width: "100%",
              bgcolor: theme.palette.surface["container"],
              borderRadius: "8px",
              padding: "8px 16px",
              marginX: "4px",
              color: theme.palette.text.secondary,
            }}>
            <Search sx={{ opacity: 0.5 }} />
            <Typography variant="body-default" color="text.secondary" sx={{ opacity: 0.5 }}>
              Search by token address
            </Typography>
          </Box>
        </Box>
        <RetroCard>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: theme.spacing(2.5),
              gap: theme.spacing(1),
              height: "100%",
            }}>
            <Typography variant="title-h3">TRADE TRENDING TOKENS</Typography>
            {/* Tabs */}
            {/* <Box
              sx={{
                display: "flex",
                gap: theme.spacing(1),
                width: "100%",
              }}>
              <RetroButton
                variant="primary"
                onClick={() => setSelectedGroupType("all groups")}
                disabled={selectedGroupType === "all groups"}>
                <Box padding="4px">
                  <Typography variant="body-sm">All Groups</Typography>
                </Box>
              </RetroButton>
              <RetroButton
                variant="primary"
                onClick={() => setSelectedGroupType("your groups")}
                disabled={selectedGroupType === "your groups"}>
                <Box padding="4px">
                  <Typography variant="body-sm">Your Groups</Typography>
                </Box>
              </RetroButton>
            </Box> */}
            {/* Calls */}
            <TrendingTokenSection />
          </Box>
        </RetroCard>
      </Box>
      <SearchDrawer isOpenned={isOpened} close={() => setIsOpened(false)} />
    </AuthCheckBlocker>
  );
};

const TrendingTokenSection = () => {
  const theme = useTheme();
  const [selectedChain, setSelectedChain] = useState(chainSelectConfig[0].value);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(
    timePeriodSelectConfig.at(-1)!.value,
  );
  const [page, setPage] = useState(1);
  const queryResult = useInfiniteQuery({
    queryKey: ["all-trade-calls", selectedChain, selectedTimePeriod, { page }],
    queryFn: async ({ pageParam }) => {
      return axiosService.getTradeAllTrendingTokens({
        chain: selectedChain,
        sortBy: selectedTimePeriod,
        page: pageParam,
        limit: 10,
      });
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.page + 1;
    },

    getPreviousPageParam: (firstPage, pages) => {
      return firstPage.page - 1;
    },
    initialPageParam: 1,
  });

  const { ref, hasAllFetched } = useInViewInfiniteQuery(queryResult);

  const { data, isLoading } = queryResult;

  return (
    <>
      {/* Select */}
      <Box
        sx={{
          display: "flex",
          gap: theme.spacing(1),
          width: "fit-content",
        }}>
        <StyledSelect
          defaultValue="all"
          value={selectedChain}
          sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
          MenuProps={{
            slotProps: {
              paper: {
                sx: {
                  borderRadius: theme.spacing(1.5),
                  backgroundColor: theme.palette.surface.silver,
                },
              },
            },
          }}
          onChange={(event) => setSelectedChain(event.target.value as string)}
          IconComponent={undefined}>
          {chainSelectConfig.map((config) => (
            <MenuItem
              selected={selectedChain === config.value}
              value={config.value}
              sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
              <Typography
                sx={{
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  gap: theme.spacing(1),
                }}
                variant="body-default"
                color={theme.palette.text.secondary}>
                {config.label}
              </Typography>
            </MenuItem>
          ))}
        </StyledSelect>
        <StyledSelect
          defaultValue={timePeriodSelectConfig.at(-1)?.value}
          value={selectedTimePeriod}
          sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
          MenuProps={{
            slotProps: {
              paper: {
                sx: {
                  borderRadius: theme.spacing(1.5),
                  backgroundColor: theme.palette.surface.silver,
                },
              },
            },
          }}
          onChange={(event) => setSelectedTimePeriod(event.target.value as string)}
          IconComponent={undefined}>
          {timePeriodSelectConfig.map((config) => (
            <MenuItem
              selected={selectedTimePeriod === config.value}
              value={config.value}
              sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
              <Typography
                sx={{
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  gap: theme.spacing(1),
                }}
                variant="body-default"
                color={theme.palette.text.secondary}>
                {config.label}
              </Typography>
            </MenuItem>
          ))}
        </StyledSelect>
      </Box>
      {/* Calls */}
      <ScrollableBox>
        {isLoading ? (
          <LoadingBlock isLoading={isLoading}>
            <Typography variant="title-h2">Loading...</Typography>
          </LoadingBlock>
        ) : !data || data.pages[0].totalResults === 0 ? (
          <EmptyViewWithoutRetroCard />
        ) : (
          data.pages
            .flatMap((page) => page.results)
            .map((call, index) => {
              const formattedCall = {
                ...call,
                performance: parseFloat(call.performance),
                marketCap: parseFloat(call.marketCap),
                liquidity: parseFloat(call.liquidity),
                price: parseFloat(call.price),
              };
              return <TradeCallRow key={`all-trade-${index}`} tradeCall={formattedCall} />;
            })
        )}
        {!hasAllFetched && !isLoading && <LoadMoreRow ref={ref} />}
      </ScrollableBox>
    </>
  );
};

export { TradePage };
