import { Box, styled, Theme } from "@mui/material";

type Variant = "dark" | "white";
type Size = "sm" | "md" | "lg";

const SURFACE_COLOR = (theme: Theme): Record<Variant, string> => ({
  dark: theme.palette.surface.silver,
  white: theme.palette.surface.light,
});

const SURFACE_BACKGROUND_COLOR = (theme: Theme): Record<Variant, string> => ({
  dark: theme.palette.surface["container-bg"],
  white: theme.palette.surface["light-bg"],
});

const TEXT_COLOR = (theme: Theme): Record<Variant, string> => ({
  dark: theme.palette.text.secondary,
  white: theme.palette.text["btn-dark"],
});

const SIZE = (theme: Theme): Record<Size, string> => ({
  sm: "28px",
  md: "40px",
  lg: "54px",
});

const CircleButtonWrapper = styled(Box)(({ theme }) => ({
  paddingTop: "7px",
}));

const CircleButtonContainer = styled(Box)<{ variant: Variant; selected?: boolean; size: Size }>(
  ({ theme, variant, selected, size }) => ({
    width: SIZE(theme)[size],
    height: SIZE(theme)[size],
    borderRadius: "50%",
    backgroundColor: SURFACE_BACKGROUND_COLOR(theme)[variant],
    border: `1px solid ${theme.palette.border.dark}`,
    color: TEXT_COLOR(theme)[variant],

    ...(selected && {
      backgroundColor: theme.palette.brand["primary-bg"],
    }),
  }),
);

const CircleButtonInner = styled("button")<{
  variant: Variant;
  isClicking: boolean;
  animationDuration: number;
  selected?: boolean;
}>(({ theme, variant, isClicking, animationDuration, selected }) => ({
  all: "unset",
  width: "100%",
  height: "100%",
  borderRadius: "50%",
  backgroundColor: SURFACE_COLOR(theme)[variant],
  border: `1px solid ${theme.palette.border.dark}`,
  color: TEXT_COLOR(theme)[variant],
  transition: `top ${animationDuration}ms ease-in-out`,

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "-1px",
  top: "-7px",
  position: "relative",

  "& svg": {
    color: TEXT_COLOR(theme)[variant],
  },

  ...(isClicking && {
    top: "0px",
  }),

  ...(selected && {
    backgroundColor: theme.palette.brand.primary,
    top: "-2px",
    color: theme.palette.text.brand,
  }),
}));

export { CircleButtonWrapper, CircleButtonContainer, CircleButtonInner, type Variant, type Size };
