var Buffer = require("buffer/").Buffer;
global.Buffer = Buffer;
/* eslint-disable import/first */
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { RecoilRoot } from "recoil";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { ThemeProvider as MakeStylesProvider } from "@mui/material";
import theme from "theme";
import { SnackbarProvider } from "notistack";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { TurnkeyProvider } from "@turnkey/sdk-react";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <TurnkeyProvider
    config={{
      rpId: process.env.REACT_APP_TURNKEY_RP_ID!,
      apiBaseUrl: process.env.REACT_APP_TURNKEY_API_BASE_URL!,
      defaultOrganizationId: process.env.REACT_APP_ORGANIZATION_ID!,
      iframeUrl: "https://auth.turnkey.com",
    }}>
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <MakeStylesProvider theme={theme}>
          <CssBaseline />
          <Router>
            <SnackbarProvider maxSnack={3}>
              <TonConnectUIProvider manifestUrl="https://app.cabal.town/tonconnect-manifest.json">
                <App />
              </TonConnectUIProvider>
            </SnackbarProvider>
          </Router>
        </MakeStylesProvider>
      </ThemeProvider>
    </RecoilRoot>
  </TurnkeyProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
