import { Box, Typography, useTheme } from "@mui/material";
import { RetroCard } from "components/RetroCard";
import { ReactComponent as BenefitA } from "assets/icons/redeem-benefit-1.svg";
import { ReactComponent as BenefitB } from "assets/icons/redeem-benefit-2.svg";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { RetroButton } from "components/RetroButton";

interface BenefitItemConfig {
  title: string;
  description: string;
  icon: JSX.Element;
}

const benefitConfigs: BenefitItemConfig[] = [
  {
    title: "Benefit 1",
    description:
      "Share to Earn. Share your alpha trades & earn 50% of trading fees that your group generates. ",
    icon: <BenefitA />,
  },
  {
    title: "Benefit 2",
    description:
      "Trade Well and Get Discovered. More people will join your group and boost your revenue and influence!",
    icon: <BenefitB />,
  },
];

export const CreateCabalOnboarding = ({ onEndOnboarding }: { onEndOnboarding: () => void }) => {
  // Benefit
  const [slide, setSlide] = useState(0);
  const theme = useTheme();

  const handleSlideChange = (index: number) => {
    setSlide(index);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "fit-content",
        }}>
        <RetroCard>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              padding: "8px 24px",
              gap: "12px",
            }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                gap: "0 20px",
              }}>
              <Typography variant="title-h2">WHY BUILD</Typography>
              <Typography variant="title-h2">A CABAL?</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                overflowX: "scroll",
                background: theme.palette.surface.bg,
                borderRadius: "16px",
                border: `1px solid ${theme.palette.border.dark}`,
              }}>
              <Box
                sx={{
                  display: "flex",
                  position: "relative",
                  overflowX: "scroll",
                  scrollSnapType: "x mandatory",
                  msScrollSnapPointsX: "repeat(100%)",
                }}>
                {benefitConfigs.map((config) => {
                  return (
                    <BenefitItem
                      config={config}
                      index={benefitConfigs.indexOf(config)}
                      slid={slide}
                      handleSlideChange={handleSlideChange}
                    />
                  );
                })}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "16px",
              }}>
              {[0, 1].map((pageIndex) => (
                <Box
                  onClick={() => handleSlideChange(pageIndex)}
                  sx={{
                    border: `1px solid ${theme.palette.border.dark}`,
                    borderRadius: "50%",
                    width: "12px",
                    height: "12px",
                    background:
                      slide === pageIndex
                        ? theme.palette.brand.primary
                        : theme.palette.surface.silver,
                  }}
                />
              ))}
            </Box>
          </Box>
        </RetroCard>
        <RetroCard>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              padding: "8px",
              gap: "24px",
            }}>
            <Box
              sx={{
                width: "100%",
              }}>
              <RetroButton
                onClick={() => {
                  if (slide === 0) {
                    setSlide((pre) => pre + 1);
                  } else {
                    onEndOnboarding();
                  }
                }}>
                <Box
                  sx={{
                    padding: "12px 24px",
                  }}>
                  <Typography variant="title-h3">NEXT</Typography>
                </Box>
              </RetroButton>
            </Box>
          </Box>
        </RetroCard>
      </Box>
    </>
  );
};

const BenefitItem = ({
  config,
  index,
  slid,
  handleSlideChange,
}: {
  config: BenefitItemConfig;
  index: number;
  slid: number;
  handleSlideChange: (slide: number) => void;
}) => {
  const { title, description, icon } = config;

  const { ref, inView } = useInView();
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (inView) {
      handleSlideChange(index);
    }
  }, [inView]);

  useEffect(() => {
    if (slid === index && !inView) {
      scrollRef.current?.scrollIntoView();
    }
  }, [slid]);

  return (
    <Box
      ref={scrollRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        gap: "18px",
        minWidth: "100%",
        scrollSnapAlign: "start",
      }}>
      {icon}
      <Typography ref={ref} variant="title-h2">
        {title}
      </Typography>
      <Typography variant="body-md" color="text.secondary">
        {description}
      </Typography>
    </Box>
  );
};
