import { atom, useRecoilState } from "recoil";
import { useTurnkey } from "@turnkey/sdk-react";
import { ApiKeyStamper } from "@turnkey/api-key-stamper";
import { generateP256KeyPair } from "@turnkey/crypto";
import { TurnkeyBrowserClient, TurnkeySDKClientConfig } from "@turnkey/sdk-browser";
import { decryptObject, encryptObject } from "utils";
import axiosService from "services/axios";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";

const emailAuthState = atom({
  key: "emailAuth",
  default: {
    isLoading: false,
    isSessionExpired: false,
  },
});

export function useEmailAuth() {
  const { turnkey, authIframeClient } = useTurnkey();
  const { organizationId, createWallet } = useUserStoreV2();
  const [credential, setCredential] = useRecoilState(emailAuthState);

  const createWalletWithEmail = async (email: string, subOrgId?: string) => {
    const response = await createWallet(email, subOrgId);
    return response;
  };

  const createEmailAuthSession = async (
    email: string,
    subOrgId: string | undefined = organizationId,
  ) => {
    try {
      if (!authIframeClient) {
        console.error("No auth iframe client");
        return null;
      }

      if (!subOrgId) {
        console.error("No organizationId");
        return null;
      }

      const response = await axiosService.sendEmailAuth({
        email,
        organizationId: subOrgId,
        targetPublicKey: authIframeClient.iframePublicKey!,
      });

      return response.userId;
    } catch (error) {
      console.error("createEmailAuthSession error", error);
      return null;
    }
  };

  const verifyEmailAuth = async (credentialBundle: string, userId: string) => {
    if (!authIframeClient) {
      console.error("No auth iframe client");
      return null;
    }

    const currentUser = await turnkey?.getCurrentUser();
    const authenticationResponse = await authIframeClient.injectCredentialBundle(credentialBundle);

    if (authenticationResponse) {
      localStorage.setItem("credentialBundle", credentialBundle);

      // user is authenticated and can perform actions from the `iframeClient`
      try {
        // TODO: create api key session for the user
        const keyPair = generateP256KeyPair();
        const privateKey = keyPair.privateKey;
        const publicKey = keyPair.publicKey;

        const apiKeys = await authIframeClient.createApiKeys({
          userId: currentUser?.userId || userId!,
          organizationId,
          apiKeys: [
            {
              publicKey,
              apiKeyName: "api-key-session",
              curveType: "API_KEY_CURVE_P256",
              expirationSeconds: "31536000", // 1 year
            },
          ],
        });

        const apiKeyId = apiKeys.apiKeyIds[0];
        // Encrypt and store the private key securely
        const encrypted = encryptObject({ publicKey, privateKey, apiKeyId }, credentialBundle);
        localStorage.setItem("encryptionBundle", encrypted || "");
      } catch (error) {
        console.error("loginWithReadWriteSession error", error);
        setCredential({ isLoading: false, isSessionExpired: true });
      }
    }

    return authIframeClient;
  };

  const initUserEmailRecovery = async (email: string) => {
    if (!authIframeClient) {
      console.error("No auth iframe client");
      return null;
    }

    const currentUser = await turnkey?.getCurrentUser();

    if (!currentUser) {
      console.error("No current user");
      return null;
    }

    const response = await authIframeClient.updateUser({
      userId: currentUser?.userId!,
      userEmail: email,
      userTagIds: [],
    });
    return response;
  };

  const getCurrentUserEmail = async () => {
    if (!authIframeClient) {
      console.error("No auth iframe client");
      return null;
    }

    const currentUser = await turnkey?.getCurrentUser();

    if (!currentUser) {
      console.error("No current user");
      return null;
    }

    const { user } = await authIframeClient.getUser({
      organizationId: currentUser.organization.organizationId,
      userId: currentUser.userId,
    });

    return user.userEmail;
  };

  const getAuthClient = async () => {
    const credentialBundle = localStorage.getItem("credentialBundle");

    // Decrypt the stored private key
    const encryptedPrivateKey = localStorage.getItem("encryptionBundle");
    const { publicKey, privateKey, apiKeyId } =
      decryptObject(encryptedPrivateKey || "", credentialBundle || "") || {};

    if (!publicKey || !privateKey || !apiKeyId) {
      console.error("Failed to decrypt private key");
      setCredential({ isLoading: false, isSessionExpired: true });
      return null;
    }

    // Initialize the API key stamper
    const stamper = new ApiKeyStamper({ apiPublicKey: publicKey, apiPrivateKey: privateKey });

    // use the stamper in the client config
    const browserConfig: TurnkeySDKClientConfig = {
      stamper,
      apiBaseUrl: "https://api.turnkey.com",
      organizationId: organizationId!,
    };

    // create a TurnkeyClient with the initialized Telegram Cloud Storage Stamper
    const client = new TurnkeyBrowserClient(browserConfig);

    try {
      await client.getWhoami();
    } catch (error) {
      setCredential({ isLoading: false, isSessionExpired: true });
      return null;
    }

    return client;
  };

  const deleteApiKeys = async () => {
    try {
      const client = await getAuthClient();

      const whoami = await client?.getWhoami();

      const apiKeysResp = await client?.getApiKeys({
        organizationId: whoami?.organizationId!,
      });

      const deleteApiKeysResp = await client?.deleteApiKeys({
        userId: whoami?.userId!,
        apiKeyIds: apiKeysResp?.apiKeys.map((apiKey) => apiKey.apiKeyId) || [],
      });

      return deleteApiKeysResp;
    } catch (error) {
      console.error("deleteApiKeys error", error);
      return null;
    }
  };

  return {
    turnkey,
    credential,
    setCredential,
    verifyEmailAuth,
    authIframeClient,
    getCurrentUserEmail,
    initUserEmailRecovery,
    createWalletWithEmail,
    createEmailAuthSession,
    getAuthClient,
    deleteApiKeys,
  };
}
