import { Close, Tune } from "@mui/icons-material";
import { RetroButton } from "components/RetroButton";
import { CircleButton } from "components/CircleButton";
import { useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as XSquare } from "assets/icons/xsquare.svg";
import { Box, DrawerProps, MenuItem, Typography, useTheme } from "@mui/material";
import { useApproval, usePriceImpact, useTradeDrawer } from "hooks/useTradeDrawer";
import { buttonConfig, DarkDrawer, NumpadKey } from "components/TradeDrawer";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import { SmallNumberView } from "components/SmallValueView/SmallValueView";
import { Mode, MODES, TRADING_MODE_CONFIG_MAP } from "tradeConfigs";
import { AmountTooSmallDrawer } from "./AmountTooSmallDrawer";
import { useTradeContext } from "contexts/TradeContext";
import { SlippageDrawer } from "./SlippageDrawer";
import { Chain, CHAIN_CONFIG } from "chainConfigs";
import { ConfirmDrawer } from "./ConfirmDrawer";
import { BuySellSwitch } from "./BuySellSwitch";
import { RefetchPreview } from "./priceHint";
import { StyledSelect } from "./styled";
import { styled } from "@mui/material";
import RetroModal from "components/RetroModal";
import useTradeStore from "store/trade-store.ts/useTradeStore";
import useUserStoreV2, { DEFAULT_CACHED_SLIPPAGE } from "store/user-store-v2/useUserStoreV2";
import { Value } from "components/Value";

const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
  useGrouping: false,
});

interface CrossChainTradeDrawerV2Props {
  chain: Chain;
  groupId: number;
  isFromTrendingCall?: boolean;
  onProcessing?: () => void;
  onProcessed?: () => void;
  onConfirm?: (message?: string) => void;
  onFail?: (message?: string) => void;
  onClose: () => void;
  mode?: Mode;
}

const UNSUPPORTED_CHAINS: Chain[] = ["ton"];

export const CrossChainTradeDrawerV2 = (props: DrawerProps & CrossChainTradeDrawerV2Props) => {
  const {
    defaultMode,
    handleSetMode,
    mode: tradeMode,
    targetTokenPrice,
    targetTokenConfig,
    selectedNativeChain,
    selectedTargetChain,
    targetBalanceAmount,
    handleSetSelectedNativeChain,
    handleSetSelectedTargetChain,
  } = useTradeContext();

  const {
    onClose,
    inputUsd,
    isSellAll,
    priceHint,
    onConfirm,
    targetAmount,
    nativeAmount,
    onNumpadPress,
    onPercentPress,
    isOpenConfirmDrawer,
    shouldConfirmDisabled,
    handleOpenConfirmDrawer,
  } = useTradeDrawer({
    tgGroupId: props.groupId,
    isFromTrendingCall: props.isFromTrendingCall,
    onConfirm: props.onConfirm,
    onProcessing: props.onProcessing,
    onProcessed: props.onProcessed,
    onClose: () => {
      setModeHandler(defaultMode);
      props.onClose();
    },
    onFail: props.onFail,
    propsMode: props.mode,
  });

  const {
    priceImpact,
    noValidRouteMessage,
    isLoadingPriceImpact,
    isRefetchingPriceImpact,
    refetchPriceImpact,
  } = usePriceImpact({
    isSellAll,
    targetAmount,
    nativeAmount,
  });

  const { isApproving, isApproveSucess, isGetCurrentAllowanceLoading, handleApproveAllowance } =
    useApproval({ targetAmount });

  const { getUserBalance } = useTradeStore();

  const mode = props.mode ?? tradeMode;
  const tradeModeConfig = TRADING_MODE_CONFIG_MAP[mode];

  const isSelectable = tradeModeConfig.supportedNativeChains[selectedNativeChain]!.length > 1;

  // Slippage
  const { preferences = { slippage: DEFAULT_CACHED_SLIPPAGE } } = useUserStoreV2();
  const { slippage } = preferences;
  const [isOpenSlippageDrawer, setIsOpenSlippageDrawer] = useState(false);

  // Reminder Drawer
  const [shouldShowAmountTooSmall, setShouldShowAmountTooSmall] = useState(false);

  // selected token
  const [fromToken, setFromToken] = useState<string | null>(null);
  const [toToken, setToToken] = useState<string | null>(null);

  // Remindar Modal
  const [modalConfig, setModalConfig] = useState<{
    variant: "success" | "error";
    title: string;
    message: string;
  } | null>(null);

  const theme = useTheme();

  const isLoadingRef = useRef(false);

  // Slippage
  const handleOpenSlippageDrawer = () => {
    setIsOpenSlippageDrawer(true);
  };

  // Start Trading
  const handleConfirm = (slippage?: number) => {
    // Checks
    if (!slippage) {
      console.error("Slippage is not set");
      return;
    }

    if (
      tradeModeConfig.minimumEntryUSD !== undefined &&
      +inputUsd < tradeModeConfig.minimumEntryUSD
    ) {
      setShouldShowAmountTooSmall(true);
      return;
    }

    handleOpenConfirmDrawer(true);
  };

  const onChangeSelect = (type: "from" | "to", chain: Chain) => {
    if (type === "to") {
      setToToken(chain);
      [MODES.BUY, MODES.SELL].includes(mode)
        ? handleSetSelectedNativeChain(chain)
        : handleSetSelectedTargetChain(chain);
    } else {
      handleSetSelectedNativeChain(chain);
      setFromToken(chain);
    }
  };

  const isTradingWrappedToken =
    [MODES.BUY, MODES.SELL].includes(mode) &&
    CHAIN_CONFIG[selectedTargetChain].wrappedTokenAddress ===
      targetTokenConfig[selectedTargetChain]?.token;

  const fromTokenList = useMemo(() => {
    if (tradeModeConfig.from.isNative) {
      return tradeModeConfig.supportedNativeChains[selectedNativeChain]!.filter(
        (chain) =>
          !UNSUPPORTED_CHAINS.includes(chain) &&
          (!isTradingWrappedToken ||
            CHAIN_CONFIG[chain as Chain]?.wrappedTokenAddress !==
              targetTokenConfig[selectedTargetChain]?.token) &&
          (!tradeModeConfig.from.isBlockedSameChain || chain !== selectedNativeChain),
      ).map((chain) => {
        return {
          chain,
          amount: getUserBalance(chain, "amount"),
          imageUrl: CHAIN_CONFIG[chain as Chain].imageUrl,
          currency: CHAIN_CONFIG[chain as Chain].currency,
        };
      });
    } else if (isTradingWrappedToken) {
      return [CHAIN_CONFIG[selectedTargetChain]].map((config) => ({
        amount: getUserBalance(config.name, "amount"),
        imageUrl: config.imageUrl,
        currency: config.currency,
        chain: config.name,
      }));
    } else if (targetTokenConfig[selectedTargetChain]) {
      return [targetTokenConfig[selectedTargetChain]].map((config) => ({
        chain: selectedTargetChain,
        amount: targetBalanceAmount,
        imageUrl: config.imageUrl,
        currency: config.symbol,
      }));
    }
  }, [
    targetTokenConfig,
    selectedTargetChain,
    tradeModeConfig.from.isNative,
    tradeModeConfig.from.isBlockedSameChain,
    tradeModeConfig.supportedNativeChains,
    isTradingWrappedToken,
    selectedNativeChain,
    getUserBalance,
    targetBalanceAmount,
  ]);

  const toTokenList = useMemo(() => {
    if (tradeModeConfig.to.isNative) {
      return tradeModeConfig.supportedNativeChains[selectedNativeChain]!.filter(
        (chain) =>
          (!tradeModeConfig.to.isBlockedSameChain || chain !== selectedNativeChain) &&
          (!isTradingWrappedToken ||
            CHAIN_CONFIG[chain as Chain]?.wrappedTokenAddress !==
              targetTokenConfig[selectedTargetChain]?.token) &&
          !UNSUPPORTED_CHAINS.includes(chain),
      ).map((chain) => {
        return {
          chain,
          amount: getUserBalance(chain, "amount"),
          symbol: CHAIN_CONFIG[chain as Chain].symbol,
          imageUrl: CHAIN_CONFIG[chain as Chain].imageUrl,
          currency: CHAIN_CONFIG[chain as Chain].currency,
        };
      });
    }
    return [targetTokenConfig[selectedTargetChain]].map((config) => ({
      chain: selectedTargetChain,
      amount: targetBalanceAmount,
      imageUrl: config.imageUrl,
      currency: config.symbol,
      selected: true,
    }));
  }, [
    getUserBalance,
    targetTokenConfig,
    selectedNativeChain,
    selectedTargetChain,
    targetBalanceAmount,
    isTradingWrappedToken,
    tradeModeConfig.supportedNativeChains,
    tradeModeConfig.to.isBlockedSameChain,
    tradeModeConfig.to.isNative,
  ]);

  const setModeHandler = (mode: Mode) => {
    handleSetMode(mode);
    setFromToken(null);
    setToToken(null);
    isLoadingRef.current = false;
  };

  useEffect(() => {
    if (!isLoadingRef.current && !fromToken && !toToken && fromTokenList && toTokenList) {
      isLoadingRef.current = true;
      const fromTokenChain = props.chain ?? selectedNativeChain;

      setFromToken(isTradingWrappedToken ? fromTokenList[0]?.chain : fromTokenChain);
      setToToken(isTradingWrappedToken ? toTokenList[0]?.chain : selectedTargetChain);
      handleSetSelectedNativeChain(
        isTradingWrappedToken
          ? mode === "BUY"
            ? fromTokenList[0]?.chain
            : toTokenList[0]?.chain
          : fromTokenChain,
      );
    }
  }, [
    mode,
    toToken,
    fromToken,
    toTokenList,
    fromTokenList,
    handleSetSelectedNativeChain,
    isTradingWrappedToken,
    selectedNativeChain,
    selectedTargetChain,
    props.chain,
  ]);

  return (
    <>
      <DarkDrawer anchor="bottom" sx={{ zIndex: 100 }} open={props.open}>
        {/* Tool Bar */}
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          {/* Slippage Button */}
          <Box>
            <CircleButton variant="white" size="lg" onClick={handleOpenSlippageDrawer}>
              <Tune />
            </CircleButton>
          </Box>
          {/* Buy / Sell */}
          <BuySellSwitch mode={mode} handleSetMode={setModeHandler} />
          {/* Close Button */}
          <Box>
            <CircleButton variant="white" size="lg" onClick={onClose}>
              <Close />
            </CircleButton>
          </Box>
        </Box>
        {/* Trade detail */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "32px",
            gap: "8px",
          }}>
          {/* Source Token Select (balance USD) */}
          <Box sx={{ flex: 1, display: "flex", justifyContent: "end", alignItems: "center" }}>
            <Box sx={{ width: "158px" }}>
              <StyledSelect
                value={fromToken}
                noPointer={!isSelectable || !tradeModeConfig.from.isNative}
                sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
                MenuProps={{
                  slotProps: {
                    paper: {
                      sx: {
                        borderRadius: theme.spacing(1.5),
                        backgroundColor: theme.palette.surface.silver,
                      },
                    },
                  },
                }}
                onChange={(event) => onChangeSelect("from", event.target.value as Chain)}
                IconComponent={
                  !isSelectable || !tradeModeConfig.from.isNative ? () => null : undefined
                }>
                {/* Default Option */}
                {fromTokenList?.map((token) => (
                  <MenuItem
                    value={token?.chain}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: theme.spacing(1),
                    }}>
                    <img
                      src={token?.imageUrl}
                      alt={token?.chain}
                      width={16}
                      height={16}
                      style={{ borderRadius: "50%" }}
                    />
                    <Typography
                      sx={{ width: "100%", textAlign: "center" }}
                      variant="body-default"
                      color={theme.palette.text.secondary}>
                      <Value
                        variant="body-default"
                        value={token?.amount!}
                        useFixedDecimals
                        zeroCountSignificantDigits={2}
                        smallNumberSignificantDigits={2}
                      />{" "}
                      {token?.currency}
                    </Typography>
                  </MenuItem>
                ))}
              </StyledSelect>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <ArrowRight width={24} />
          </Box>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "start", alignItems: "center" }}>
            <Box sx={{ width: "158px" }}>
              <StyledSelect
                value={toToken}
                noPointer={!isSelectable || !tradeModeConfig.to.isNative}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: theme.spacing(1),
                }}
                MenuProps={{
                  slotProps: {
                    paper: {
                      sx: {
                        borderRadius: theme.spacing(1.5),
                        backgroundColor: theme.palette.surface.silver,
                      },
                    },
                  },
                }}
                onChange={(event) => onChangeSelect("to", event.target.value as Chain)}
                IconComponent={
                  !isSelectable || !tradeModeConfig.to.isNative ? () => null : undefined
                }>
                {/* Default Option */}
                {toTokenList.map((token) => (
                  <MenuItem
                    value={token.chain}
                    sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                    <img src={token.imageUrl} alt={token.chain} width={16} height={16} />
                    <Typography
                      sx={{ width: "100%", textAlign: "center" }}
                      variant="body-default"
                      color={theme.palette.text.secondary}>
                      {token.currency}
                    </Typography>
                  </MenuItem>
                ))}
              </StyledSelect>
            </Box>
          </Box>
        </Box>

        {/* Price Display */}
        <Box sx={{ marginTop: theme.spacing(1), marginX: "auto", textAlign: "center" }}>
          {/* Entered Value (USD) */}
          <Box>
            <Value
              variant="display-md"
              value={inputUsd}
              isPrice
              useFixedDecimals
              useKMBTFormat={false}
            />
          </Box>
          {/* Target Token (Amount) Received Preview */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Typography
              variant="body-md"
              color={theme.palette.text.secondary}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "8px" }}>
              ≈{" "}
              <Value
                variant="body-md"
                value={tradeModeConfig.to.isNative ? nativeAmount : targetAmount}
              />
              {tradeModeConfig.to.isNative ? (
                <img
                  src={CHAIN_CONFIG[selectedNativeChain as Chain].imageUrl}
                  alt={CHAIN_CONFIG[selectedNativeChain]?.symbol}
                  width={16}
                  height={16}
                  style={{ margin: "auto" }}
                />
              ) : (
                <img
                  src={targetTokenConfig[selectedTargetChain]?.imageUrl}
                  alt={targetTokenConfig[selectedTargetChain]?.symbol}
                  width={16}
                  height={16}
                  style={{ borderRadius: "50%" }}
                />
              )}{" "}
              {/* ({numberFormatter.format(price)} USD) */}
            </Typography>
          </Box>
        </Box>
        {/* Remindar */}
        {noValidRouteMessage && selectedNativeChain === selectedTargetChain ? (
          <RefetchPreview
            isLoading={isLoadingPriceImpact}
            isRefetching={isRefetchingPriceImpact}
            refetch={refetchPriceImpact}
            message={noValidRouteMessage}
          />
        ) : priceHint ? (
          CHAIN_CONFIG[selectedTargetChain].isEvm &&
          !tradeModeConfig.from.isNative &&
          tradeModeConfig.to.isNative &&
          !isApproveSucess ? (
            <RefetchPreview
              isLoading={isLoadingPriceImpact}
              isRefetching={isRefetchingPriceImpact}
              refetch={refetchPriceImpact}
              message={"Approval amount exceeded balance."}
            />
          ) : (
            priceHint
          )
        ) : priceImpact && priceImpact / +inputUsd > 0.05 ? (
          <RefetchPreview
            isLoading={isLoadingPriceImpact}
            isRefetching={isRefetchingPriceImpact}
            refetch={refetchPriceImpact}
            message={"Price impact exceeded 5% of your trade size. Proceed with caution."}
          />
        ) : null}

        {/* Percentage */}
        <Box
          display={"flex"}
          gap={theme.spacing(1)}
          marginTop={theme.spacing(1)}
          width={"fit-content"}
          marginX={"auto"}>
          {buttonConfig.map((item) => (
            <RetroButton
              size="sm"
              key={item.label}
              onClick={() => {
                onPercentPress(item.value);
              }}>
              <Box sx={{ padding: theme.spacing(0.0, 2) }}>
                <Typography variant="body-default">{item.label}</Typography>
              </Box>
            </RetroButton>
          ))}
        </Box>

        {/* Numpad */}
        <Box
          marginTop={theme.spacing(1)}
          display={"flex"}
          flexWrap={"wrap"}
          width={"100%"}
          marginX={"auto"}
          maxWidth={"600px"}>
          {Array.from({ length: 9 }).map((_, index) => (
            <NumpadButton key={index} onClick={() => onNumpadPress(`${index + 1}` as NumpadKey)}>
              <Typography variant="title-h1">{index + 1}</Typography>
            </NumpadButton>
          ))}
          <NumpadButton onClick={() => onNumpadPress(".")}>
            <Typography variant="title-h1">.</Typography>
          </NumpadButton>
          <NumpadButton onClick={() => onNumpadPress("0")}>
            <Typography variant="title-h1">0</Typography>
          </NumpadButton>
          <NumpadButton
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            onClick={() => onNumpadPress("delete")}>
            <XSquare />
          </NumpadButton>
        </Box>

        {/* Confirm Button */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "4px",
          }}>
          <Typography variant="pre-title" color={theme.palette.text.secondary}>
            {priceImpact ? (
              <>
                -<SmallNumberView value={priceImpact} variants="pre-title" isUSD />
              </>
            ) : (
              "$--"
            )}{" "}
            net change • {slippage ? <Value variant="pre-title" value={slippage} /> : "--"}%
            slippage
          </Typography>
        </Box>
        {CHAIN_CONFIG[selectedTargetChain].isEvm &&
        !tradeModeConfig.from.isNative &&
        tradeModeConfig.to.isNative &&
        !isApproveSucess ? (
          <RetroButton
            onClick={handleApproveAllowance}
            disabled={shouldConfirmDisabled || isApproving || isGetCurrentAllowanceLoading}>
            <Box sx={{ padding: theme.spacing(1.5, 3) }}>
              <Typography variant="title-h3">
                {isGetCurrentAllowanceLoading ? "Loading..." : "APPROVE SPENDING"}
              </Typography>
            </Box>
          </RetroButton>
        ) : (
          <RetroButton
            onClick={() => handleConfirm(slippage)}
            disabled={
              (noValidRouteMessage && selectedTargetChain !== selectedNativeChain) ||
              shouldConfirmDisabled
            }>
            <Box sx={{ padding: theme.spacing(1.5, 3) }}>
              <Typography variant="title-h3">CONFIRM</Typography>
            </Box>
          </RetroButton>
        )}
      </DarkDrawer>

      {/* Amount too small drawer */}
      <AmountTooSmallDrawer
        isOpen={shouldShowAmountTooSmall}
        close={() => setShouldShowAmountTooSmall(false)}
      />

      {/* Confirm drawer */}
      <ConfirmDrawer
        mode={mode}
        isOpen={isOpenConfirmDrawer}
        close={() => handleOpenConfirmDrawer(false)}
        inputUsd={inputUsd}
        targetAmount={targetAmount}
        targetTokenPrice={targetTokenPrice ?? 0}
        targetTokenSymbol={targetTokenConfig[selectedTargetChain]?.symbol ?? ""}
        onConfirm={(slippage) => {
          onConfirm(slippage);
          handleOpenConfirmDrawer(false);
        }}
      />

      {/* Slippage Drawer */}
      <SlippageDrawer isOpen={isOpenSlippageDrawer} close={() => setIsOpenSlippageDrawer(false)} />

      {/* Modal */}
      <RetroModal
        open={modalConfig !== null}
        onClose={() => {
          setModalConfig(null);
        }}
        variant={modalConfig?.variant ?? "success"}
        title={modalConfig?.title ?? ""}
        message={modalConfig?.message ?? ""}
      />
    </>
  );
};

const NumpadButton = styled("button")(({ theme }) => ({
  all: "unset",
  width: "33%",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  textAlign: "center",

  background: "transparent",
  border: "none",
  cursor: "pointer",
}));
