import { useEffect } from "react";
import { Profile } from "./Profile";
import { ROUTES } from "consts";
import { PortfolioContainer } from "./styled";
import { WalletDetail } from "./WalletDetail";
import { RetroCard } from "components/RetroCard";
import { Box, useTheme } from "@mui/material";
import { PortfolioDetail } from "./PortfolioDetail";
import { RetroButton } from "components/RetroButton";
import { Typography } from "@mui/material";
import { Add, Cached, Group } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { PricePerformanceWithPercentagBlock } from "components/PercentageBrick";
import useLayoutConfigStore from "store/layout-config-store/useLayoutConfigStore";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { Value } from "components/Value";
import { usePortfolioContext } from "contexts/PortfolioContext";

const Portfolio = () => {
  const { isShowHeader, setIsShowHeader } = useLayoutConfigStore();
  const { hasWallet } = useUserStoreV2();
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    userAllPortfolioAssetsData: data,
    isUserAllPortfolioAssetsLoading: isLoading,
    isUserAllPortfolioAssetsRefetching: isRefetching,
    refetchFullAllData,
  } = usePortfolioContext();

  useEffect(() => {
    if (isShowHeader) {
      setIsShowHeader(false);
    }

    return () => {
      setIsShowHeader(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigteToCreateCabal = () => {
    navigate(ROUTES.create);
  };

  const navigateToReferral = () => {
    navigate(ROUTES.referral);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        paddingTop: "20px",
      }}>
      <Box sx={{ width: "100%" }}>
        <RetroCard size="sm">
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              padding: "20px",
            }}>
            <PortfolioContainer>
              <Profile />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}>
                <RetroButton variant="white" onClick={navigateToReferral}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "4px",
                      padding: "8px",
                    }}>
                    <Group fontSize="small" />
                    <Typography variant="button-md">Referral</Typography>
                  </Box>
                </RetroButton>
                {/* <RetroButton
                  variant={hasWallet ? "primary" : "white"}
                  onClick={navigteToCreateCabal}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "4px",
                      padding: "8px",
                    }}>
                    <Add
                      style={{ color: theme.palette.text.brand, fontWeight: "bolder" }}
                      fontSize="small"
                    />
                    <Typography
                      variant="button-md"
                      color={hasWallet ? "text.brand" : "text.btn-dark"}>
                      Create Group
                    </Typography>
                  </Box>
                </RetroButton> */}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "12px",
                  width: "100%",
                  border: `1px solid ${theme.palette.border.dark}`,
                  borderRadius: "16px",
                  bgcolor: theme.palette.surface["container-bg"],
                }}>
                {!isLoading && data ? (
                  <>
                    <Typography variant="title-h1">
                      <Value
                        variant="title-h1"
                        value={data.totalAssetsValue}
                        isPrice
                        useFixedDecimals
                        useKMBTFormat={false}
                      />
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "4px",
                      }}>
                      <PricePerformanceWithPercentagBlock
                        performance={data.totalPerformanceInUsd}
                        percentage={data.totalPerformance}
                      />
                      <Cached
                        onClick={() => refetchFullAllData()}
                        sx={{
                          cursor: "pointer",
                          width: "20px",
                          height: "20px",
                          ...((isLoading || isRefetching) && {
                            animation: "spin 2s linear infinite",
                            "@keyframes spin": {
                              "0%": {
                                transform: "rotate(360deg)",
                              },
                              "100%": {
                                transform: "rotate(0deg)",
                              },
                            },
                          }),
                        }}
                      />
                    </Box>
                  </>
                ) : (
                  <Typography variant="title-h1">Loading...</Typography>
                )}
              </Box>
              <WalletDetail />
            </PortfolioContainer>
          </Box>
        </RetroCard>
      </Box>
      <Box sx={{ width: "100%", height: "100%" }}>
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}>
          <PortfolioContainer>
            <PortfolioDetail />
          </PortfolioContainer>
        </Box>
        {/* </RetroCard> */}
      </Box>
    </Box>
  );
};

export { Portfolio };
