import { Box, Typography } from "@mui/material";
import { ReactComponent as CabalTownCircleLogo } from "assets/icons/cabal-town-logo-circle.svg";
import { Telegram } from "@mui/icons-material";
import { RetroButton } from "components/RetroButton";
import theme from "theme";

export const LoginCheckBlocker = () => {
  const handleLogin = () => {
    const referralId = window.localStorage.getItem("referralId");
    const botName = process.env.REACT_APP_TG_BOT_NAME!;
    window.open(`https://t.me/${botName}?start=login-${referralId}`, "_blank");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        padding: "16px",
        textAlign: "center",
      }}>
      <Box>
        <CabalTownCircleLogo />
      </Box>
      <Typography sx={{ marginBottom: "10px" }} variant="title-h2">
        CABAL.TOWN
      </Typography>
      <Typography
        sx={{ marginBottom: "12px", maxWidth: "250px" }}
        variant="body-md"
        color="text.secondary">
        Shill. Trade. Earn
      </Typography>
      <Box
        id="telegram-login-container"
        sx={{
          position: "relative",
          marginX: "auto",
          height: "56px",
          maxWidth: "300px",
          width: "100%",
          // paddingX: "12px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
        }}>
        <RetroButton onClick={handleLogin}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: `${theme.spacing(1)}`,
            }}>
            <Telegram />
            <Typography variant="title-h3">LOGIN</Typography>
          </Box>
        </RetroButton>
      </Box>
    </Box>
  );
};
