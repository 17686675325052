import { Box, Button, Container, Typography, useTheme } from "@mui/material";
import { FullPageHeader } from "components/Fullpage";
import FullPageWrapper from "components/FullPageWrapper";
import { RetroCard } from "components/RetroCard";
import { ReactComponent as BackArrow } from "assets/icons/arrow-left.svg";
import { ReactComponent as StepOneIcon } from "assets/icons/referral-how-it-works-step-1.svg";
import { ReactComponent as StepTwoIcon } from "assets/icons/referral-how-it-works-step-2.svg";
import { ReactComponent as StepThreeIcon } from "assets/icons/referral-how-it-works-step-3.svg";
import { AuthCheckBlocker } from "components/Blockers";
import { ROUTES } from "consts";
import { useNavigatePreserveQuery } from "lib/hooks/useNavigatePreserveQuery";
import { RetroButton } from "components/RetroButton";
import { ReferralPageBody } from "components/Referral";
import RetroModal from "components/RetroModal";
import { Close, Telegram, X } from "@mui/icons-material";
import InveiteIcon from "assets/icons/referrals-invite.svg";
import { CircleButton } from "components/CircleButton";
import { SetStateAction, useEffect, useRef, useState } from "react";
import { copyToClipboard } from "utils";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useInViewInfiniteQuery } from "hooks/useInViewInfiniteQuery";
import axiosService from "services/axios";
import useToastStore from "store/toast-store/useToastStore";
import useScript from "hooks/useScript";

export const ReferralTabs = ["all", "user", "group"];

const ReferralPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isHowItWorksModalOpen, setIsHowItWorksModalOpen] = useState(
    !localStorage.getItem("isSkipHowItWorksModalOpen"),
  );
  const [page, setPage] = useState(1);

  const { tgUserId } = useUserStoreV2();
  useScript("https://platform.twitter.com/widgets.js");
  const navigate = useNavigatePreserveQuery();
  const theme = useTheme();

  const twitterShareButtonRef = useRef<HTMLAnchorElement>(null);
  const { showToast } = useToastStore();

  const { data: referralOverview, isLoading: isReferralOverviewLoading } = useQuery({
    queryKey: ["referralOverview", tgUserId],
    queryFn: () => axiosService.getReferralOverview(),
    enabled: !!tgUserId,
  });

  const queryResult = useInfiniteQuery({
    queryKey: [
      "referrals",
      {
        page,
        type: ReferralTabs[selectedTab],
        referredBy: tgUserId?.toString(),
      },
    ],
    queryFn: async ({ pageParam }) => {
      return axiosService.getReferralCode({
        filter: {
          type: ReferralTabs[selectedTab],
          referredBy: tgUserId?.toString(),
        },
        selects: ["tgUserId", "isGroupTrade", "tgGroupId", "referralEarningUsd", "groupName"],
        limit: 10,
        page: pageParam,
      });
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.page + 1;
    },

    getPreviousPageParam: (firstPage, pages) => {
      return firstPage.page - 1;
    },
    initialPageParam: 1,
    enabled: !!tgUserId,
  });

  const { ref, hasAllFetched } = useInViewInfiniteQuery(queryResult);

  const { data, isLoading } = queryResult;

  const navigateToPortfolio = () => {
    navigate(ROUTES.portfolio);
  };

  const copyReferralLink = () => {
    copyToClipboard(`https://app.cabal.town?referralId=${tgUserId}`);
    showToast({
      message: "Referral link copied to clipboard",
      variant: "success",
    });
  };

  return (
    <>
      <FullPageWrapper>
        <FullPageHeader>
          <Container>
            <Button sx={{ padding: "0", cursor: "pointer" }} onClick={() => navigateToPortfolio()}>
              <BackArrow />
              <Typography variant="button-md" color="text.secondary">
                Portfolio
              </Typography>
            </Button>
          </Container>
        </FullPageHeader>
        <Container sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(2) }}>
          <AuthCheckBlocker withCard>
            {isReferralOverviewLoading ? (
              <></>
            ) : (
              <ReferralPageBody
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                isLoading={isLoading}
                isReferralOverviewLoading={isReferralOverviewLoading}
                hasAllFetched={hasAllFetched}
                inViewRef={ref}
                data={data?.pages.flatMap((page) => page.results) || []}
                openHowItWorksModal={() => setIsHowItWorksModalOpen(true)}
                referralOverviewData={referralOverview[0]}
                onInviteNow={() => {
                  setIsShareModalOpen(true);
                  setIsHowItWorksModalOpen(false);
                }}
              />
            )}
          </AuthCheckBlocker>
        </Container>
        <Box sx={{ height: "112px" }} />
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 2,
          }}>
          <RetroCard>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "8px",
                padding: "12px",
              }}>
              <RetroButton variant="white" onClick={copyReferralLink}>
                <Box
                  sx={{
                    padding: "12px",
                  }}>
                  <Typography variant="title-h3">COPY</Typography>
                </Box>
              </RetroButton>
              <RetroButton onClick={() => setIsShareModalOpen(true)}>
                <Box
                  sx={{
                    padding: "12px",
                  }}>
                  <Typography variant="title-h3">SHARE</Typography>
                </Box>
              </RetroButton>
            </Box>
          </RetroCard>
        </Box>
      </FullPageWrapper>
      <RetroModal
        sx={{
          opacity: isShareModalOpen ? 1 : 0,
          pointerEvents: isShareModalOpen ? "auto" : "none",
        }}
        open={true}
        onClose={() => {
          setIsShareModalOpen(false);
        }}>
        <Box sx={{ padding: theme.spacing(2.5), display: "flex", flexDirection: "column" }}>
          <Box display={"flex"} justifyContent={"flex-end"}>
            <CircleButton
              variant="white"
              size="md"
              onClick={() => {
                setIsShareModalOpen(false);
              }}>
              <Close />
            </CircleButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              maxWidth: "360px",
              margin: "0 auto",
            }}>
            <Box display={"flex"} justifyContent={"center"}>
              <img src={InveiteIcon} alt="icon" />
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              textAlign={"center"}>
              <Typography variant="title-h2">INVITE YOUR FRIENDS</Typography>
              <Box sx={{ display: "flex", flexDirection: "column", margin: "12px 0px 8px" }}>
                <Typography variant="body-default" color={theme.palette.text.secondary}>
                  Earn 10% of trading fees from groups they own
                </Typography>
                <Typography variant="body-default" color={theme.palette.text.secondary}>
                  and 1% of fees from their own trades
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: "12px",
                width: "fit-content",
                margin: "0 auto",
              }}>
              <Box sx={{ margin: "0 auto" }}>
                {/* <RetroButton> */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "4px",
                  }}>
                  {/* <Typography variant="title-h3">SHARE ON </Typography> */}
                  {/* <X fontSize="small"/> */}
                  <a
                    ref={twitterShareButtonRef}
                    href="https://twitter.com/share?ref_src=twsrc%5Etfw"
                    className="twitter-share-button"
                    data-size="large"
                    data-text="Join me at Cabal Town.  Earn up to 50% of the fees from your trading cabals!"
                    data-url={`https://app.cabal.town?referralId=${tgUserId}`}
                    data-show-count="false">
                    Share
                  </a>
                </Box>
                {/* </RetroButton> */}
              </Box>
              <Box sx={{ margin: "0 auto" }}>
                {/* <RetroButton> */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "4px",
                  }}>
                  {/* <Typography variant="title-h3">Share </Typography> */}
                  <a
                    href={`https://t.me/share/url?url=${`https://app.cabal.town?referralId=${tgUserId}`}&text=${"Join me at Cabal Town.  Earn up to 50% of the fees from your trading cabals!"}`}
                    target="_blank"
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#2481cc",
                      color: "#fff",
                      padding: "2px 12px",
                      font: "normal normal normal 12px/18px 'Helvetica Neue',Arial,sans-serif",
                      fontWeight: "500",
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "4px",
                      height: "28px",
                    }}>
                    <Telegram fontSize="small" />
                    <b>Share</b>
                  </a>
                  {/* <script async src="https://telegram.org/js/telegram-widget.js?22" data-telegram-share-url="https://t.me/CabalTown_Bot " data-comment="Join me in the Cabal Town. Trade together and earn up to 50% trade fees" data-size="large"></script> */}
                </Box>
                {/* </RetroButton> */}
              </Box>
            </Box>
          </Box>
          <Box height={"24px"} />
        </Box>
      </RetroModal>
      {/* How it works */}
      <HowItWorksModal
        open={isHowItWorksModalOpen}
        close={() => {
          localStorage.setItem("isSkipHowItWorksModalOpen", "true");
          setIsHowItWorksModalOpen(false);
        }}
        handleInviteNow={() => {
          setIsShareModalOpen(true);
          setIsHowItWorksModalOpen(false);
        }}
      />
    </>
  );
};

interface HowItWorksBodyItemConfig {
  description: string;
  icon: JSX.Element;
}

const howItWorksBodyConfigs = [
  {
    description: "Share the invite link to your friend via Telegram or X.",
    icon: <StepOneIcon />,
  },
  {
    description:
      "Earn 10% of trading fees from groups they own and 1% of fees from their own trades",
    icon: <StepThreeIcon />,
  },
];

const HowItWorksModal = ({
  open,
  close,
  handleInviteNow,
}: {
  open: boolean;
  close: () => void;
  handleInviteNow: () => void;
}) => {
  // Benefit
  const [slide, setSlide] = useState(0);
  const theme = useTheme();

  const handleSlideChange = (index: number) => {
    setSlide(index);
  };

  return (
    <RetroModal open={open} onClose={close}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "calc(100vh - 91px)",
        }}>
        <RetroCard>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              padding: "16px 24px",
              gap: "24px",
              height: "100%",
            }}>
            <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
              <CircleButton variant="white" size="md" onClick={close}>
                <Close />
              </CircleButton>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              textAlign={"center"}>
              <Typography variant="title-h2">How it Works</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                overflowX: "scroll",
                background: theme.palette.surface.bg,
                borderRadius: "16px",
                border: `1px solid ${theme.palette.border.dark}`,
                width: "calc(100% - 12px)",
                height: "100%",
              }}>
              <Box
                sx={{
                  display: "flex",
                  position: "relative",
                  overflowX: "scroll",
                  scrollSnapType: "x mandatory",
                  msScrollSnapPointsX: "repeat(calc(100% - 12px))",
                }}>
                {howItWorksBodyConfigs.map((config) => {
                  return (
                    <BenefitItem
                      config={config}
                      index={howItWorksBodyConfigs.indexOf(config)}
                      slid={slide}
                      handleSlideChange={handleSlideChange}
                      onInviteNow={handleInviteNow}
                    />
                  );
                })}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "16px",
              }}>
              {Array.from({ length: howItWorksBodyConfigs.length }).map((_, pageIndex) => (
                <Box
                  onClick={() => handleSlideChange(pageIndex)}
                  sx={{
                    border: `1px solid ${theme.palette.border.dark}`,
                    borderRadius: "50%",
                    width: "12px",
                    height: "12px",
                    background:
                      slide === pageIndex
                        ? theme.palette.brand.primary
                        : theme.palette.surface.silver,
                  }}
                />
              ))}
            </Box>
          </Box>
        </RetroCard>
      </Box>
    </RetroModal>
  );
};

const BenefitItem = ({
  config,
  index,
  slid,
  handleSlideChange,
  onInviteNow,
}: // referralOverviewData
{
  config: HowItWorksBodyItemConfig;
  index: number;
  slid: number;
  handleSlideChange: (slide: number) => void;
  onInviteNow: () => void;
  // referralOverviewData: any;
}) => {
  const { description, icon } = config;

  const { ref, inView } = useInView();
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (inView) {
      handleSlideChange(index);
    }
  }, [inView]);

  useEffect(() => {
    if (slid === index && !inView) {
      scrollRef.current?.scrollIntoView();
    }
  }, [slid]);

  return (
    <Box
      ref={scrollRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        gap: "18px",
        minWidth: "100%",
        scrollSnapAlign: "start",
        height: "100%",
      }}>
      {icon}
      <Typography ref={ref} variant="body-md" color="text.secondary">
        {description}
      </Typography>
      <Box>
        {index === 0 ? (
          <RetroButton onClick={() => handleSlideChange(index + 1)}>
            <Box
              sx={{
                padding: "8px 16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "4px",
                minWidth: "114px",
              }}>
              <Typography variant="button-md">NEXT</Typography>
            </Box>
          </RetroButton>
        ) : (
          <RetroButton onClick={onInviteNow}>
            <Box
              sx={{
                padding: "8px 16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "4px",
                whiteSpace: "nowrap",
                minWidth: "114px",
              }}>
              <Typography variant="button-md">INVITE NOW</Typography>
            </Box>
          </RetroButton>
        )}
      </Box>
    </Box>
  );
};

export { ReferralPage };
