import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

export interface LayoutConfigStoreState {
  isShowHeader: boolean;
  isShowFooter: boolean;
}

const { persistAtom } = recoilPersist({
  key: "layoutConfigStateAtom",
});

// TODO
const layoutConfigStateAtom = atom<LayoutConfigStoreState>({
  key: "layoutConfigStateAtom",
  default: {
    isShowHeader: true,
    isShowFooter: true,
  },
});

export { layoutConfigStateAtom };
