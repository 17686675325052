/* eslint-disable react-hooks/exhaustive-deps */
import theme from "theme";
import { Box, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { RetroButton } from "components/RetroButton";
import { Cached, Lock } from "@mui/icons-material";
import {
  PortfolioDetailListContainer,
  PortfolioDetailListHeader,
  PortfolioDetailListItemColumnContainer,
  PortfolioDetailListItemContainer,
  PortfolioDetailListTitle,
  TapsContainer,
} from "./styled";
import { PricePerformanceBrick } from "components/PercentageBrick";
import CoinAvatar, { NATIVE_TOKEN_MAP } from "components/CoinAvatar";
import { useInfiniteQuery } from "@tanstack/react-query";
import { UserPortfolioAssets, UserPortfolioEarning, UserPortfolioJoinedGroup } from "types";
import { useNavigatePreserveQuery } from "lib/hooks/useNavigatePreserveQuery";
import { LoadingBlock } from "components/LoadingScreen";
import { useInViewInfiniteQuery } from "hooks/useInViewInfiniteQuery";
import { ScrollableBox } from "components/ScrollableBox";
import { LoadMoreRow } from "components/LoadMoreRow";
import { useNavigate } from "react-router-dom";
import { SmallNumberView } from "components/SmallValueView/SmallValueView";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import useToastStore from "store/toast-store/useToastStore";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import axiosService from "services/axios";
import { TradeProvider } from "contexts/TradeContext";
import { RetroCard } from "components/RetroCard";
import useTradeStore from "store/trade-store.ts/useTradeStore";
import { CrossChainTradeDrawerV2 } from "components/CrossChainSwapDrawerV2";
import {
  Chain,
  CHAIN_CONFIG,
  ChainCurrency,
  ChainSymbol,
  NATIVE_CURRENCY_CHECK_LIST,
  WRAPPED_CURRENCY_CHECK_LIST,
  WrappedCurrency,
} from "chainConfigs";
import { CHAIN_DECIMAL, CHAIN_EXPLORER } from "consts";
import { useTxnSign } from "hooks/useTxnSign";
import wEthAbi from "lib/erc/wEthAbi.json";
import { UNSUPPORTED_SWAP_TOKENS } from "tradeConfigs";
import { Value } from "components/Value";
import { usePortfolioContext } from "contexts/PortfolioContext";

export const PortfolioDetail = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <>
      <TapsContainer>
        {["Assets", "Earning", "Groups"].map((tab, index) => {
          return (
            <RetroButton
              key={tab + index}
              disabled={selectedTab === index}
              onClick={() => setSelectedTab(index)}>
              <Box
                sx={{
                  padding: "4px",
                }}>
                <Typography
                  variant="button-md"
                  color={selectedTab === index ? "text.primary" : "text.brand"}>
                  {tab}
                </Typography>
              </Box>
            </RetroButton>
          );
        })}
      </TapsContainer>
      <RetroCard size="sm">
        <PortfolioDetailListContainer>
          {selectedTab === 0 && <AssetsView />}
          {selectedTab === 1 && <EarningView />}
          {selectedTab === 2 && <CabalsView />}
        </PortfolioDetailListContainer>
      </RetroCard>
    </>
  );
};

const AssetsView = () => {
  const [shouldTradeDrawerOpen, setShouldTradeDrawerOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedChain, setSelectedChain] = useState<Chain>("solana");
  const { updateUserBalance } = useTradeStore();
  const {
    assetsData: data,
    isAssetsLoading: isLoading,
    refetchAssetsAndTotalAssets: refetch,
    isAssetsRefetching: isRefetching,
    hasAllFetchedAssets: hasAllFetched,
    assetsRef: ref,
  } = usePortfolioContext();

  const refetchAssets = useCallback(
    async (symbol: string) => {
      const MAX_ATTEMPTS = 10;
      let attempts = 0;
      let isSymbolFound = true;

      while (isSymbolFound && attempts < MAX_ATTEMPTS) {
        const result = await refetch();
        attempts++;

        const asset = result.data?.pages
          ?.flatMap((page: any) => page.results)
          .find((item: any) => item.symbol === symbol);

        isSymbolFound = !!asset;

        if (isSymbolFound) {
          // Wait a bit before next attempt
          await new Promise((resolve) => setTimeout(resolve, 2000));
        }
      }

      await updateUserBalance();
    },
    [refetch, updateUserBalance],
  );

  const onClickShowDrawer = (chain: Chain) => {
    setSelectedChain(chain);
    setShouldTradeDrawerOpen(true);
  };

  useEffect(() => {
    updateUserBalance();
  }, []);

  return (
    <>
      <TradeProvider
        chain={selectedChain}
        mode="SWAP"
        targetTokenConfig={{
          solana: {
            chain: CHAIN_CONFIG["solana"].name,
            symbol: CHAIN_CONFIG["solana"].currency,
            token: CHAIN_CONFIG["solana"].nativeTokenAddress,
          },
          ethereum: {
            chain: CHAIN_CONFIG["ethereum"].name,
            symbol: CHAIN_CONFIG["ethereum"].currency,
            token: CHAIN_CONFIG["ethereum"].nativeTokenAddress,
          },
          base: {
            chain: CHAIN_CONFIG["base"].name,
            symbol: CHAIN_CONFIG["base"].currency,
            token: CHAIN_CONFIG["base"].nativeTokenAddress,
          },
          berachain: {
            chain: CHAIN_CONFIG["berachain"].name,
            symbol: CHAIN_CONFIG["berachain"].currency,
            token: CHAIN_CONFIG["berachain"].nativeTokenAddress,
          },
          bsc: {
            chain: CHAIN_CONFIG["bsc"].name,
            symbol: CHAIN_CONFIG["bsc"].currency,
            token: CHAIN_CONFIG["bsc"].nativeTokenAddress,
          },
        }}>
        <PortfolioDetailListHeader>
          <PortfolioDetailListTitle>
            <Typography variant="pre-title-2" color="text.secondary">
              TOKEN
            </Typography>
          </PortfolioDetailListTitle>
          <PortfolioDetailListTitle>
            <Box>
              <Typography variant="pre-title-2" color="text.secondary">
                USD VALUE
              </Typography>
            </Box>
          </PortfolioDetailListTitle>
          <PortfolioDetailListTitle>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                marginRight: "20px",
              }}>
              <Cached
                onClick={() => refetch()}
                sx={{
                  cursor: "pointer",
                  width: "20px",
                  height: "20px",
                  ...((isLoading || isRefetching) && {
                    animation: "spin 2s linear infinite",
                    "@keyframes spin": {
                      "0%": {
                        transform: "rotate(360deg)",
                      },
                      "100%": {
                        transform: "rotate(0deg)",
                      },
                    },
                  }),
                }}
              />
            </Box>
          </PortfolioDetailListTitle>
        </PortfolioDetailListHeader>
        <TabLoadingSuspense isLoading={isLoading}>
          {data && data?.pages?.length > 0 && data.pages[0].totalResults > 0 ? (
            <ScrollableBox>
              {data.pages
                ?.flatMap((page: any) => page.results)
                .map((groupdata: any, index: number) => (
                  <AssetsListItem
                    key={index}
                    data={groupdata}
                    refetch={refetchAssets}
                    isProcessing={isProcessing}
                    onClickShowDrawer={onClickShowDrawer}
                  />
                ))}
              {!hasAllFetched && <LoadMoreRow ref={ref} />}

              {/* CrossChainTradeDrawer */}
              {shouldTradeDrawerOpen && (
                <CrossChainTradeDrawerV2
                  mode="SWAP"
                  open={shouldTradeDrawerOpen}
                  groupId={0}
                  chain={selectedChain}
                  onConfirm={() => {
                    setShouldTradeDrawerOpen(false);
                    updateUserBalance();
                  }}
                  onFail={() => {
                    setIsProcessing(false);
                    setShouldTradeDrawerOpen(false);
                  }}
                  onProcessing={() => {
                    setIsProcessing(true);
                  }}
                  onProcessed={() => {
                    setIsProcessing(false);
                    refetch();
                  }}
                  onClose={() => {
                    setShouldTradeDrawerOpen(false);
                    refetch();
                  }}
                />
              )}
            </ScrollableBox>
          ) : (
            <EmptyView portfolioType="Assets" />
          )}
        </TabLoadingSuspense>
      </TradeProvider>
    </>
  );
};

const EarningView = () => {
  const { walletAddresses } = useUserStoreV2();

  const {
    earningsData: data,
    isEarningsLoading: isLoading,
    refetchEarningsAndTotalAssets: refetch,
    isEarningsRefetching: isRefetching,
    hasAllFetchedEarnings: hasAllFetched,
    earningsRef: ref,
  } = usePortfolioContext();

  return (
    <>
      <PortfolioDetailListHeader>
        <PortfolioDetailListTitle>
          <Typography variant="pre-title-2" color="text.secondary">
            TOKEN
          </Typography>
        </PortfolioDetailListTitle>
        <PortfolioDetailListTitle>
          <Box>
            <Typography variant="pre-title-2" color="text.secondary">
              USD VALUE
            </Typography>
          </Box>
        </PortfolioDetailListTitle>
        <PortfolioDetailListTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              marginRight: "20px",
            }}>
            <Cached
              onClick={() => refetch()}
              sx={{
                cursor: "pointer",
                width: "20px",
                height: "20px",
                ...((isLoading || isRefetching) && {
                  animation: "spin 2s linear infinite",
                  "@keyframes spin": {
                    "0%": {
                      transform: "rotate(360deg)",
                    },
                    "100%": {
                      transform: "rotate(0deg)",
                    },
                  },
                }),
              }}
            />
          </Box>
        </PortfolioDetailListTitle>
      </PortfolioDetailListHeader>
      <TabLoadingSuspense isLoading={isLoading}>
        {data && data?.pages?.length > 0 && data.pages[0].totalResults > 0 ? (
          <ScrollableBox>
            {data.pages
              ?.flatMap((page: any) => page.results)
              .map((earningData: any, index: number) =>
                earningData.chain === "solana" ? (
                  <EarningListItem
                    key={index}
                    data={earningData}
                    refetch={refetch}
                    walletAddress={walletAddresses ? walletAddresses["solAddress"] : ""}
                  />
                ) : (
                  <EarningListItem key={index} data={earningData} refetch={refetch} />
                ),
              )}
            {!hasAllFetched && <LoadMoreRow ref={ref} />}
          </ScrollableBox>
        ) : (
          <EmptyView portfolioType="Earning" />
        )}
      </TabLoadingSuspense>
    </>
  );
};

const CabalsView = () => {
  const {
    cabalGroupsData: data,
    isCabalGroupsLoading: isLoading,
    refetchCabalGroupsAndTotalAssets: refetch,
    isCabalGroupsRefetching: isRefetching,
    hasAllFetchedCabalGroups: hasAllFetched,
    cabalGroupsRef: ref,
  } = usePortfolioContext();

  return (
    <>
      <PortfolioDetailListHeader>
        <PortfolioDetailListTitle>
          <Typography variant="pre-title-2" color="text.secondary">
            GROUPS
          </Typography>
        </PortfolioDetailListTitle>
        <PortfolioDetailListTitle>
          <Box>
            <Typography variant="pre-title-2" color="text.secondary">
              USD VALUE
            </Typography>
          </Box>
        </PortfolioDetailListTitle>
        <PortfolioDetailListTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              marginRight: "20px",
            }}>
            <Cached
              onClick={() => refetch()}
              sx={{
                cursor: "pointer",
                width: "20px",
                height: "20px",
                ...((isLoading || isRefetching) && {
                  animation: "spin 2s linear infinite",
                  "@keyframes spin": {
                    "0%": {
                      transform: "rotate(360deg)",
                    },
                    "100%": {
                      transform: "rotate(0deg)",
                    },
                  },
                }),
              }}
            />
          </Box>
        </PortfolioDetailListTitle>
      </PortfolioDetailListHeader>
      <TabLoadingSuspense isLoading={isLoading}>
        {data && data?.pages?.length > 0 && data.pages[0].totalResults > 0 ? (
          <ScrollableBox>
            {data.pages
              ?.flatMap((page: any) => page.results)
              .map((groupdata: any, index: number) => (
                <CabalListItem key={index} data={groupdata} />
              ))}
            {!hasAllFetched && <LoadMoreRow ref={ref} />}
          </ScrollableBox>
        ) : (
          <EmptyView portfolioType="Cabals" />
        )}
      </TabLoadingSuspense>
    </>
  );
};

const AssetsListItem = ({
  data,
  refetch,
  isProcessing,
  onClickShowDrawer,
}: {
  data: UserPortfolioAssets;
  refetch: (symbol: string) => Promise<any>;
  isProcessing: boolean;
  onClickShowDrawer: (chain: Chain) => void;
}) => {
  const navigate = useNavigate();
  const { showToast } = useToastStore();
  const { walletAddresses } = useUserStoreV2();
  const { signAndWriteContract, signAndBroadcastSolanaTxn } = useTxnSign();

  const [isLoading, setIsLoading] = useState(false);

  const symbol = data.symbol.toUpperCase();
  const isNativeToken = NATIVE_CURRENCY_CHECK_LIST.includes(symbol as ChainCurrency);
  const isWrappedToken = WRAPPED_CURRENCY_CHECK_LIST.includes(symbol as WrappedCurrency);
  const isUnsupportedToken = UNSUPPORTED_SWAP_TOKENS.includes(symbol as ChainSymbol);

  const buttonText = isNativeToken ? "Swap" : isWrappedToken ? "Unwrap" : "Trade";

  const navigateToTrade = async () => {
    if (isNativeToken) {
      onClickShowDrawer(data.chain);
      return;
    }

    if (isWrappedToken) {
      try {
        setIsLoading(true);
        showToast({
          variant: "info",
          message: "Processing now. Please wait.",
          duration: 5000,
        });

        let res;
        if (["WETH", "WBNB", "WBERA"].includes(symbol)) {
          const calldata = {
            abi: wEthAbi,
            address: data.tokenAddress,
            functionName: "withdraw",
            args: [Math.floor(Number(data.balance) * CHAIN_DECIMAL.ethereum)],
          };

          const hash = await signAndWriteContract(
            data.chain,
            walletAddresses?.ethAddress,
            calldata,
          );

          if (hash) {
            res = {
              status: 200,
              message: `Transaction executed successfully: <a target="_blank" href='${
                CHAIN_EXPLORER[data.chain]
              }/${hash}'>Link</a>`,
            };
          }
        } else {
          const unWrapRes = await axiosService.unwrapSol({
            senderAddress: walletAddresses?.solAddress!!,
            tokenAddress: data.tokenAddress,
          });

          if (unWrapRes.status < 300) {
            const signedTxn = await signAndBroadcastSolanaTxn(
              walletAddresses?.solAddress!,
              unWrapRes.versionedTransaction,
            );

            res = await axiosService.sendToken({
              receiverAddress: walletAddresses?.solAddress!,
              amount: Number(data.balance),
              chain: data.chain,
              signedTxn,
            });
          }
        }

        if (res && res.status < 300) {
          showToast({
            variant: "success",
            message: res.message,
            duration: 5000,
          });
        } else {
          showToast({
            variant: "error",
            message: res?.message,
            duration: 5000,
          });
        }
      } catch (error) {
        console.error("Failed to unwrap token", error);
      } finally {
        await refetch(data.symbol);
      }

      return;
    }

    navigate({
      pathname: `/token/${data.tokenAddress}`,
      search: `chain=${data.chain}${data.tgGroupId ? `&groupId=${data.tgGroupId}` : ""}`,
    });
  };

  return (
    <PortfolioDetailListItemContainer>
      <Box
        sx={{
          display: "flex",
          flex: "1 1 0%",
          justifyContent: "start",
          alignItems: "center",
          gap: "2px",
          overflow: "hidden",
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: "8px",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}>
          <CoinAvatar
            style={{
              width: "28px",
              height: "28px",
            }}
            badgeStyle={{
              width: "14px",
              height: "14px",
            }}
            iconUri={data.imageUrl ? data.imageUrl : NATIVE_TOKEN_MAP[symbol]}
            chain={data.chain}
            size="sm"
          />
          <Typography
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              width: "100%",
            }}
            variant="subtitle">
            {data.symbol}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          flex: "1 1 0%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          gap: "12px",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            flex: "1 1 0%",
          }}>
          <Value
            variant="body-default"
            value={+data.priceUsd}
            isPrice
            useFixedDecimals
            color="text.primary"
          />
          {isNativeToken ? null : (
            <PricePerformanceBrick
              performance={data.performance ? +data.performance.toFixed(4) : 0}
            />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          flex: "1 1 0%",
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          textAlign: "center",
          gap: "12px",
        }}>
        <Box
          sx={{
            width: "78px",
            height: "46px",
          }}>
          <RetroButton
            variant={"primary"}
            onClick={navigateToTrade}
            // TODO: Revert for berachain
            disabled={isUnsupportedToken || isLoading || isProcessing}>
            <Box
              sx={{
                padding: "12px",
              }}>
              <Typography
                variant="button-md"
                // TODO: Revert for berachain
                color={
                  isUnsupportedToken || isLoading || isProcessing ? "text.secondary" : "text.brand"
                }>
                {buttonText}
              </Typography>
            </Box>
          </RetroButton>
        </Box>
      </Box>
    </PortfolioDetailListItemContainer>
  );
};

const EarningListItem = ({
  data,
  refetch,
  walletAddress,
}: {
  data: UserPortfolioEarning;
  refetch: () => Promise<any>;
  walletAddress?: string;
}) => {
  const CLAIM_AVAILABLE_USD_REQUIREMENT = 10;

  const { signAndBroadcastSolanaTxn } = useTxnSign();
  const { showToast } = useToastStore();
  const [isClaiming, setIsClaiming] = useState(false);
  const claimingRef = useRef(false);

  const handleClaim = async () => {
    if (claimingRef.current || isClaiming) return;

    try {
      claimingRef.current = true;
      setIsClaiming(true);

      const tokenAccResp = await axiosService.createSolTokenAccount({
        toAddress: walletAddress!,
        feeToken: data.feeToken,
      });

      if (tokenAccResp.versionedTransaction) {
        const signedTxn = await signAndBroadcastSolanaTxn(
          walletAddress!,
          tokenAccResp.versionedTransaction,
        );

        const res = await axiosService.sendToken({
          receiverAddress: walletAddress!,
          amount: data.totalFeeAmounts,
          chain: data.chain,
          signedTxn,
        });

        if (res.status > 300) {
          return showToast({
            variant: "error",
            message: res.message,
            duration: 5000,
          });
        }
      }

      const payload = {
        chain: data.chain,
        feeToken: data.feeToken,
        totalFeeAmounts: data.totalFeeAmounts,
      };

      const res = await axiosService.claimReward(payload);
      if (res.status < 300) {
        showToast({
          variant: "success",
          message: res.message,
          duration: 5000,
        });
      } else {
        showToast({
          variant: "error",
          message: res.message ?? "Failed to claim reward",
          duration: 5000,
        });
      }
    } catch (error) {
      showToast({ variant: "error", message: "Failed to claim reward", duration: 5000 });
    } finally {
      await refetch();
      claimingRef.current = false;
      setIsClaiming(false);
    }
  };

  return (
    <PortfolioDetailListItemContainer>
      <PortfolioDetailListItemColumnContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: "8px",
          }}>
          <CoinAvatar
            style={{
              width: "28px",
              height: "28px",
            }}
            badgeStyle={{
              width: "14px",
              height: "14px",
            }}
            iconUri={NATIVE_TOKEN_MAP[data.symbol.toUpperCase()]}
            chain={data.chain}
            size="sm"
          />
          <Typography
            sx={{
              maxWidth: "86px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
            variant="subtitle">
            {data.symbol}
          </Typography>
        </Box>
      </PortfolioDetailListItemColumnContainer>
      <Box
        sx={{
          flex: "1 1 0%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center",
          gap: "12px",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            flex: "1 1 0%",
          }}>
          <Typography variant="body-default" color="text.primary">
            <Value variant="body-default" value={+data.priceUsd} isPrice useFixedDecimals />
          </Typography>
        </Box>
        <Box
          sx={{
            width: "78px",
            height: "46px",
          }}>
          <RetroButton
            disabled={isClaiming || +data.priceUsd < CLAIM_AVAILABLE_USD_REQUIREMENT}
            variant={
              isClaiming || +data.priceUsd < CLAIM_AVAILABLE_USD_REQUIREMENT
                ? "disabled"
                : "primary"
            }
            onClick={handleClaim}>
            <Box
              sx={{
                padding: "12px",
              }}>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "4px",
                }}
                variant="button-md"
                color={
                  isClaiming || +data.priceUsd < CLAIM_AVAILABLE_USD_REQUIREMENT
                    ? "text.secondary"
                    : "text.brand"
                }>
                {isClaiming ? (
                  "CLAIMING"
                ) : (
                  <>
                    <Lock sx={{ fontSize: "14px" }} /> $10
                  </>
                )}
              </Typography>
            </Box>
          </RetroButton>
        </Box>
      </Box>
    </PortfolioDetailListItemContainer>
  );
};

const CabalListItem = ({ data }: { data: UserPortfolioJoinedGroup }) => {
  const navigate = useNavigatePreserveQuery();
  const navigateToGroup = () => {
    navigate(`/cabal/${data.tgGroupId}`);
  };

  return (
    <PortfolioDetailListItemContainer>
      <PortfolioDetailListItemColumnContainer>
        <CoinAvatar
          style={{
            width: "24px",
            height: "24px",
          }}
          iconUri={data.imageUri}
          size="sm"
        />
        <Typography
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            marginLeft: "8px",
          }}
          variant="subtitle">
          {data.symbol}
        </Typography>
      </PortfolioDetailListItemColumnContainer>
      <Box
        sx={{
          flex: "1 1 0%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center",
          gap: "12px",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            flex: "1 1 0%",
          }}>
          <Typography variant="body-default" color="text.primary">
            {data && data.priceUsd ? (
              <Value variant="body-default" value={data.priceUsd} isPrice useFixedDecimals />
            ) : (
              "$--"
            )}
          </Typography>
          <PricePerformanceBrick
            performance={data.performance ? +data.performance.toFixed(2) : 0}
          />
        </Box>
        <Box
          sx={{
            width: "78px",
            height: "46px",
          }}>
          <RetroButton variant="white" onClick={navigateToGroup}>
            <Box
              sx={{
                padding: "12px",
              }}>
              <Typography variant="button-md" color="text.btn-dark">
                View
              </Typography>
            </Box>
          </RetroButton>
        </Box>
      </Box>
      {/* <LoadingView isLoading={isLoading}/> */}
    </PortfolioDetailListItemContainer>
  );
};

const TabLoadingSuspense = ({ isLoading, children }: { isLoading: boolean; children: any }) => {
  return isLoading ? (
    <LoadingBlock isLoading={isLoading}>
      <Typography variant="title-h2">Loading...</Typography>
    </LoadingBlock>
  ) : (
    children
  );
};

const EmptyView = ({ portfolioType }: { portfolioType: "Assets" | "Earning" | "Cabals" }) => {
  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "45px",
        backgroundColor: theme.palette.surface.silver,
        borderRadius: "16px",
        border: `1px solid ${theme.palette.border.dark}`,
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100px",
        }}>
        <LogoIcon />
      </Box>
      <Box
        sx={{
          textAlign: "center",
          color: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}>
        <Typography variant="title-h2">{portfolioType} Empty</Typography>
        <Typography variant="body-lg">
          You do not have any {portfolioType.toLowerCase()} yet
        </Typography>
      </Box>
    </Box>
  );
};
