import bs58 from "bs58";
import { atom, useRecoilState } from "recoil";
import { generateP256KeyPair, decryptExportBundle } from "@turnkey/crypto";
import { TurnkeySigner } from "@turnkey/solana";
import { VersionedTransaction } from "@solana/web3.js";
import { createPublicClient, createWalletClient, erc20Abi, http } from "viem";
import { mainnet, base, berachain, bsc } from "viem/chains";
import { createAccount } from "@turnkey/viem";
import {
  Address,
  beginCell,
  external,
  internal,
  SendMode,
  storeMessage,
  WalletContractV4,
} from "@ton/ton";
import { BUY_NETWORK_FEE, RPC_ENDPOINT, SELL_NETWORK_FEE, TON_DECIMAL_SCALER } from "consts";
import { getSolanaTransactions } from "lib/solana";
import { createWalletTransferV4WithTurnkey } from "lib/ton";
import { ToncoreAdapter } from "@tonx/adapter";
import { OPS, sleep, TradeType } from "lib/utils";
import { useEmailAuth } from "./useEmailAuth";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import axiosService from "services/axios";

export const CHAIN_RPC_ENDPOINT = {
  ethereum: mainnet,
  berachain,
  base,
  bsc,
};

type ChainType = keyof typeof CHAIN_RPC_ENDPOINT;

export interface RawQuoteData {
  to?: string;
  value?: string;
  amount?: string;
  calldatas?: any;
  tokenAddress?: string;
}

const txnSigningState = atom({
  key: "txnSign",
  default: {
    isLoading: false,
    hash: undefined as string | undefined,
  },
});

export function useTxnSign() {
  const { getAuthClient } = useEmailAuth();
  const [signingState, setSigningState] = useRecoilState(txnSigningState);
  const { organizationId, walletId } = useUserStoreV2();

  const tonClient = new ToncoreAdapter({
    network: "mainnet",
    apiKey: process.env.REACT_APP_TON_API_KEY!,
  });

  const signAndBroadcastTonTxn = async (
    receiverAddress: string,
    tonAmount: number,
    memo: string,
    isMax: boolean,
  ) => {
    if (!organizationId) {
      throw new Error("organizationId not found");
    }

    const currentClient = await getAuthClient();

    if (!currentClient) {
      throw new Error("No active client found");
    }

    try {
      const walletsResponse = await currentClient.getPrivateKeys({
        organizationId,
      });

      const privateKeys: any = walletsResponse.privateKeys.find(
        (wallet: any) => wallet.curve === "CURVE_ED25519",
      );

      const data: any = await axiosService.getTonTxnMessage({ publicKey: privateKeys.publicKey });

      const walletAddress = privateKeys.addresses.filter(
        (add: any) => add.format === "ADDRESS_FORMAT_TON_V4R2",
      )[0].address;
      const publicKey = Buffer.from(privateKeys.publicKey, "hex");

      const wallet = WalletContractV4.create({
        publicKey,
        workchain: 0,
      });

      const receiver = Address.parse(receiverAddress);
      // Check if the contract needs initialization (init code/data)

      const internalMessage = internal({
        to: receiver,
        value: tonAmount.toString(),
        body: memo,
        bounce: false,
      });

      let mode = isMax ? SendMode.CARRY_ALL_REMAINING_BALANCE : SendMode.PAY_GAS_SEPARATELY;

      const { signingMessage, signingMessageBuilder } = await createWalletTransferV4WithTurnkey(
        [internalMessage],
        wallet.walletId,
        data.seqno,
        mode,
      );

      const txSignResult = await currentClient.signRawPayload({
        signWith: walletAddress,
        payload: signingMessage.toString("hex"),
        encoding: "PAYLOAD_ENCODING_HEXADECIMAL",
        hashFunction: "HASH_FUNCTION_NOT_APPLICABLE",
      });

      const { r, s } = txSignResult;
      const signatureBytes = Buffer.from(r + s, "hex");

      const body = beginCell()
        .storeBuffer(signatureBytes)
        .storeBuilder(signingMessageBuilder)
        .endCell();
      const userAddress = Address.parse(walletAddress);

      // Create the external message
      const ext = external({
        to: userAddress,
        init: null,
        body,
      });

      // Build the final message to send
      const boc = beginCell().store(storeMessage(ext)).endCell().toBoc();

      const bocBase64 = boc.toString("base64");

      return bocBase64;
    } catch (error: any) {
      console.error(`signAndBroadcastTonTxn error: `, error);
    }
  };

  const signAndBroadcastJettonTxn = async (
    masterAddress: string,
    amount: number,
    type: TradeType,
  ) => {
    const currentClient = await getAuthClient();

    if (!currentClient) {
      throw new Error("No active client found");
    }

    try {
      const walletsResponse = await currentClient.getPrivateKeys({
        organizationId,
      });

      const privateKeys: any = walletsResponse.privateKeys.find(
        (wallet: any) => wallet.curve === "CURVE_ED25519",
      );

      const payload = {
        publicKey: privateKeys.publicKey,
      } as {
        publicKey: any;
        masterAddress?: string;
        value?: number;
      };

      if (type === TradeType.SELL) {
        payload.masterAddress = masterAddress;
        payload.value = amount;
      }

      const data: any = await axiosService.getTonTxnMessage({
        publicKey: privateKeys.publicKey,
        masterAddress,
        value: amount,
      });

      const walletAddress = privateKeys.addresses.filter(
        (add: any) => add.format === "ADDRESS_FORMAT_TON_V4R2",
      )[0].address;
      const publicKey = Buffer.from(privateKeys.publicKey, "hex");

      const wallet = WalletContractV4.create({
        publicKey,
        workchain: 0,
      });

      let fee = 0;
      let dataCell;
      let msgValue = 0;

      switch (type) {
        case TradeType.BUY:
          const tonAmount = amount * TON_DECIMAL_SCALER;
          const value = Math.floor(tonAmount);
          fee = (tonAmount * 1) / 100;
          msgValue = BUY_NETWORK_FEE + value;

          dataCell = beginCell()
            .storeUint(OPS.BuyJetton, 32)
            .storeUint(1, 64)
            .storeCoins(value)
            .storeRef(beginCell().endCell())
            .endCell();

          break;
        case TradeType.SELL:
          const jettonAmount = Math.floor(amount * TON_DECIMAL_SCALER);
          fee = (data.sellReturn * 1) / 100;
          msgValue = SELL_NETWORK_FEE;

          dataCell = beginCell()
            .storeUint(OPS.SellJetton, 32)
            .storeUint(1, 64)
            .storeCoins(jettonAmount)
            .storeRef(beginCell().endCell())
            .endCell();
          break;
        default:
          break;
      }

      const message = internal({
        to: Address.parse(masterAddress),
        value: BigInt(msgValue),
        bounce: false,
        body: dataCell,
      });

      // share 1% fee to vault
      const feeShareMessage = internal({
        value: BigInt(Math.floor(fee)),
        to: Address.parse(process.env.REACT_APP_TON_VAULT_ADDRESS!),
        bounce: false,
      });

      const { signingMessage, signingMessageBuilder } = await createWalletTransferV4WithTurnkey(
        [message, feeShareMessage],
        wallet.walletId,
        data.seqno,
      );

      const txSignResult = await currentClient.signRawPayload({
        signWith: walletAddress,
        payload: signingMessage.toString("hex"),
        encoding: "PAYLOAD_ENCODING_HEXADECIMAL",
        hashFunction: "HASH_FUNCTION_NOT_APPLICABLE",
      });

      const { r, s } = txSignResult;
      const signatureBytes = Buffer.from(r + s, "hex");

      // Create the body cell directly from the signature and message builder data
      const body = beginCell()
        .storeBuffer(signatureBytes)
        .storeBuilder(signingMessageBuilder) // Assume base64 encoded data
        .endCell();

      const tonAddress = Address.parse(walletAddress);
      const walletContract = tonClient.open(wallet);

      // Create the external message
      const ext = external({
        to: tonAddress,
        init: { code: walletContract.init.code, data: walletContract.init.data },
        body,
      });

      // Build the final message to send
      const boc = beginCell().store(storeMessage(ext)).endCell().toBoc();
      // Convert boc buffer to base64 string for transmission
      const bocBase64 = boc.toString("base64");

      return {
        fee,
        boc: bocBase64,
      };
    } catch (error: any) {
      console.error(`getWallet error: `, error);
    }
  };

  const signAndBroadcastSolanaTxn = async (solAddress: string, calldatas: any) => {
    if (!organizationId) {
      throw new Error("organizationId not found");
    }

    const currentClient = await getAuthClient();

    if (!currentClient) {
      throw new Error("No active client found");
    }

    const turnkeySigner = new TurnkeySigner({
      organizationId,
      client: currentClient,
    });

    const transactions = await getSolanaTransactions(calldatas);

    // add user signature
    for (let i = 0; i < transactions.length; i++) {
      transactions[i] = (await turnkeySigner.signTransaction(
        transactions[i],
        solAddress,
      )) as VersionedTransaction;
    }

    return transactions.map((tx) => bs58.encode(tx.serialize()));
  };

  const signAndBroadcastTxn = async (addresss: string, chain: any, quoteData: RawQuoteData) => {
    if (!organizationId) {
      throw new Error("organizationId not found");
    }

    const currentClient = await getAuthClient();

    if (!currentClient) {
      throw new Error("No active client found");
    }

    const viemAccount = await createAccount({
      client: currentClient,
      organizationId,
      signWith: addresss,
      ethereumAddress: addresss,
    });

    const viemClient = createWalletClient({
      account: viemAccount,
      transport: http(RPC_ENDPOINT[chain]),
      chain: CHAIN_RPC_ENDPOINT[chain as ChainType],
    }) as any;

    // estimate gas
    const { gas, maxFeePerGas } = await estimateEvmGas(chain, quoteData);

    const payload = {
      ...quoteData.calldatas,
      value: BigInt(Math.floor(Number(quoteData.value))),
      maxFeePerGas,
      gas,
    };

    // const result = await getEvmSimulatedResult(chain, {
    //   calls: [{ ...quoteData.calldatas, value: BigInt(Math.floor(Number(quoteData.value))) }],
    //   account: viemClient.account,
    // });

    const hash = await viemClient.sendTransaction(payload);

    setSigningState({
      isLoading: false,
      hash,
    });

    return hash;
  };

  const signAndWriteContract = async (chain: any, walletAddress: any, callData: any) => {
    const currentClient = await getAuthClient();

    if (!currentClient) {
      throw new Error("No active client found");
    }

    if (!organizationId) {
      throw new Error("organizationId not found");
    }

    const viemPublicClient = createPublicClient({
      chain: CHAIN_RPC_ENDPOINT[chain as ChainType],
      transport: http(RPC_ENDPOINT[chain]),
    }) as any;

    const viemAccount = await createAccount({
      client: currentClient,
      organizationId,
      signWith: walletAddress,
      ethereumAddress: walletAddress,
    });

    const viemClient = createWalletClient({
      account: viemAccount,
      transport: http(RPC_ENDPOINT[chain]),
      chain: CHAIN_RPC_ENDPOINT[chain as ChainType],
    }) as any;

    const { request } = await viemPublicClient.simulateContract({
      ...callData,
      account: viemClient.account,
    });

    const hash = await viemClient.writeContract(request);
    return hash;
  };

  const approveAllowance = async (
    chain: any,
    address: string,
    walletAddress: any,
    spenderAddress: string,
    amountToApprove: number,
  ) => {
    const currentClient = await getAuthClient();

    if (!currentClient) {
      throw new Error("No active client found");
    }

    if (!organizationId) {
      throw new Error("organizationId not found");
    }

    const viemAccount = await createAccount({
      client: currentClient,
      organizationId,
      signWith: walletAddress,
      ethereumAddress: walletAddress,
    });

    const viemClient = createWalletClient({
      account: viemAccount,
      transport: http(RPC_ENDPOINT[chain]),
      chain: CHAIN_RPC_ENDPOINT[chain as ChainType],
    }) as any;

    const viemPublicClient = createPublicClient({
      chain: CHAIN_RPC_ENDPOINT[chain as ChainType],
      transport: http(RPC_ENDPOINT[chain]),
    }) as any;

    const { request } = await viemPublicClient.simulateContract({
      address,
      abi: erc20Abi,
      functionName: "approve",
      account: viemClient.account,
      args: [spenderAddress, BigInt(amountToApprove)],
    });

    const hash = await viemClient.writeContract(request);

    let retry = 0;
    while (retry < 30) {
      const confirmation = await getEvmTxnConfirmation(hash, chain);
      if (confirmation) {
        break;
      }
      retry++;
      await sleep(1000);
    }

    return hash;
  };

  const exportWallet = async () => {
    if (!walletId) {
      throw new Error("No wallets found");
    }

    if (!organizationId) {
      throw new Error("organizationId not found");
    }

    const keyPair = generateP256KeyPair();
    const privateKey = keyPair.privateKey;
    const publicKey = keyPair.publicKeyUncompressed;

    const currentClient = await getAuthClient();

    if (!currentClient) {
      throw new Error("No active client found");
    }

    const exportResult = await currentClient.exportWallet({
      walletId,
      targetPublicKey: publicKey,
    });

    const decryptedBundle = await decryptExportBundle({
      organizationId,
      exportBundle: exportResult.exportBundle,
      embeddedKey: privateKey,
      returnMnemonic: true,
    });

    const walletsResponse = await currentClient.getPrivateKeys({
      organizationId,
    });

    const privateKeys: any = walletsResponse.privateKeys.find(
      (wallet: any) => wallet.curve === "CURVE_ED25519",
    );

    const exportPrivateKeyResult = await currentClient.exportPrivateKey({
      privateKeyId: privateKeys.privateKeyId,
      targetPublicKey: publicKey,
    });

    const decryptedPrivateKeyBundle = await decryptExportBundle({
      organizationId,
      exportBundle: exportPrivateKeyResult.exportBundle,
      embeddedKey: privateKey,
      returnMnemonic: false,
    });

    return {
      decryptedBundle,
      decryptedPrivateKeyBundle,
    };
  };

  const estimateEvmGas = async (chain: any, quoteData: RawQuoteData) => {
    const viemPublicClient = createPublicClient({
      chain: CHAIN_RPC_ENDPOINT[chain as ChainType],
      transport: http(RPC_ENDPOINT[chain]),
    }) as any;
    let gasLimit = 500000;
    try {
      gasLimit = await viemPublicClient.estimateGas({
        ...quoteData.calldatas,
        value: BigInt(Math.floor(Number(quoteData.value))),
      });
    } catch (error) {
      console.error("estimateEvmGas error", error);
    }
    const { maxFeePerGas } = await viemPublicClient.estimateFeesPerGas();
    return {
      gas: BigInt(Number(gasLimit) * 5),
      maxFeePerGas: BigInt(Number(maxFeePerGas) * 5),
    };
  };

  const getEvmTxnConfirmation = async (hash: string, chain: any) => {
    try {
      const viemPublicClient = createPublicClient({
        chain: CHAIN_RPC_ENDPOINT[chain as ChainType],
        transport: http(RPC_ENDPOINT[chain]),
      }) as any;

      const transactionReceipt = await viemPublicClient.getTransactionReceipt({ hash });
      if (transactionReceipt.status === "reverted" || transactionReceipt.status === "failed") {
        return false;
      }

      const confirmations = await viemPublicClient.getTransactionConfirmations({
        transactionReceipt,
      });

      return {
        status: Number(confirmations) > 0,
      };
    } catch (error) {
      console.error("getEvmTxnConfirmation error", error);
      return false;
    }
  };

  const getEvmSimulatedResult = async (chain: any, data: any) => {
    try {
      const viemPublicClient = createPublicClient({
        chain: CHAIN_RPC_ENDPOINT[chain as ChainType],
        transport: http(RPC_ENDPOINT[chain]),
      }) as any;

      const result = await viemPublicClient.simulateCalls(data);

      return result;
    } catch (error) {
      console.error("getEvmSimulatedResult error", error);
    }
  };

  return {
    exportWallet,
    signingState,
    approveAllowance,
    signAndBroadcastTxn,
    signAndBroadcastSolanaTxn,
    signAndBroadcastJettonTxn,
    signAndBroadcastTonTxn,
    signAndWriteContract,
    estimateEvmGas,
    getEvmTxnConfirmation,
    getEvmSimulatedResult,
  };
}
