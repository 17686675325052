import { Chain, ChainConfig, ChainSymbol } from "chainConfigs";
import { MIN_ENTRY_USD, MIN_ENTRY_USD_BUFFER } from "consts";

export type Mode = "BUY" | "SELL" | "SWAP" | "UNWRAP";
export const MODES: Record<Mode, Mode> = {
  BUY: "BUY",
  SELL: "SELL",
  SWAP: "SWAP",
  UNWRAP: "UNWRAP",
};

export interface TradingModeConfig {
  modeSwitch: Mode[] | null;
  supportedNativeChains: Partial<Record<Chain, Chain[]>>;
  minimumEntryUSD?: number;
  minimumGasBufferUSD?: Partial<Record<Chain, number>>;
  minimumCrossChainGasBufferUSD?: Partial<Record<Chain, number>>;
  from: {
    isWrapped?: boolean;
    isNative: boolean;
    isBlockedSameChain?: boolean;
  };
  to: {
    isWrapped?: boolean;
    isNative: boolean;
    isBlockedSameChain?: boolean;
  };
}

export const minimumGasBufferUSD: Partial<Record<Chain, number>> = {
  ton: 1,
  berachain: 1,
  ethereum: 10,
  solana: 1,
  base: 1,
  bsc: 1,
};

export const minimumCrossChainGasBufferUSD: Partial<Record<Chain, number>> = {
  ton: 1,
  berachain: 5,
  ethereum: 10,
  solana: 5,
  base: 5,
  bsc: 5,
};

export const UNSUPPORTED_SWAP_TOKENS: Array<ChainSymbol> = ["TON"];
export const TRADING_MODE_CONFIG_MAP: Record<Mode, TradingModeConfig> = {
  BUY: {
    modeSwitch: ["SELL", "BUY"],
    supportedNativeChains: {
      ton: ["ton", "ethereum", "solana", "base", "berachain", "bsc"],
      berachain: ["ton", "ethereum", "solana", "base", "berachain", "bsc"],
      ethereum: ["ton", "ethereum", "solana", "base", "berachain", "bsc"],
      solana: ["ton", "ethereum", "solana", "base", "berachain", "bsc"],
      base: ["ton", "ethereum", "solana", "base", "berachain", "bsc"],
      bsc: ["ton", "ethereum", "solana", "base", "berachain", "bsc"],
    },
    minimumEntryUSD: MIN_ENTRY_USD - MIN_ENTRY_USD_BUFFER,
    minimumGasBufferUSD,
    minimumCrossChainGasBufferUSD,
    from: {
      isNative: true,
    },
    to: {
      isNative: false,
    },
  },
  SELL: {
    modeSwitch: ["SELL", "BUY"],
    supportedNativeChains: {
      ton: ["ton"],
      berachain: ["ethereum", "solana", "base", "berachain", "bsc"],
      ethereum: ["ethereum", "solana", "base", "berachain", "bsc"],
      solana: ["ethereum", "solana", "base", "berachain", "bsc"],
      base: ["ethereum", "solana", "base", "berachain", "bsc"],
      bsc: ["ethereum", "solana", "base", "berachain", "bsc"],
    },
    minimumGasBufferUSD,
    minimumCrossChainGasBufferUSD,
    from: {
      isNative: false,
    },
    to: {
      isNative: true,
    },
  },
  SWAP: {
    modeSwitch: null,
    supportedNativeChains: {
      berachain: ["ethereum", "solana", "base", "berachain", "bsc"],
      ethereum: ["solana", "ethereum", "base", "berachain", "bsc"],
      solana: ["ethereum", "solana", "base", "berachain", "bsc"],
      base: ["ethereum", "solana", "base", "berachain", "bsc"],
      bsc: ["ethereum", "solana", "base", "berachain", "bsc"],
    },
    minimumEntryUSD: MIN_ENTRY_USD - MIN_ENTRY_USD_BUFFER,
    minimumGasBufferUSD,
    minimumCrossChainGasBufferUSD,
    from: {
      isNative: true,
    },
    to: {
      isBlockedSameChain: true,
      isNative: true,
    },
  },
  UNWRAP: {
    modeSwitch: null,
    supportedNativeChains: {
      ethereum: ["ethereum", "solana", "base"],
      solana: ["ethereum", "solana", "base"],
      base: ["ethereum", "solana", "base"],
    },
    from: {
      isWrapped: true,
      isNative: true,
    },
    to: {
      isNative: false,
    },
  },
};
