import { RetroCard } from "components/RetroCard";
import {
  ExportKeyDetailWrapper,
  ExportKeyTextWrapper,
  ModalWrapper,
  Overlay,
  PasswordButtonGroups,
  PasswordInput,
  StyledExportKeyTextDrawer,
  ViewPasswordButton,
  WalletAddressContainer,
} from "./styled";
import { Box, IconButton, MenuItem, Modal, Typography } from "@mui/material";
import { CircleButton } from "components/CircleButton";
import { ReactComponent as PrivateIcon } from "assets/icons/private.svg";
import { ReactComponent as ShowIcon } from "assets/icons/eye.svg";
import { ReactComponent as HideIcon } from "assets/icons/eye-slash.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy-brand.svg";
import { ReactComponent as GroupIcon } from "assets/icons/deposit-money.svg";
import SolanaIcon from "../../assets/icons/chains/solana.png";
import EthereumIcon from "../../assets/icons/chains/ethereum.png";
import TonIcon from "../../assets/icons/chains/ton.png";
import BaseIcon from "../../assets/icons/chains/base.png";
import BeraIcon from "../../assets/icons/chains/bera.png";
import BinanceIcon from "../../assets/icons/chains/bsc.png";
import { useEffect, useMemo, useRef, useState } from "react";
import { RetroButton } from "components/RetroButton";
import { copyToClipboard } from "utils";
import { Chain, EVM_CHAINS } from "chainConfigs";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { WalletAddresses } from "store/user-store-v2";
import { Close } from "@mui/icons-material";
import { Select } from "components/Select";
import useToastStore from "store/toast-store/useToastStore";

interface ExportPrivateKeyModalProps {
  keys: Record<string, string | null>;
  isOpen: boolean;
  close: () => void;
}

// export const ExportPrivateKeyModal = ({ keys, isOpen, close }: ExportPrivateKeyModalProps) => {
//   const [copiedWalletIndex, setCopiedWalletIndex] = useState<number | null>(null);

//   return isOpen ? (
//     <Overlay>
//       <Modal
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//         }}
//         open={isOpen}
//         onClose={close}>
//         <ModalWrapper>
//           <RetroCard>
//             <Box
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 height: "100%",
//                 padding: "20px",
//                 gap: "16px",
//               }}>
//               <Box
//                 sx={{
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "end",
//                 }}>
//                 <CircleButton variant="white" onClick={close}>
//                   <Box
//                     sx={{
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "center",
//                     }}>
//                     X
//                   </Box>
//                 </CircleButton>
//               </Box>
//               <Box
//                 sx={{
//                   display: "flex",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   gap: "16px",
//                 }}>
//                 <PrivateIcon width={84} height={87} />
//               </Box>
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   marginX: "auto",
//                   textAlign: "center",
//                   width: "300px",
//                   gap: "16px",
//                   marginBottom: "20px",
//                 }}>
//                 <Typography variant="title-h2">YOUR PRIVATE KEY / RECOVERY PHRASE</Typography>
//                 <Typography variant="body-md" color="text.system-red">
//                   Never share your private key or recovery phrase to anyone.
//                 </Typography>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     gap: "16px",
//                     textAlign: "start",
//                   }}>
//                   <Box display="flex" minWidth="47px">
//                     <Typography variant="title-h3" color="text.secondary">
//                       TON
//                     </Typography>
//                   </Box>
//                   <PasswordInputs
//                     password={keys.ton!}
//                     isCopied={copiedWalletIndex === 0}
//                     setIsCopied={() => setCopiedWalletIndex(0)}
//                   />
//                 </Box>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     gap: "16px",
//                     textAlign: "start",
//                   }}>
//                   <Box display="flex" minWidth="47px">
//                     <Typography variant="title-h3" color="text.secondary">
//                       SOL
//                     </Typography>
//                   </Box>
//                   <PasswordInputs
//                     password={keys.solana!}
//                     isCopied={copiedWalletIndex === 1}
//                     setIsCopied={() => setCopiedWalletIndex(1)}
//                   />
//                 </Box>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     alignItems: "center",
//                     gap: "16px",
//                     textAlign: "start",
//                     flexWrap: "wrap",
//                   }}>
//                   <Box display="flex" minWidth="47px">
//                     <Typography variant="title-h3" color="text.secondary">
//                       BASE/ETH/BERA
//                     </Typography>
//                   </Box>
//                   <PasswordInputs
//                     password={keys.ethereum!}
//                     isCopied={copiedWalletIndex === 2}
//                     setIsCopied={() => setCopiedWalletIndex(2)}
//                   />
//                 </Box>
//               </Box>
//               <Box
//                 sx={{
//                   display: "flex",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   width: "100%",
//                   maxWidth: "300px",
//                   marginX: "auto",
//                 }}>
//                 <RetroButton variant="white" onClick={close}>
//                   <Typography variant="title-h3" sx={{ padding: "12px 24px" }}>
//                     DONE
//                   </Typography>
//                 </RetroButton>
//               </Box>
//             </Box>
//           </RetroCard>
//         </ModalWrapper>
//       </Modal>
//     </Overlay>
//   ) : (
//     <></>
//   );
// };

interface CrossChainConfig {
  id: string;
  symbol: string;
  networkName: React.ReactNode;
  walletName: keyof WalletAddresses;
}

const crossChainConfigs: Record<string, CrossChainConfig> = {
  ton: {
    id: "ton",
    symbol: "TON",
    networkName: (
      <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <img src={TonIcon} alt="TON" width="25px" height="24px" />
        <Typography variant="body-md">The Open Network (TON)</Typography>
      </Box>
    ),
    walletName: "tonAddress",
  },
  solana: {
    id: "solana",
    symbol: "SOL",
    networkName: (
      <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <img src={SolanaIcon} alt="SOL" width="25px" height="24px" />
        <Typography variant="body-md">Solana</Typography>
      </Box>
    ),
    walletName: "solAddress",
  },
  ethereum: {
    id: "ethereum",
    symbol: "ETH",
    networkName: (
      <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <img src={EthereumIcon} alt="ETH" width="25px" height="24px" />
        <Typography variant="body-md">Ethereum</Typography>
      </Box>
    ),
    walletName: "ethAddress",
  },
  base: {
    id: "base",
    symbol: "ETH(BASE)",
    networkName: (
      <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <img src={BaseIcon} alt="BASE" width="25px" height="24px" />
        <Typography variant="body-md">Base</Typography>
      </Box>
    ),
    walletName: "ethAddress",
  },
  berachain: {
    id: "berachain",
    symbol: "BERA",
    networkName: (
      <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <img src={BeraIcon} alt="BERA" width="25px" height="24px" />
        <Typography variant="body-md">Berachain</Typography>
      </Box>
    ),
    walletName: "ethAddress",
  },
  bsc: {
    id: "bsc",
    symbol: "BNB",
    networkName: (
      <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <img src={BinanceIcon} alt="BNB" width="25px" height="24px" />
        <Typography variant="body-md">Binance Smart Chain (BSC)</Typography>
      </Box>
    ),
    walletName: "ethAddress",
  },
};

export const ExportPrivateKeyModalV2 = ({
  defaultChain = "ethereum",
  chains,
  isOpen,
  close,
  onCopySuccess,
  keys,
}: {
  defaultChain?: Chain;
  chains: Array<Chain>;
  isOpen: boolean;
  close: () => void;
  onCopySuccess?: () => void;
  keys: Record<string, string | null>;
}) => {
  const [selectedChain, setSelectedChain] = useState<Chain>(defaultChain);

  const sortedConfigs = useMemo(() => {
    const config = chains
      // Map to cross chain config
      .map((chains) => {
        return crossChainConfigs[chains];
      })
      // Sort by default token type
      .sort((a, b) => {
        const isAMatch = a.id === defaultChain ? 1 : -1;
        const isBMatch = b.id === defaultChain ? 1 : -1;

        return isAMatch - isBMatch;
      });
    return config;
  }, [chains, defaultChain]);

  const handleCopy = () => {
    const key = EVM_CHAINS.includes(selectedChain) ? keys.ethereum! : keys[selectedChain]!;
    if (key !== undefined) {
      const success = copyToClipboard(key);
      if (success) {
        onCopySuccess?.();
      }
    }
  };

  return (
    <StyledExportKeyTextDrawer anchor={"bottom"} open={isOpen} onClose={close}>
      <Box
        sx={{
          marginLeft: "auto",
          fontSize: "16px",
        }}>
        <CircleButton variant="white" onClick={close}>
          <Close fontSize="inherit" />
        </CircleButton>
      </Box>
      <ExportKeyDetailWrapper>
        <Box>
          <PrivateIcon height="140px" width="140px" />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            maxWidth: "495px",
            marginBottom: "16px",
            textAlign: "center",
          }}>
          <Typography variant="title-h1">YOUR PRIVATE KEY / RECOVERY PHRASE</Typography>
        </Box>
        <ExportKeyTextWrapper>
          <Typography variant="body-default" color="text.system-red">
            Never share your private key or recovery phrase to anyone.
          </Typography>
        </ExportKeyTextWrapper>
        <Select
          value={selectedChain}
          onChange={(event) => setSelectedChain(event.target.value as Chain)}
          sx={{ width: "100%", maxWidth: "495px", marginBottom: "16px" }}>
          {sortedConfigs.map((option, index) => {
            return (
              <MenuItem key={`cross-chain-modal-select-${index}`} value={option.id}>
                {option.networkName}
              </MenuItem>
            );
          })}
        </Select>
        <WalletAddressContainer>
          <PasswordInputs
            chain={selectedChain}
            password={EVM_CHAINS.includes(selectedChain) ? keys.ethereum! : keys[selectedChain]!}
          />
        </WalletAddressContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            maxWidth: "495px",
          }}>
          <RetroButton variant="primary" onClick={handleCopy}>
            <Typography variant="title-h3" sx={{ padding: "12px 21.5px" }}>
              COPY
            </Typography>
          </RetroButton>
        </Box>
      </ExportKeyDetailWrapper>
    </StyledExportKeyTextDrawer>
  );
};

const PasswordInputs = ({ password, chain }: { password: string; chain: Chain }) => {
  const [isShowPassword, setShowPassword] = useState<boolean>(false);
  const passwordRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setShowPassword(false);
  }, [chain]);

  const handleShowPassword = () => {
    setShowPassword(!isShowPassword);
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: "100%",
        }}>
        <PasswordInput
          type={isShowPassword ? "text" : "password"}
          ref={passwordRef}
          disabled
          value={password}
        />
        <PasswordButtonGroups>
          <ViewPasswordButton
            $variant={isShowPassword ? "text" : "password"}
            onClick={handleShowPassword}>
            {isShowPassword ? (
              <ShowIcon width={16} height={16} />
            ) : (
              <HideIcon width={16} height={16} />
            )}
          </ViewPasswordButton>
          {/* {isCopied ? (
            <Typography color="text.system-green">✔</Typography>
          ) : (
            <IconButton sx={{ padding: "0px" }} onClick={handleCopyWalletAddress}>
              <CopyIcon width={16} height={16} />
            </IconButton>
          )} */}
        </PasswordButtonGroups>
      </Box>
    </>
  );
};
