import { Outlet, useNavigate } from "react-router-dom";
import CabalTownLogo from "../../assets/icons/cabal-town-logo.png";
import { HeaderContainer, HeaderLogo } from "./styled";
import { HeaderProfile } from "./headerProfile";
import { RetroButton } from "components/RetroButton";
import { Box, Typography, useTheme } from "@mui/material";
import { Container } from "components/container";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import useLayoutConfigStore from "store/layout-config-store/useLayoutConfigStore";
import { ROUTES } from "consts";
import useToastStore from "store/toast-store/useToastStore";
import { HeaderToast } from "./headerToast";
import { Brick } from "components/Brick";

export const Header = () => {
  const { tgUserId, tgUserName, tgPhotoUrl } = useUserStoreV2();
  const { isShowHeader } = useLayoutConfigStore();
  const theme = useTheme();

  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      if (!tgUserId || !tgUserName) {
        navigate(ROUTES.portfolio);
      }
    } catch (error) {
      console.error("Error while logging in", error);
    }
  };

  const navigateToPorfolio = () => {
    navigate(ROUTES.portfolio);
  };

  const navigateToExplorer = () => {
    navigate(ROUTES.explorer);
  };

  const { toastState, resetToast } = useToastStore();

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Box sx={{ background: theme.palette.surface.container }}>
          <Container>
            {isShowHeader ? (
              <HeaderContainer>
                <Brick variant="darker">
                  <Box
                    width="100%"
                    height="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    padding={theme.spacing(0.3)}>
                    <HeaderLogo
                      src={CabalTownLogo}
                      alt="cabal-town-logo"
                      onClick={navigateToExplorer}
                    />
                  </Box>
                </Brick>
                {tgUserName ? (
                  <HeaderProfile
                    name={tgUserName}
                    onClick={navigateToPorfolio}
                    avatar={tgPhotoUrl}
                  />
                ) : null}
                {/* ) : (
                  <Box sx={{ width: "120px", alignSelf: "stretch" }}>
                    <RetroButton variant="white" onClick={handleLogin}>
                      <Typography variant="title-h3">Signup</Typography>
                    </RetroButton>
                  </Box>
                )} */}
              </HeaderContainer>
            ) : null}
          </Container>
        </Box>

        <HeaderToast
          isOpen={!!toastState.message}
          variant={toastState.variant}
          message={toastState.message}
          onClose={resetToast}
        />
      </Box>

      <Box sx={{ marginTop: theme.spacing(1) }}></Box>
      {/* spacer */}

      <Outlet />
    </>
  );
};
