import { Avatar, Box, Drawer, IconButton, Select, styled, TextField, Theme } from "@mui/material";

export const PortfolioContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  width: "100%",
}));

export const WalletDetailListContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "16px",
  backgroundColor: theme.palette.surface.silver,
  borderRadius: "16px",
}));

export const WalletDetailListHeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: "46px",
}));

export const WalletDetailListBodyContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  gap: "6px",
}));

export const WalletDetailItemContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: "46px",
  padding: "0 16px",
  border: `1px solid ${theme.palette.border.dark}`,
  backgroundColor: theme.palette.surface.container,
  borderRadius: "16px",
}));

export const WalletDetailItemNetworkContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.surface.light,
  padding: "6px 8px 2px",
  borderBottom: `1px solid ${theme.palette.border.dark}`,
  borderRadius: "8px",
  minWidth: "53px",
}));

export const TokenNetworkContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: theme.palette.surface.light,
  padding: "6px 8px 2px",
  borderBottom: `1px solid ${theme.palette.border.dark}`,
  borderRadius: "8px",
}));

export const WidgeWrapper = styled(Box)<{
  $justify?: "start" | "center";
  $align?: "start" | "center";
}>(({ theme, $justify = "start", $align = "start" }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: $justify,
  alignItems: $align,
  padding: "8px 12px",
  backgroundColor: theme.palette.surface.silver,
  border: `1px solid ${theme.palette.border.dark}`,
  borderRadius: "16px",
  gap: "12px",
  height: "fit-content",
}));

export const ExportKeyButtonWrapper = styled(Box)(({ theme }) => ({
  width: "125px",
  height: "46px",
}));

export const StyledCopyIcon = styled("img")(({ theme }) => ({
  width: "16px",
  height: "16px",
}));
// export const UserWalletDetailContainer = styled(Box)(({ theme }) => ({
//   width: "100%",
//   height: "382px"
// }));

export const StyledUserIcon = styled(Avatar)(({ theme }) => ({
  width: "55px",
  height: "55px",
  borderRadius: "50%",
  border: `1px solid ${theme.palette.border.dark}`,
}));

export const TapsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  gap: "8px",
  padding: "8px",
  backgroundColor: theme.palette.surface.container,
  borderRadius: "16px",
  border: `1px solid ${theme.palette.border.dark}`,
}));

export const PortfolioDetailListContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "6px",
  width: "100%",
  padding: "20px",
}));

export const PortfolioDetailListHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
}));

export const PortfolioDetailListTitle = styled(Box)(({ theme }) => ({
  width: "80px",
  marginLeft: "20px",
}));

export const PortfolioDetailListItemContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  backgroundColor: theme.palette.surface.silver,
  border: `1px solid ${theme.palette.border.dark}`,
  borderRadius: "16px",
  padding: "12px",
}));

export const PortfolioDetailListItemColumnContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "95px",
  justifyContent: "start",
  alignItems: "center",
  gap: "2px",
  overflow: "hidden",
}));

export const PortfolioDetailListItemCabalIcon = styled("img")(({ theme }) => ({
  width: "32px",
  height: "32px",
  borderRadius: "8px",
  overflow: "hidden",
}));

export const Overlay = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 999,
}));

export const ModalWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "411px",
  padding: "20px",
  "&:focus-visible": {
    outline: "none",
  },
}));

export const CloseButtonWrapper = styled(Box)(({ theme }) => ({
  width: "52px",
  height: "52px",
  borderRadius: "50%",
}));

export const CheckCircleImage = styled("img")(({ theme }) => ({
  width: "101px",
  height: "101px",
  margin: "0 auto",
}));

export const PasswordInput = styled("input")(({ theme }) => ({
  width: "100%",
  height: "24px",
  // padding: "12px 16px",
  // border: `1px solid ${theme.palette.border.dark}`,
  border: "none",
  backgroundColor: theme.palette.surface.silver,
  // borderRadius: "16px",
  color: theme.palette.text.primary,
  paddingRight: "68px",
  ...theme.typography["body-md"],
}));

export const PasswordButtonGroups = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: "8px",
  top: "50%",
  transform: "translateY(-50%)",
  display: "flex",
  gap: "8px",
  padding: "8px",
}));

export const ViewPasswordButton = styled(IconButton)<{ $variant: "password" | "text" }>(
  ({ theme, $variant }) => ({
    opacity: $variant === "password" ? 0.7 : 1,
    padding: "0",
  }),
);

export const StyledSelect = styled(Select)<{ noPointer?: boolean }>(({ theme, noPointer }) => ({
  pointerEvents: noPointer ? "none" : "auto",
  cursor: "pointer",
  borderRadius: `${theme.spacing(1.5)} 0 0 ${theme.spacing(1.5)}`,
  overflow: "hidden",

  "& :focus-visible": {
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
  },

  ".MuiSelect-select": {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(1, 2),
    borderRadius: `${theme.spacing(1.5)} 0 0 ${theme.spacing(1.5)}`,
    backgroundColor: theme.palette.surface.silver,
    pointerEvents: noPointer ? "none" : "auto",
    width: "100%",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
    borderRadius: `${theme.spacing(1.5)} 0 0 ${theme.spacing(1.5)}`,
    outline: "none",
  },

  "& .MuiSelect-icon": {
    color: theme.palette.text.secondary,
    fontSize: "24px",
  },

  "&:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));

export const StyledTextField = styled("input")(({ theme }) => ({
  backgroundColor: theme.palette.surface.silver,
  borderRadius: "12px",
  padding: "14px 12px",
  width: "100%",

  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },

  "& .MuiInputBase-input": {
    padding: "8px",
    outline: "none",
    border: "none",
    ...theme.typography["body-default"],
  },
}));

const TYPOGRAPHY_BODY_MD = (theme: Theme) => ({
  ...theme.typography["body-default"],
});

export const StyledInputV2 = styled("input")(({ theme }) => ({
  width: "100%",
  height: "46px",
  border: `none`,
  textAlign: "end",
  backgroundColor: theme.palette.surface.silver,
  borderRadius: "8px",
  padding: "0 12px",
  opacity: "0.5",
  color: theme.palette.text.primary,
  ...TYPOGRAPHY_BODY_MD(theme as Theme),
  "&:focus, &:not(:placeholder-shown)": {
    opacity: "1",
    outline: "none",
  },
  "&::placeholder": {
    color: theme.palette.text.primary,
  },
}));

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.surface.bg,
    color: theme.palette.text.primary,
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
}));

export const DrawerHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  height: "68px",
  width: "100%",
  marginLeft: "16px",
}));

export const ExportKeyDetailWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
}));

export const ExportKeyTextWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "343px",
  textAlign: "center",
  marginBottom: "48px",

  [theme.breakpoints.down("sm")]: {
    marginBottom: "24px",
  },
}));

export const WalletAddressContainer = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: "495px",
  borderRadius: "16px",
  backgroundColor: theme.palette.surface.silver,
  border: `1px solid ${theme.palette.border.dark}`,
  padding: "12px 16px",
  textAlign: "center",
  overflowWrap: "break-word",
  marginBottom: "24px",
}));

export const StyledExportKeyTextDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.surface.bg,
    display: "flex",
    flexdirection: "column",
    alignItems: "center",
    padding: "20px",
  },
}));
