import { Box } from "@mui/material";
import { RetroCard } from "components/RetroCard";
import { useLocation } from "react-router-dom";

type BlockerConfigs = {
  check: boolean;
  fallback: React.ReactNode;
  excludePaths?: string[];
};

export const Blocker = ({
  blockerConfigs,
  withCard,
  children,
}: {
  blockerConfigs: Array<BlockerConfigs>;
  withCard?: boolean;
  children: React.ReactNode;
}): JSX.Element => {
  const location = useLocation();

  for (let blockerConfig of blockerConfigs) {
    if (blockerConfig.excludePaths) {
      if (blockerConfig.excludePaths.includes(location.pathname)) {
        continue;
      }
    }

    if (!blockerConfig.check && withCard) {
      return <RetroCard>{blockerConfig.fallback}</RetroCard>;
    } else if (!blockerConfig.check) {
      return <>{blockerConfig.fallback}</>;
    }
  }

  return children !== undefined ? <>{children}</> : <></>;
};
