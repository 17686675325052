import React, { useCallback, useMemo } from "react";
import { Typography, TypographyProps } from "@mui/material";
const DEFAULT_CONFIG = {
  zeroCountThreshold: 0.001,
  smallNumberThreshold: 1,
  smallNumberSignificantDigits: 4, // Significant digits, default 4
  zeroCountSignificantDigits: 4, // Significant digits for ZeroCount format
  largeNumberThreshold: 1,
  largeNumberDecimals: 2,
  kmbtDecimals: 2, // Default to 2 decimals for KMBT
  useZeroCountFormat: true,
  useSmallNumberPrecision: true,
  useLargeNumberDecimals: true,
  useKMBTFormat: true,
  useFixedDecimals: false, // Default to false (remove unnecessary trailing zeros)
  isPrice: false,
};

// Define the props for the Value component
interface ValueProps extends TypographyProps {
  value: string | number;
  isPrice?: boolean;
  // Configurable thresholds and significant digits
  zeroCountThreshold?: number; // When to switch to ZeroCount format (e.g., 0.0001)
  smallNumberThreshold?: number; // When to apply small number significant digits (e.g., 1)
  smallNumberSignificantDigits?: number; // Significant digits for small numbers (e.g., 4)
  zeroCountSignificantDigits?: number; // Significant digits for ZeroCount format (e.g., 4)
  largeNumberThreshold?: number; // When to apply large number decimals (e.g., 1)
  largeNumberDecimals?: number; // Decimals for large numbers (e.g., 2)
  kmbtDecimals?: number; // Decimals for KMBT format (e.g., 2)
  // Flags to toggle modes
  useZeroCountFormat?: boolean;
  useSmallNumberPrecision?: boolean;
  useLargeNumberDecimals?: boolean;
  useKMBTFormat?: boolean; // Optional K, M, B, T formatting
  useFixedDecimals?: boolean; // Whether to enforce fixed decimal places (default: false)
}

export const Value: React.FC<ValueProps> = (props) => {
  const { value, ...typographyProps } = props;

  // Destructure config values with defaults from DEFAULT_CONFIG
  const {
    zeroCountThreshold,
    smallNumberThreshold,
    smallNumberSignificantDigits,
    zeroCountSignificantDigits,
    largeNumberThreshold,
    largeNumberDecimals,
    kmbtDecimals,
    useZeroCountFormat,
    useSmallNumberPrecision,
    useLargeNumberDecimals,
    useKMBTFormat,
    useFixedDecimals,
    isPrice,
  } = { ...DEFAULT_CONFIG, ...props };

  // Parse the value to a number for calculations
  const parsedValue = typeof value === "string" ? parseFloat(value) : value;

  // Memoize the KMBT formatting function
  const formatKMBT = useCallback(
    (num: number): string => {
      let formatted: string;
      if (Math.abs(num) >= 1e12) {
        formatted = `${(num / 1e12).toFixed(kmbtDecimals)}T`;
      } else if (Math.abs(num) >= 1e9) {
        formatted = `${(num / 1e9).toFixed(kmbtDecimals)}B`;
      } else if (Math.abs(num) >= 1e6) {
        formatted = `${(num / 1e6).toFixed(kmbtDecimals)}M`;
      } else if (Math.abs(num) >= 1e3) {
        formatted = `${(num / 1e3).toFixed(kmbtDecimals)}K`;
      } else {
        formatted = num.toFixed(largeNumberDecimals);
      }
      // Remove trailing zeros and unnecessary decimal point if useFixedDecimals is false
      if (!useFixedDecimals) {
        formatted = formatted.replace(/\.?0+$/, "");
      }
      return formatted;
    },
    [kmbtDecimals, largeNumberDecimals, useFixedDecimals],
  );

  // Memoize the ZeroCount formatting function
  const formatZeroCount = useCallback(
    (num: number): JSX.Element => {
      // Convert the number to a string with scientific notation to avoid floating-point issues
      const str = num.toExponential(10);
      const [mantissa, exponent] = str.split("e");
      const exponentValue = parseInt(exponent, 10);

      // Calculate the number of leading zeros after the decimal point
      let zeroCount = 0;
      let significantDigits = mantissa.replace(".", "").replace("-", "");

      // If the exponent is negative, calculate leading zeros
      if (exponentValue < 0) {
        zeroCount = Math.abs(exponentValue) - 1; // -1 because the first digit is the mantissa
        significantDigits = significantDigits.replace(/^0+/, ""); // Remove leading zeros in mantissa
      }

      // Truncate to zeroCountSignificantDigits
      significantDigits = significantDigits.slice(0, zeroCountSignificantDigits);

      // Remove trailing zeros from significant digits
      significantDigits = significantDigits.replace(/0+$/, "");

      // If there are no significant digits (e.g., 0.0000000), return just 0
      if (!significantDigits) {
        return <>0</>;
      }

      // Render as 0.0<sub>zeroCount</sub>significantDigits
      return (
        <>
          0.0<sub style={{ display: "inline-block" }}>{zeroCount}</sub>
          {significantDigits}
        </>
      );
    },
    [zeroCountSignificantDigits],
  );

  // Helper function to format the value based on conditions
  const formatValue = (num: number): string | JSX.Element => {
    // If no formatting modes are enabled, return the full number as a string
    if (
      !useZeroCountFormat &&
      !useSmallNumberPrecision &&
      !useLargeNumberDecimals &&
      !useKMBTFormat
    ) {
      return num.toString();
    }

    // Check for ZeroCount format (very small numbers)
    if (useZeroCountFormat && Math.abs(num) < zeroCountThreshold && num !== 0) {
      return formatZeroCount(num);
    }

    // Check for KMBT formatting (prioritized over largeNumberDecimals)
    if (useKMBTFormat && Math.abs(num) >= largeNumberThreshold) {
      return formatKMBT(num);
    }

    // Check for large numbers (≥ 1) if KMBT is not applied
    if (useLargeNumberDecimals && Math.abs(num) >= largeNumberThreshold) {
      const formatted = num.toFixed(largeNumberDecimals);
      // Remove trailing zeros and unnecessary decimal point if useFixedDecimals is false
      return useFixedDecimals ? formatted : formatted.replace(/\.?0+$/, "");
    }

    // Check for small numbers (< 1)
    if (useSmallNumberPrecision && Math.abs(num) < smallNumberThreshold && num !== 0) {
      // Convert to string with high precision
      const str = num.toFixed(15);
      const decimalPart = str.split(".")[1] || "";

      // Find the first non-zero digit to start counting significant digits
      let leadingZeros = 0;
      let significantDigits = "";
      for (let i = 0; i < decimalPart.length; i++) {
        if (decimalPart[i] === "0") {
          leadingZeros++;
        } else {
          significantDigits = decimalPart.slice(i);
          break;
        }
      }

      // Truncate to smallNumberSignificantDigits
      significantDigits = significantDigits.slice(0, smallNumberSignificantDigits);

      // Remove trailing zeros from significant digits
      significantDigits = significantDigits.replace(/0+$/, "");

      // Reconstruct the number with the same number of leading zeros
      return `0.${"0".repeat(leadingZeros)}${significantDigits}`.replace(/\.?0+$/, "");
    }

    // Default: return the number as a string
    return num.toString();
  };

  // Memoize the formatted value to avoid unnecessary recalculations
  let formattedValue = useMemo(
    () => (isNaN(parsedValue) ? "0" : formatValue(parsedValue)),
    [
      parsedValue,
      zeroCountThreshold,
      smallNumberThreshold,
      smallNumberSignificantDigits,
      largeNumberThreshold,
      largeNumberDecimals,
      useZeroCountFormat,
      useSmallNumberPrecision,
      useLargeNumberDecimals,
      useKMBTFormat,
      useFixedDecimals,
      formatZeroCount,
      formatKMBT,
    ],
  );

  if (formattedValue === "0") {
    formattedValue = useFixedDecimals ? "0.".concat("0".repeat(largeNumberDecimals)) : "0";
  }

  return (
    <Typography {...typographyProps}>
      {isPrice ? <>${formattedValue}</> : formattedValue}
    </Typography>
  );
};
