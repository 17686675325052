import React from "react";
import { Box, Typography, Paper, Grid, Divider, styled, useTheme, Theme } from "@mui/material";
import { Value } from "components/Value";

// Styled components for the demo page
const Section = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
  backgroundColor: theme.palette.surface.container,
  border: `1px solid ${theme.palette.border.dark}`,
}));

const ColorBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "bgcolor" && prop !== "color",
})<{ bgcolor?: string; color?: string }>(({ bgcolor, color }) => ({
  width: "100%",
  height: "80px",
  backgroundColor: bgcolor,
  color: color || "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "8px",
  borderRadius: "4px",
  marginBottom: "8px",
}));

const SampleText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "textcolor",
})<{ textcolor?: string }>(({ textcolor }) => ({
  color: textcolor,
  marginBottom: "8px",
}));

const DesignSystemPage = () => {
  const theme = useTheme();

  // Typography variants from the theme
  const typographyVariants = [
    "display-lg",
    "display-md",
    "display-sm",
    "title-h1",
    "title-h2",
    "title-h3",
    "subtitle",
    "body-lg",
    "body-md",
    "body-default",
    "body-sm",
    "pre-title",
    "pre-title-2",
    "button-lg",
    "button-md",
    "button-sm",
  ];

  // Text colors from the theme
  const textColors = {
    "Text Primary": theme.palette.text.primary,
    "Text Secondary": theme.palette.text.secondary,
    "Text Brand": theme.palette.text.brand,
    "Text Disabled": theme.palette.text.disabled,
    "Text System Green": theme.palette.text["system-green"],
    "Text System Red": theme.palette.text["system-red"],
    "Text Button Dark": theme.palette.text["btn-dark"],
  };

  // Background colors from the theme
  const backgroundColors = {
    // Brand
    "Brand Primary": theme.palette.brand.primary,
    "Brand Primary BG": theme.palette.brand["primary-bg"],

    // System
    "System Green": theme.palette.system.green,
    "System Green BG": theme.palette.system["green-bg"],
    "System Red": theme.palette.system.red,
    "System Red BG": theme.palette.system["red-bg"],

    // Surface
    "Surface Light": theme.palette.surface.light,
    "Surface Light BG": theme.palette.surface["light-bg"],
    "Surface Silver": theme.palette.surface.silver,
    "Surface Container": theme.palette.surface.container,
    "Surface BG": theme.palette.surface.bg,
    "Surface Container BG": theme.palette.surface["container-bg"],

    // Borders
    "Border Dark": theme.palette.border.dark,
    "Border Light": theme.palette.border.light,
  };

  return (
    <Box sx={{ maxWidth: "1200px", margin: "0 auto", padding: 3 }}>
      <Typography variant="title-h1" component="h1" gutterBottom>
        Design System
      </Typography>

      {/* Typography Section */}
      <Section>
        <Typography variant="title-h2" gutterBottom>
          Typography
        </Typography>
        <Divider sx={{ mb: 4 }} />

        <Grid container spacing={2}>
          {typographyVariants.map((variant) => (
            <Grid item xs={12} md={6} key={variant}>
              <Box
                sx={{
                  mb: 2,
                  p: 2,
                  border: `1px solid ${theme.palette.border.light}`,
                  borderRadius: "4px",
                }}>
                <Typography
                  variant="body-sm"
                  component="div"
                  gutterBottom
                  sx={{ color: theme.palette.text.secondary }}>
                  {variant}
                </Typography>
                <Typography variant={variant as any} component="div">
                  This is {variant} text
                </Typography>
                <Typography
                  variant="body-sm"
                  component="div"
                  sx={{ mt: 1, color: theme.palette.text.secondary }}>
                  {`Font: ${(theme.typography as any)[variant].fontFamily}`}
                  <br />
                  {`Size: ${(theme.typography as any)[variant].fontSize}`}
                  <br />
                  {`Weight: ${(theme.typography as any)[variant].fontWeight}`}
                  <br />
                  {`Line Height: ${(theme.typography as any)[variant].lineHeight}`}
                  {(theme.typography as any)[variant].letterSpacing && (
                    <>
                      <br />
                      {`Letter Spacing: ${(theme.typography as any)[variant].letterSpacing}`}
                    </>
                  )}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Section>

      {/* Text Colors Section */}
      <Section>
        <Typography variant="title-h2" gutterBottom>
          Text Colors
        </Typography>
        <Divider sx={{ mb: 4 }} />

        <Grid container spacing={3}>
          {Object.entries(textColors).map(([name, color]) => (
            <Grid item xs={12} sm={6} md={4} key={name}>
              <Box
                sx={{
                  p: 2,
                  border: `1px solid ${theme.palette.border.light}`,
                  borderRadius: "4px",
                }}>
                <SampleText variant="body-md" textcolor={color}>
                  {name}
                </SampleText>
                <SampleText variant="body-sm" textcolor={theme.palette.text.secondary}>
                  {color as string}
                </SampleText>
                <Box
                  sx={{
                    height: "40px",
                    backgroundColor: color,
                    borderRadius: "4px",
                    border: `1px solid ${theme.palette.border.light}`,
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Section>

      {/* Background Colors Section */}
      <Section>
        <Typography variant="title-h2" gutterBottom>
          Background Colors
        </Typography>
        <Divider sx={{ mb: 4 }} />

        <Grid container spacing={3}>
          {Object.entries(backgroundColors).map(([name, color]) => (
            <Grid item xs={12} sm={6} md={4} key={name}>
              <ColorBox
                bgcolor={color}
                color={
                  name.includes("Light")
                    ? theme.palette.text["btn-dark"]
                    : theme.palette.text.primary
                }>
                {name}
              </ColorBox>
              <Typography variant="body-sm" sx={{ color: theme.palette.text.secondary }}>
                {color as string}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Section>

      {/* Font Preview Section */}
      <Section>
        <Typography variant="title-h2" gutterBottom>
          Font Preview
        </Typography>
        <Divider sx={{ mb: 4 }} />

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="title-h3" gutterBottom>
                TT Interphases Pro Mono
              </Typography>
              <Box
                sx={{
                  p: 2,
                  border: `1px solid ${theme.palette.border.light}`,
                  borderRadius: "4px",
                }}>
                <Typography
                  sx={{ fontFamily: "TT Interphases Pro Mono, sans-serif", fontWeight: "normal" }}>
                  Regular - The quick brown fox jumps over the lazy dog. 1234567890
                </Typography>
                <Typography
                  sx={{ fontFamily: "TT Interphases Pro Mono, sans-serif", fontWeight: "bold" }}>
                  Bold - The quick brown fox jumps over the lazy dog. 1234567890
                </Typography>
                <Typography
                  sx={{ fontFamily: "TT Interphases Pro Mono, sans-serif", fontStyle: "italic" }}>
                  Italic - The quick brown fox jumps over the lazy dog. 1234567890
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "TT Interphases Pro Mono, sans-serif",
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }}>
                  Bold Italic - The quick brown fox jumps over the lazy dog. 1234567890
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="title-h3" gutterBottom>
                Sora Font
              </Typography>
              <Box
                sx={{
                  p: 2,
                  border: `1px solid ${theme.palette.border.light}`,
                  borderRadius: "4px",
                }}>
                <Typography sx={{ fontFamily: "Sora Font, sans-serif", fontWeight: "normal" }}>
                  Regular - The quick brown fox jumps over the lazy dog. 1234567890
                </Typography>
                <Typography sx={{ fontFamily: "Sora Font, sans-serif", fontWeight: "bold" }}>
                  Bold - The quick brown fox jumps over the lazy dog. 1234567890
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="title-h3" gutterBottom>
                Cabin Condensed
              </Typography>
              <Box
                sx={{
                  p: 2,
                  border: `1px solid ${theme.palette.border.light}`,
                  borderRadius: "4px",
                }}>
                <Typography sx={{ fontFamily: "Cabin Condensed, sans-serif", fontWeight: "400" }}>
                  Regular - The quick brown fox jumps over the lazy dog. 1234567890
                </Typography>
                <Typography sx={{ fontFamily: "Cabin Condensed, sans-serif", fontWeight: "500" }}>
                  Medium - The quick brown fox jumps over the lazy dog. 1234567890
                </Typography>
                <Typography sx={{ fontFamily: "Cabin Condensed, sans-serif", fontWeight: "600" }}>
                  Semi-Bold - The quick brown fox jumps over the lazy dog. 1234567890
                </Typography>
                <Typography sx={{ fontFamily: "Cabin Condensed, sans-serif", fontWeight: "700" }}>
                  Bold - The quick brown fox jumps over the lazy dog. 1234567890
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="title-h3" gutterBottom>
                Aber Mono
              </Typography>
              <Box
                sx={{
                  p: 2,
                  border: `1px solid ${theme.palette.border.light}`,
                  borderRadius: "4px",
                }}>
                <Typography sx={{ fontFamily: "Aber Mono, sans-serif", fontWeight: "300" }}>
                  Light - The quick brown fox jumps over the lazy dog. 1234567890
                </Typography>
                <Typography sx={{ fontFamily: "Aber Mono, sans-serif", fontWeight: "normal" }}>
                  Regular - The quick brown fox jumps over the lazy dog. 1234567890
                </Typography>
                <Typography sx={{ fontFamily: "Aber Mono, sans-serif", fontWeight: "bold" }}>
                  Bold - The quick brown fox jumps over the lazy dog. 1234567890
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Section>
      <Section>
        <Typography variant="title-h2" gutterBottom>
          Price
        </Typography>
        <Divider sx={{ mb: 4 }} />

        <Value value={0.000012} />
        <Value value={0.00012} />
        <Value value={0.0012} />
        <Value value={0.012} />
        <Value value={0.12} />
        <Value value={1} />
        <Value value={10} />
        <Value value={100} />
        <Value value={1000} />
        <Value value={10000} />
        <Value value={1.1} />
        <Value value={11} />
        <Value value={110} />
        <Value value={1100} />
        <Value value={11000} />
        <Value value={0.00001256124125} />
        <Value value={0.0001256124125} />
        <Value value={0.001256124125} />
        <Value value={0.01256124125} />
        <Value value={0.1256124125} />
        <Value value={1.256124125} />
        <Value value={12.56124125} />
        <Value value={125.6124125} />
        <Value value={1256.124125} />
        <Value value={12561.24125} />
        <Value value={125612.4125} />
        <Value value={1256124.125} />
        <Value value={12561241.25} />
        <Value value={125612412.5} />
        <Value value={1256124125} />
      </Section>
    </Box>
  );
};

export default DesignSystemPage;
