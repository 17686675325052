import React, { forwardRef, useRef, useState, ChangeEvent, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { RetroCard } from "components/RetroCard";
import {
  CabalIcon,
  CreateCabalButtonWrapper,
  CurveSteepnessHeaderWrapper,
  CurveSteepnessOption,
  CurveSteepnessOptionRadio,
  CurveSteepnessOptionRadioContainer,
  CurveSteepnessOptionsWrapper,
  CurveSteepnessOptionText,
  StyledInput,
  StyledLabel,
  StyledOption,
  StyledSelect,
  UploadButtonWrapper,
  UploadImageContainer,
  ViewButtonWrapper,
} from "./styles";
import { RetroButton } from "components/RetroButton";
import { ReactComponent as ImageIcon } from "../../assets/icons/image.svg";
import { SteepnessPreview } from "./steepnessPreview/SteepnessPreview";
import axiosService from "services/axios";
import { CreateCabalError } from "./createCabalModal";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { useNavigatePreserveQuery } from "lib/hooks/useNavigatePreserveQuery";
import { PreviewChart } from "components/Chart/PreviewChart";
import useToastStore from "store/toast-store/useToastStore";
import useLayoutConfigStore from "store/layout-config-store/useLayoutConfigStore";
import { useTheme } from "@mui/material";
import { GroupMode } from "./CreateCabalMenu";
import { Cached } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";

const initialData = {
  Casual: require("./data/casual.json"),
  Standard: require("./data/standard.json"),
  Exclusive: require("./data/exclusive.json"),
};

// Define the ref type
type CreateCabalData = {
  cabalName: string;
  cabalSize: number;
  cabalImageUrl: string;
};
export interface CreateCabalRef {
  getValue: () => CreateCabalData;
  deployContract: () => Promise<void>;
}

export type Steepness = "Casual" | "Standard" | "Exclusive";
export type Language = "English" | "Chinese" | "Korean" | "Russian";

type CreateCabalProps = {
  mode: GroupMode;
  onCabalCreated: (cabalId: string | null) => void;
  onPreviousPage: () => void;
  handleOnSkip: () => void;
};

const SteepnessRR = {
  Casual: "7500",
  Standard: "420",
  Exclusive: "190",
};

const SteepnessMaxMemberPreview = {
  Casual: "2000",
  Standard: "200",
  Exclusive: "100",
};

const LanguageShortForm = {
  English: "en",
  Chinese: "zh",
  Korean: "ko",
  Russian: "ru",
};

export const CreateCabalV2 = forwardRef<CreateCabalRef, CreateCabalProps>(
  ({ mode, onCabalCreated, onPreviousPage, handleOnSkip }) => {
    const { tgUserId, tgUserName, walletAddresses, tgPhotoUrl, hasWallet, createWallet } =
      useUserStoreV2();

    const navigate = useNavigatePreserveQuery();
    const { showToast } = useToastStore();
    const { setIsShowHeader } = useLayoutConfigStore();
    const theme = useTheme();

    // Icon
    const [icon, setIcon] = useState<File | undefined>();
    const cabalIconRef = useRef<HTMLInputElement>(null);

    // Name, Token ticker, Language
    const [name, setName] = useState<string | undefined>();
    const [tokenTicker, setTokenTicker] = useState<string | undefined>();
    const [language, setLanguage] = useState<Language>("English");

    // Curve Steepness
    const [curveSteepness, setCurveSteepness] = useState<Steepness>("Standard");

    // Submition
    const [isValidToSubmit, setIsValidToSubmit] = useState<boolean>(false);

    // Preview
    const [isOpennedPreview, setIsOpennedPreview] = useState(false);

    // Drawer
    const [isOpennedModal, setIsOpennedModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    // Created cabal id
    const [cabalId, setCabalId] = useState<string>("default");

    // Loading State
    const [isLoading, setIsLoading] = useState(false);
    const loadingRef = useRef(false);

    // Free Cabal
    const [selectedGroup, setSelectedGroup] = useState<string | undefined>();

    const {
      data: freeGroupData = [],
      isLoading: isFreeGroupLoading,
      refetch: refetchFreeGroupData,
      isRefetching: isFreeGroupRefetching,
    } = useQuery({
      queryKey: ["free-group-list", hasWallet],
      queryFn: async () => {
        setCabalId("default");
        return axiosService.getAllCabals({ isActive: false });
      },
      enabled: mode === "free",
    });

    useEffect(() => {
      if (mode === "free") {
        setIsValidToSubmit(!!name && !!language && cabalId !== "default" && !!icon);
      }

      if (mode === "paid") {
        setIsValidToSubmit(!!name && !!tokenTicker && !!language && !!curveSteepness);
      }
    }, [icon, name, tokenTicker, language, curveSteepness, cabalId]);

    const handleUpload = () => {
      cabalIconRef.current?.click();
    };

    const handleIconSelected = async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setIcon(file);
      }
    };

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    };

    const handleTokenTickerChange = (event: ChangeEvent<HTMLInputElement>) => {
      setTokenTicker(event.target.value);
    };

    const handleSelectLanguage = (event: any) => {
      setLanguage(event.target.value);
    };

    const handleOpenPreview = () => {
      setIsOpennedPreview(true);
    };

    const handleClosePreview = () => {
      setIsOpennedPreview(false);
    };

    const handleOpenSuccessModal = () => {
      setErrorMessage("");
      setIsOpennedModal(true);
    };

    const handleOpenErrorModal = (message: string) => {
      setErrorMessage(message);
      setIsOpennedModal(true);
    };

    const handleCloseModal = () => {
      setIsOpennedModal(false);
    };

    const handleSelectCurveSteepness = (steepness: Steepness) => {
      setCurveSteepness(steepness);
    };

    // Form submition
    const handleOnSubmit = async () => {
      let res: {
        status: number | null;
        message: string;
      } = {
        status: null,
        message: "",
      };

      try {
        loadingRef.current = true;
        setIsLoading(true);

        let tonWallet = walletAddresses?.tonAddress;
        // Validation
        let isInValid = false;
        if (mode === "free") {
          isInValid =
            !name || !tgUserId || !tgUserName || !language || cabalId === "default" || !icon;
        }

        if (mode === "paid") {
          isInValid =
            !name ||
            !tgUserId ||
            !tokenTicker ||
            !tonWallet ||
            !tgUserName ||
            !curveSteepness ||
            !tokenTicker ||
            !language;
        }

        if (isInValid) {
          throw new Error("Failed to create cabal.");
        }

        // Upload icon
        let iconUrl;
        showToast({ variant: "info", message: `Uploading Cabal Icon...` });
        if (icon) {
          iconUrl = await axiosService.uploadFile(icon);
        } else if (mode === "paid") {
          iconUrl = tgPhotoUrl;
        }

        if (!iconUrl) {
          handleOpenErrorModal("Failed to upload image.");
          return setIsLoading(false);
        }

        // Create cabal
        showToast({ variant: "info", message: `Creating Cabal...` });

        let response;
        if (mode === "free") {
          const body = {
            name: name!,
            language: LanguageShortForm[language]!,
            imageUri: iconUrl!,
          };

          response = await axiosService.activateCabalByCabalId({
            body,
            tgGroupId: parseInt(cabalId!),
          });

          if (response.status < 300) {
            navigate(`/cabal/${cabalId}`);
          }
        } else {
          const body = {
            image: iconUrl!,
            name: name!,
            tgUserId: tgUserId!,
            tgUserName: tgUserName!,
            reserveRate: SteepnessRR[curveSteepness],
            description: "Cabal",
            symbol: tokenTicker || "",
            decimals: "9",
            language: LanguageShortForm[language],
          };

          response = await axiosService.insertJettonV2(body);
        }

        const { tgGroupId } = response.res;

        // Check response payload
        if (!tgGroupId) {
          res.message = response.res.message;
          res.status = response.res.status;
          throw new Error("Failed to get tgGroupId");
        }

        // Throw error if status code is not 2xx
        if (response.status > 299) {
          res.message = response.res.message;
          res.status = response.res.status;
          throw new Error(res.message);
        }

        onCabalCreated(tgGroupId);
        showToast({
          variant: "success",
          message: `Success to create Cabal, Telegram Group ID: ${tgGroupId}`,
          duration: 5000,
        });
        handleOpenSuccessModal();
      } catch (error) {
        if (res.status! >= 400) {
          showToast({ variant: "error", message: res.message, duration: 5000 });
        } else {
          showToast({ variant: "info", message: res.message, duration: 5000 });
        }
        console.error("Failed to create cabal:", error);
        handleOpenErrorModal("Failed to create cabal.");
      } finally {
        setIsLoading(false);
        loadingRef.current = false;
      }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      // console.debug("event: ", event.key);
      if (event.key === "Enter") {
        event.preventDefault();
        event.currentTarget.blur();
        // Force any active element to lose focus
        const activeElement = document.activeElement as HTMLElement;
        activeElement?.blur();
      }
    };

    return (
      <>
        <RetroCard>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "calc(100vh - 160px)",
              padding: "20px",
              gap: "16px",
            }}>
            {mode === "free" ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}>
                <Typography variant="title-h3">PUBLISH YOUR GROUP</Typography>
                <Typography variant="body-default" color="text.secondary">
                  Showcase your community on the Cabal Town app and expand your reach.
                </Typography>
              </Box>
            ) : null}
            <UploadImageContainer>
              <CabalIcon src={icon ? URL.createObjectURL(icon) : mode === "free" ? "" : tgPhotoUrl}>
                <ImageIcon />
              </CabalIcon>
              <UploadButtonWrapper>
                <RetroButton variant="white" onClick={handleUpload}>
                  <Typography variant="title-h3">
                    {mode === "free" ? "UPLOAD" : "CHANGE"}
                  </Typography>
                </RetroButton>
                <input
                  ref={cabalIconRef}
                  type="file"
                  onChange={handleIconSelected}
                  accept="image/png, image/jpeg"
                  hidden
                />
              </UploadButtonWrapper>
            </UploadImageContainer>
            {mode === "free" ? (
              <Box>
                <StyledLabel>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}>
                    <Typography variant="body-md" color="text.secondary">
                      Community
                    </Typography>
                    <Cached
                      onClick={() => {
                        if (!isFreeGroupLoading && !isFreeGroupRefetching) {
                          refetchFreeGroupData();
                        }
                      }}
                      sx={{
                        cursor:
                          isFreeGroupLoading || isFreeGroupRefetching ? "not-allowed" : "pointer",
                        marginY: "auto",
                        padding: "0",
                        width: "20px",
                        height: "20px",
                        ...((isFreeGroupLoading || isFreeGroupRefetching) && {
                          animation: "spin 2s linear infinite",
                          "@keyframes spin": {
                            "0%": {
                              transform: "rotate(360deg)",
                            },
                            "100%": {
                              transform: "rotate(0deg)",
                            },
                          },
                        }),
                      }}
                    />
                  </Box>
                  <StyledSelect
                    id="name"
                    defaultValue="default"
                    value={cabalId}
                    onChange={(e) => {
                      setCabalId((e.target as HTMLSelectElement).value);
                      setName(
                        freeGroupData.find((group) => group.tgGroupId === e.target.value)?.name ||
                          "",
                      );
                    }}
                    required>
                    <StyledOption value="default" disabled>
                      {isFreeGroupLoading || isFreeGroupRefetching
                        ? "Please Select Group"
                        : freeGroupData?.length === 0
                        ? "No Group Available"
                        : "Please Select Group"}
                    </StyledOption>
                    {freeGroupData.map((group) => (
                      <StyledOption value={group.tgGroupId}>{group.name}</StyledOption>
                    ))}
                  </StyledSelect>
                </StyledLabel>
              </Box>
            ) : (
              <StyledLabel>
                <Typography variant="body-md" color="text.secondary">
                  Alpha group name
                </Typography>
                <StyledInput
                  id="name"
                  placeholder="Full name"
                  onChange={handleNameChange}
                  onKeyDown={handleKeyDown}
                />
              </StyledLabel>
            )}
            {mode === "paid" ? (
              <StyledLabel>
                <Typography variant="body-md" color="text.secondary">
                  Group token symbol
                </Typography>
                <StyledInput
                  id="name"
                  placeholder="e.g. HELIUM"
                  onChange={handleTokenTickerChange}
                  onKeyDown={handleKeyDown}
                />
              </StyledLabel>
            ) : null}
            <StyledLabel>
              <Typography variant="body-md" color="text.secondary">
                Language
              </Typography>
              <StyledSelect
                id="name"
                defaultValue="English"
                onChange={handleSelectLanguage}
                required>
                <StyledOption value="English">English</StyledOption>
                <StyledOption value="Chinese">中文</StyledOption>
                <StyledOption value="Korean">한국어</StyledOption>
                <StyledOption value="Russian">русский</StyledOption>
              </StyledSelect>
            </StyledLabel>
            {mode === "free" ? (
              <Typography variant="body-default" color="text.secondary">
                If you wish to publish other groups or channels, use <b>/publish</b> in the Cabal
                Town Bot.
              </Typography>
            ) : null}
            {mode === "paid" ? (
              <>
                <CurveSteepnessHeaderWrapper>
                  <Typography variant="body-md" color="text.secondary">
                    Price Curves
                  </Typography>
                  <ViewButtonWrapper>
                    <RetroButton variant="white" onClick={handleOpenPreview}>
                      <Typography variant="button-md">PREVIEW</Typography>
                    </RetroButton>
                  </ViewButtonWrapper>
                </CurveSteepnessHeaderWrapper>
                <CurveSteepnessOptionsWrapper>
                  {(["Casual", "Standard", "Exclusive"] as Steepness[]).map((option) => (
                    <CurveSteepnessOptionRadioContainer
                      $selected={curveSteepness === option}
                      key={option}
                      onClick={() => handleSelectCurveSteepness(option as Steepness)}>
                      <CurveSteepnessOption>
                        <PreviewChart
                          variant={curveSteepness === option ? "active" : "non-active"}
                          data={initialData[option as Steepness].slice(
                            0,
                            option === "Standard" ? 75 : SteepnessMaxMemberPreview[option],
                          )}
                        />
                        <CurveSteepnessOptionText variant="body-md">
                          {option}
                        </CurveSteepnessOptionText>
                      </CurveSteepnessOption>
                      <CurveSteepnessOptionRadio $selected={curveSteepness === option} />
                    </CurveSteepnessOptionRadioContainer>
                  ))}
                </CurveSteepnessOptionsWrapper>
                <SteepnessPreview
                  isOpenned={isOpennedPreview}
                  close={handleClosePreview}
                  selectedSteepness={curveSteepness}
                  handleSelectCurveSteepness={handleSelectCurveSteepness}
                />
              </>
            ) : null}
            <CreateCabalError
              isOpen={errorMessage !== "" && isOpennedModal}
              close={handleCloseModal}
              errorMessage={errorMessage}
            />
          </Box>
        </RetroCard>
        <Box height="72px" />
        <CreateCabalButtonWrapper>
          {mode === "paid" ? (
            <Box sx={{ width: "100%" }}>
              <RetroButton variant="white" onClick={onPreviousPage}>
                <Typography variant="title-h3" sx={{ paddingY: "12px" }}>
                  BACK
                </Typography>
              </RetroButton>
            </Box>
          ) : null}
          <Box sx={{ width: "100%" }}>
            <RetroButton
              variant={isValidToSubmit && !isLoading ? "primary" : "disabled"}
              disabled={!isValidToSubmit || isLoading}
              onClick={handleOnSubmit}>
              <Typography
                variant="title-h3"
                sx={{ paddingY: "12px" }}
                color={isValidToSubmit && !isLoading ? "text.brand" : "text.secondary"}>
                {mode === "free" ? "PUBLISH" : "CREATE"}
              </Typography>
            </RetroButton>
          </Box>
        </CreateCabalButtonWrapper>
      </>
    );
  },
);
