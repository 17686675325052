/* eslint-disable react-hooks/exhaustive-deps */
import { Box, styled, Theme, Typography } from "@mui/material";
import { ReactComponent as SetUpPassKeyLogo } from "assets/icons/pass-key-set-up-circle.svg";
import { RetroButton } from "components/RetroButton";
import { useEmailAuth } from "hooks/useEmailAuth";
import { useCallback, useEffect, useRef, useState } from "react";
import axiosService from "services/axios";
import useLayoutConfigStore from "store/layout-config-store/useLayoutConfigStore";
import useToastStore from "store/toast-store/useToastStore";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";

const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const EmailCheckBlocker = ({
  variant,
  onVerified,
}: {
  variant: "init" | "update";
  onVerified?: () => void;
}): JSX.Element | null => {
  const loadingRef = useRef(false);
  const [email, setEmail] = useState<string | null>(null);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [isEmailSending, setIsEmailSending] = useState<boolean>(false);
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const [otp, setOtp] = useState<string | null>(null);
  const [subOrgId, setSubOrgId] = useState<string | undefined>(undefined);
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const [isOtpValid, setIsOtpValid] = useState<boolean>(false);
  const [isOtpVerifying, setIsOtpVerifying] = useState<boolean>(false);
  const [retryCount, setRetryCount] = useState<number>(0);
  const {
    verifyEmailAuth,
    createWalletWithEmail,
    createEmailAuthSession,
    initUserEmailRecovery,
    setCredential,
    deleteApiKeys,
    authIframeClient,
  } = useEmailAuth();
  const {
    tgUserName,
    email: userEmail,
    tgUserId,
    organizationId,
    refreshLatestUserState,
    logoutTgUserV2,
  } = useUserStoreV2();
  const { setIsShowFooter } = useLayoutConfigStore();
  const { showToast } = useToastStore();

  const _initEmailAuth = useCallback(async () => {
    if (variant === "init" && userEmail) {
      const turnkeyUserId = await createEmailAuthSession(userEmail);

      if (turnkeyUserId) {
        setEmail(userEmail);
        setIsEmailSent(true);
        setUserId(turnkeyUserId);
      }
    }
  }, [authIframeClient, variant, userEmail]);

  useEffect(() => {
    if (!loadingRef.current) {
      loadingRef.current = true;
      _initEmailAuth();
    }

    return () => {
      setEmail(null);
      setIsEmailValid(false);
      setIsEmailSending(false);
      setIsEmailSent(false);
      setOtp(null);
      setIsOtpValid(false);
      setIsOtpVerifying(false);
    };
  }, [_initEmailAuth]);

  useEffect(() => {
    setIsShowFooter(false);

    return () => {
      setIsShowFooter(true);
    };
  }, []);

  const handleOnEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setIsEmailValid(!!e.target.value.match(EMAIL_REGEX));
  };

  const handleOnOTPChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
    setIsOtpValid(!!e.target.value);
  };

  const handleSendEmail = async () => {
    if (!email) return;
    setIsEmailSending(true);

    if (retryCount > 1) {
      onClickLogout();
      return;
    }

    try {
      let newSubOrgId;
      if (!organizationId || organizationId === subOrgId) {
        await createWalletWithEmail(email, subOrgId);
        const user = await axiosService.getUser(tgUserId!.toString());
        newSubOrgId = user?.organizationId;
        setSubOrgId(newSubOrgId);
      }

      let turnkeyUserId;
      if (variant === "init") {
        turnkeyUserId = await createEmailAuthSession(email, newSubOrgId);
      } else {
        await initUserEmailRecovery(email);
        turnkeyUserId = await createEmailAuthSession(email);
      }

      if (!turnkeyUserId) {
        return showToast({
          variant: "error",
          message: `Incorrect email address. Please try again.`,
          duration: 5000,
        });
      }

      setUserId(turnkeyUserId);
      setIsEmailSent(true);
      showToast({ variant: "success", message: `Email sent to ${email}`, duration: 5000 });
    } catch (e) {
      console.error(e);
    } finally {
      setIsEmailSending(false);
      setRetryCount(retryCount + 1);
    }
  };

  const handleGoBack = async () => {
    setIsEmailSent(false);
  };

  const handleVerifyOTP = async () => {
    // console.debug("handleVerifyOTP", otp, email, userId);
    if (!otp || !email || !userId) return;

    setIsOtpVerifying(true);
    try {
      await verifyEmailAuth(otp, userId);
      showToast({
        variant: "success",
        message: "Email authenticated.",
        duration: 5000,
      });
      await refreshLatestUserState();
      setCredential((pre) => ({ ...pre, isSessionExpired: false }));
      if (variant === "update") {
        if (onVerified) {
          onVerified();
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsOtpVerifying(false);
      setIsShowFooter(true);
    }
  };

  const onClickLogout = async () => {
    await deleteApiKeys();
    logoutTgUserV2();
  };

  return !isEmailSent ? (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          padding: "16px",
          textAlign: "center",
        }}>
        <Box>
          <SetUpPassKeyLogo />
        </Box>
        <Typography sx={{ marginBottom: "8px", maxWidth: "240px" }} variant="title-h2">
          {variant === "init" ? "INPUT EMAIL FOR RECOVERY" : "UPDATE RECOVERY EMAIL"}
        </Typography>
        <Box
          sx={{
            textAlign: "start",
            marginBottom: "8px",
            maxWidth: "372px",
            width: "100%",
          }}>
          <Box sx={{ marginBottom: "4px" }}>
            <Typography variant="body-sm" color="text.secondary">
              Email Address
            </Typography>
          </Box>
          <StyledInputV2
            key="email"
            defaultValue={email ?? ""}
            onChange={handleOnEmailChange}
            sx={{ textAlign: "start" }}
          />
        </Box>
        <Box
          sx={{
            position: "relative",
            marginX: "auto",
            height: "56px",
            maxWidth: "372px",
            width: "100%",
            // paddingX: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "4px",
          }}>
          <RetroButton
            variant={isEmailValid && !isEmailSending ? "primary" : "disabled"}
            onClick={handleSendEmail}
            disabled={!isEmailValid || isEmailSending}>
            <Box sx={{ padding: "8px" }}>
              <Typography variant="title-h3">NEXT</Typography>
            </Box>
          </RetroButton>
        </Box>
        {tgUserId && tgUserName && variant !== "update" ? (
          <Typography
            sx={{ marginY: "20px" }}
            variant="pre-title-2"
            color="text.system-red"
            onClick={onClickLogout}>
            LOGOUT
          </Typography>
        ) : (
          <></>
        )}
      </Box>
    </>
  ) : (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          padding: "16px",
          textAlign: "center",
        }}>
        <Box>
          <SetUpPassKeyLogo />
        </Box>
        <Typography sx={{ marginBottom: "34px", maxWidth: "300px" }} variant="title-h2">
          ENTER THE SECURITY ONE-TIME CODE
        </Typography>
        <Box
          sx={{
            textAlign: "start",
            marginBottom: "24px",
            maxWidth: "372px",
            width: "100%",
          }}>
          <Box sx={{ marginBottom: "4px" }}>
            <Typography variant="body-sm" color="text.secondary">
              One-time security code
            </Typography>
          </Box>
          <StyledInputV2 key="otp" onChange={handleOnOTPChange} sx={{ textAlign: "start" }} />
          <Typography variant="body-sm" color="brand.primary">
            A one-time security code is sent to {email}.
          </Typography>
        </Box>
        <Box
          sx={{
            position: "relative",
            marginX: "auto",
            height: "56px",
            maxWidth: "372px",
            width: "100%",
            // paddingX: "12px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
            gap: "12px",
          }}>
          <RetroButton variant="white" onClick={handleGoBack}>
            <Box>
              <Typography variant="title-h3">GO BACK</Typography>
            </Box>
          </RetroButton>
          <RetroButton
            variant={isOtpValid && !isOtpVerifying ? "primary" : "disabled"}
            onClick={handleVerifyOTP}
            disabled={!isOtpValid || isOtpVerifying}>
            <Box sx={{ padding: "8px" }}>
              <Typography variant="title-h3">CONFIRM</Typography>
            </Box>
          </RetroButton>
        </Box>
        {tgUserId && tgUserName && variant !== "update" ? (
          <Typography
            sx={{ marginY: "20px" }}
            variant="pre-title-2"
            color="text.system-red"
            onClick={onClickLogout}>
            LOGOUT
          </Typography>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

const TYPOGRAPHY_BODY_MD = (theme: Theme) => ({
  ...theme.typography["body-default"],
});

const StyledInputV2 = styled("input")(({ theme }) => ({
  width: "100%",
  height: "46px",
  border: `none`,
  textAlign: "end",
  backgroundColor: theme.palette.surface.silver,
  borderRadius: "8px",
  padding: "0 12px",
  opacity: "0.5",
  color: theme.palette.text.primary,
  ...TYPOGRAPHY_BODY_MD(theme as Theme),
  "&:focus, &:not(:placeholder-shown)": {
    opacity: "1",
    outline: "none",
  },
  "&::placeholder": {
    color: theme.palette.text.primary,
  },
}));
