/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import axiosService from "services/axios";
import useLayoutConfigStore from "store/layout-config-store/useLayoutConfigStore";
import { useInViewInfiniteQuery } from "hooks/useInViewInfiniteQuery";
import { JoinedTokenCallsEmptyView } from "components/EmptyView";
import { Box, Typography, useTheme } from "@mui/material";
import { ScrollableBox } from "components/ScrollableBox";
import { useInfiniteQuery } from "@tanstack/react-query";
import { LoadingBlock } from "components/LoadingScreen";
import { LoadMoreRow } from "components/LoadMoreRow";
import { TokenCallRow } from "components/TokenCall";
import { RetroCard } from "components/RetroCard";

const JoinedTokenCalls = ({ data, isLoadingJoinedCabalCalls, ref, hasAllFetched }: any) => {
  if (isLoadingJoinedCabalCalls) {
    return (
      <LoadingBlock isLoading={isLoadingJoinedCabalCalls}>
        <Typography variant="title-h2">Loading...</Typography>
      </LoadingBlock>
    );
  }

  // If there are token calls
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}>
      {/* <TokenCallHeader /> */}
      <ScrollableBox>
        {data.pages
          .flatMap((page: any) => page.results)
          .map((tokenCall: any) => (
            <Box key={tokenCall._id}>
              <TokenCallRow tokenCall={tokenCall} />
            </Box>
          ))}
        {!hasAllFetched && <LoadMoreRow ref={ref} />}
      </ScrollableBox>
    </Box>
  );
};

const AlphaPage = () => {
  const theme = useTheme();
  const { isShowHeader, setIsShowHeader } = useLayoutConfigStore();

  const [page, setPage] = useState(1);

  const queryResult = useInfiniteQuery({
    queryKey: ["aplha-calls", { page }],
    queryFn: async ({ pageParam }) => {
      return axiosService.getJoinedCabalCalls({
        limit: 10,
        page: pageParam,
        field: "joinedCabals",
        selects: [
          "name",
          "symbol",
          "iconUri",
          "confidenceInterval",
          "createdAt",
          "updatedAt",
          "userId",
          "groupId",
          "chain",
          "cabal",
          "address",
        ],
      });
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.page + 1;
    },
    getPreviousPageParam: (firstPage, pages) => {
      return firstPage.page - 1;
    },
    initialPageParam: 1,
  });

  const { data, isLoading: isLoadingJoinedCabalCalls } = queryResult;
  const { ref, hasAllFetched } = useInViewInfiniteQuery(queryResult);

  // const [tab, setTab] = useState<"trending" | "joined-cabal">("trending");

  useEffect(() => {
    if (!isShowHeader) {
      setIsShowHeader(true);
    }
  }, []);

  if (
    !isLoadingJoinedCabalCalls &&
    (!data || !data.pages[0].results || data.pages[0].results.length === 0)
  ) {
    return <JoinedTokenCallsEmptyView />;
  }

  return (
    <RetroCard>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "20px 20px 20px",
          height: "100%",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
            height: "100%",
          }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}>
            <Typography variant="title-h3" color={theme.palette.text.primary}>
              ALPHA FROM YOUR GROUPS
            </Typography>
          </Box>
          <Box minHeight="12px" />
          <JoinedTokenCalls
            data={data}
            isLoadingJoinedCabalCalls={isLoadingJoinedCabalCalls}
            ref={ref}
            hasAllFetched={hasAllFetched}
          />
        </Box>
      </Box>
    </RetroCard>
  );
};

export { AlphaPage };
