import { Box, BoxProps, styled, Typography, useTheme } from "@mui/material";

export const ContentBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  background: theme.palette.surface.silver,
  border: `1px solid ${theme.palette.border.dark}`,
}));

export const InnerContentBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(1.5),
  background: theme.palette.surface.container,
  border: `1px solid ${theme.palette.border.dark}`,
}));

export const ContentCard = ({
  title,
  children,
  ...props
}: { title: string; children: React.ReactNode } & BoxProps) => {
  const theme = useTheme();
  return (
    <ContentBox {...props}>
      <Typography
        variant="pre-title"
        sx={{ textTransform: "uppercase" }}
        color={theme.palette.text.secondary}>
        {title}
      </Typography>
      {children}
    </ContentBox>
  );
};

export const TapsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  gap: "8px",
  padding: "8px",
  backgroundColor: theme.palette.surface.container,
  borderRadius: "16px",
  border: `1px solid ${theme.palette.border.dark}`,
}));

export const RowContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "16px",
  border: `1px solid ${theme.palette.border.dark}`,
  background: theme.palette.surface.silver,
  borderRadius: theme.spacing(1.5),
  gap: "4px",
  maxWidth: "100%",
}));
