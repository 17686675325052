import axios from "axios";
import { getAccessToken } from "./auth";
// import { getAccessToken, refreshAccessToken, onRefreshed, addRefreshSubscriber } from "./auth";

axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = getAccessToken();
    // console.debug("Request", config, token);
    if (token) {
      if (!config.headers) {
        config.headers = new axios.AxiosHeaders();
      }
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Immediately reject if no config or response
    if (!error.config || !error.response) {
      return Promise.reject(error);
    }

    // Handle 401 errors without retry
    if (error.response.status === 401) {
      window.localStorage.removeItem("accessToken");
      window.localStorage.removeItem("refreshToken");
      window.history.replaceState({}, document.title, window.location.pathname);
      window.location.href = window.location.href.split("?")[0];
      return Promise.reject(new Error("Authentication required"));
    }

    // Immediately reject all other errors
    return Promise.reject(error);
  },
);

export default axiosInstance;
