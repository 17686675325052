import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { Blocker } from "./Blocker";
import { LoginCheckBlocker } from "../LoginCheckBlocker";
import { useEmailAuth } from "hooks/useEmailAuth";
import { EmailCheckBlocker } from "../EmailCheckBlocker";
import { ROUTES } from "consts";
import { Navigate, useLocation } from "react-router-dom";

export const AuthCheckBlocker = ({
  children,
  withCard = false,
}: {
  children: React.ReactNode;
  withCard?: boolean;
}) => {
  const { tgUserId, tgUserName, email } = useUserStoreV2();
  const { credential } = useEmailAuth();
  const accessToken = localStorage.getItem("accessToken");
  console.debug(`AuthCheckBlocker A:`, !!accessToken);
  console.debug(`AuthCheckBlocker E:`, !!email);
  console.debug(`AuthCheckBlocker C:`, !credential.isSessionExpired);

  return (
    <Blocker
      withCard={withCard}
      blockerConfigs={[
        {
          check: !!tgUserId && !!tgUserName && !!accessToken,
          fallback: <LoginCheckBlocker />,
          excludePaths: [ROUTES.explorer],
        },
        {
          check: !!accessToken && !!email && !credential.isSessionExpired,
          fallback: <EmailCheckBlocker variant="init" />,
        },
        {
          check: !localStorage.getItem("cachedUrl"),
          fallback: <NavigateToCachedUrl excludedPaths={[ROUTES.gated, ROUTES.explorer]} />,
        },
      ]}>
      {children}
    </Blocker>
  );
};

const NavigateToCachedUrl = ({ excludedPaths }: { excludedPaths: string[] }) => {
  const location = useLocation();
  const cachedUrl = localStorage.getItem("cachedUrl");
  localStorage.removeItem("cachedUrl");

  if (excludedPaths.includes(location.pathname)) {
    return <Navigate to={location.pathname} />;
  }

  return <Navigate to={cachedUrl ?? ROUTES.explorer} />;
};
