import { Select, styled, Theme } from "@mui/material";

export const StyledSelect = styled(Select)<{ noPointer?: boolean }>(({ theme, noPointer }) => ({
  pointerEvents: noPointer ? "none" : "auto",
  borderRadius: theme.spacing(1.5),
  overflow: "hidden",

  ".MuiSelect-select": {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.surface.silver,
    pointerEvents: noPointer ? "none" : "auto",
    width: "100%",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${theme.palette.border.dark}`,
    borderRadius: theme.spacing(1.5),
  },

  "& .MuiSelect-icon": {
    color: theme.palette.text.secondary,
    fontSize: "24px",
  },

  "&:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.border.dark}`,
    },
  },
}));

const TYPOGRAPHY_BODY_MD = (theme: Theme) => ({
  ...theme.typography["body-md"],
});

export const StyledInputV2 = styled("input")(({ theme }) => ({
  width: "100%",
  height: "46px",
  border: `1.5px solid ${theme.palette.border.light}`,
  backgroundColor: theme.palette.surface.silver,
  borderRadius: "8px",
  padding: "0 12px",
  opacity: "0.5",
  color: theme.palette.text.primary,
  ...TYPOGRAPHY_BODY_MD(theme as Theme),
  "&:focus, &:not(:placeholder-shown)": {
    opacity: "1",
    outline: "none",
  },
  "&::placeholder": {
    color: theme.palette.text.primary,
  },
}));
