import { Box, Theme, Typography, useTheme } from "@mui/material";
import { RetroButton } from "components/RetroButton";
import { ReactComponent as ShareToFriendIcon } from "assets/icons/redeem-benefit-1.svg";
import { styled } from "@mui/material";
import { useNavigatePreserveQuery } from "lib/hooks/useNavigatePreserveQuery";
import { RetroCard } from "components/RetroCard";
import { useQuery } from "@tanstack/react-query";
import axiosService from "services/axios";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { useEffect } from "react";

interface ModeTip {
  content: string;
  commandBlocks?: {
    color: (theme: Theme) => string;
    bgColor: (theme: Theme) => string;
    command: string;
  }[];
}

const MODE_TIPS: ModeTip[] = [
  {
    commandBlocks: [
      {
        color: (theme) => theme.palette.text["system-green"],
        bgColor: (theme) => theme.palette.system["green"],
        command: "/call {address}",
      },
      {
        color: (theme) => theme.palette.text["system-red"],
        bgColor: (theme) => theme.palette.system["red"],
        command: "/exit {address}",
      },
    ],
    content:
      "Recommend and close recommendation of token in the group, and it will be factored into your performance.",
  },
  {
    commandBlocks: [
      {
        color: (theme) => theme.palette.text["btn-dark"],
        bgColor: (theme) => theme.palette.border["light"],
        command: "Paste an address, Dexscreener link, or symbol",
      },
    ],
    content: "Mention a token that will not count toward your performance.",
  },
  {
    content: "When your community trade on your alpha, you will receive 50% of their trading fees.",
  },
];

export const CreateCabalSuccessV2 = ({ cabalId }: { cabalId?: string }) => {
  const theme = useTheme();
  const navigate = useNavigatePreserveQuery();

  const { hasWallet } = useUserStoreV2();
  const { data: groupList, isLoading: isGroupListLoading } = useQuery({
    queryKey: ["check-free-group-list", hasWallet],
    queryFn: async () => {
      return axiosService.getAllCabals({ isActive: false });
    },
    enabled: !cabalId,
  });

  const navigateToCabalDetail = () => {
    navigate({
      pathname: `/cabal/${cabalId}`,
      search: "isShowBuyNowModal=true",
    });
  };

  const navigateToExplorerPage = () => {
    if (groupList?.length) {
      navigate({
        pathname: `/explorer`,
        search: "isShowListNowModal=true",
      });
    } else {
      navigate(`/explorer`);
    }
  };

  return (
    <RetroCard>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          height: "calc(100vh - 92px)",
          width: "100%",
          maxWidth: "762px",
          padding: "20px",
          gap: "16px",
          margin: "0 auto",
        }}>
        <Box sx={{ height: "24px" }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "16px",
            outline: "none",
          }}>
          <ShareToFriendIcon />
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
            }}>
            <Typography variant="title-h2">YOUR CABAL</Typography>
            <Typography variant="title-h2">STARTS NOW</Typography>
          </Box>
          <Typography variant="body-default" color="text.secondary" sx={{ textAlign: "center" }}>
            Learn these useful tips to start your journey at Cabal Town!
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}>
            {MODE_TIPS.map((tip, index) => (
              <ContentContainer>
                {tip.commandBlocks ? (
                  <Box sx={{ display: "flex", gap: "8px", width: "fit-content" }}>
                    {tip.commandBlocks.map((commandBlock) => (
                      <Box
                        sx={{
                          width: "fit-content",
                          padding: "4px",
                          borderRadius: "8px",
                          border: `1px solid ${theme.palette.border.dark}`,
                          backgroundColor: commandBlock.bgColor(theme),
                        }}>
                        <Typography variant="body-sm" color={commandBlock.color(theme)}>
                          {commandBlock.command}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                ) : null}
                <Typography variant="body-default" color="text.secondary">
                  {tip.content}
                </Typography>
              </ContentContainer>
            ))}
          </Box>
        </Box>
        {cabalId ? (
          <Box sx={{ width: "100%" }}>
            <RetroButton variant="primary" onClick={navigateToCabalDetail}>
              <Box sx={{ padding: "12px" }}>
                <Typography variant="title-h3">VIEW GROUP DETAIL</Typography>
              </Box>
            </RetroButton>
          </Box>
        ) : (
          <Box sx={{ width: "100%" }}>
            <RetroButton
              variant="primary"
              onClick={navigateToExplorerPage}
              disabled={isGroupListLoading}>
              <Box sx={{ padding: "12px" }}>
                <Typography
                  variant="title-h3"
                  color={isGroupListLoading ? "text.secondary" : "text.brand"}>
                  {isGroupListLoading ? "CHECKING..." : "DONE"}
                </Typography>
              </Box>
            </RetroButton>
          </Box>
        )}
      </Box>
    </RetroCard>
  );
};

const ContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "12px",
  borderRadius: "12px",
  border: `1px solid ${theme.palette.border.dark}`,
  backgroundColor: theme.palette.surface["container-bg"],
  gap: "8px",
}));
