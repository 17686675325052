import { Box, Button, Container, Typography, useTheme } from "@mui/material";
import { CreateCabalV2 } from "components/CreateCabal/CreateCabalV2";
import { useLocation, useNavigate } from "react-router-dom";
import FullPageWrapper from "components/FullPageWrapper";
import { ReactComponent as BackArrow } from "assets/icons/arrow-left.svg";
import { ROUTES } from "consts";
import { AuthCheckBlocker } from "components/Blockers";
import { CreateCabalMenu, GroupMode } from "components/CreateCabal/CreateCabalMenu";
import { useEffect, useState } from "react";
import { CreateCabalOnboarding } from "components/CreateCabal/CreateCabalOnboarding";
import { CreateCabalAddBot } from "components/CreateCabal/CreateCabalAddBot";
import { CreateCabalSuccessV2 } from "components/CreateCabal/createCabalModal";

type Step = "ON_BOARDING" | "MENU" | "ADD_BOT" | "CREATE" | "SUCCESS";

const STEPS: Record<Step, Step> = {
  ON_BOARDING: "ON_BOARDING",
  MENU: "MENU",
  ADD_BOT: "ADD_BOT",
  CREATE: "CREATE",
  SUCCESS: "SUCCESS",
};

const CreatePage = () => {
  const { search } = useLocation();
  const isListNow = search ? !!new URLSearchParams(search).get("isListNow") : false;

  const [step, setStep] = useState<Step>(
    isListNow
      ? STEPS.CREATE
      : localStorage.getItem("isSkipCreateCabalOnboard")
      ? STEPS.MENU
      : STEPS.ON_BOARDING,
  );
  const [groupMode, setGroupMode] = useState<GroupMode | null>(null);
  const [createdCabalId, setCreatedCabalId] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (step === STEPS.MENU) {
      setGroupMode(null);
    }
    if (step === STEPS.CREATE) {
      setCreatedCabalId(null);
    }
  }, [step]);

  const handleOnEndedOnboarding = () => {
    localStorage.setItem("isSkipCreateCabalOnboard", "true");
    setStep(STEPS.MENU);
  };

  const handleOnEndedMenu = (selectedGroupMode: GroupMode) => {
    if (selectedGroupMode === "free") {
      setGroupMode("free");
      setStep(STEPS.ADD_BOT);
    } else {
      setGroupMode("paid");
      setStep(STEPS.CREATE);
    }
  };

  const handleOnEndedAddBot = () => {
    setStep(STEPS.SUCCESS);
  };

  const handleOnEndedCreateCabal = (cabalId: string | null) => {
    setCreatedCabalId(cabalId);
    setStep(STEPS.SUCCESS);
  };

  const handleOnBackFromAddBot = () => {
    setStep(STEPS.MENU);
  };

  const handleOnBackFromCreate = () => {
    if (groupMode === "free") {
      setStep(STEPS.ADD_BOT);
    } else {
      setStep(STEPS.MENU);
    }
  };

  const handleOnSkipCreateFreeGroup = () => {
    navigate(ROUTES.explorer);
  };

  if (step === STEPS.ON_BOARDING) {
    return (
      <CreateCabalPageWrapper>
        <CreateCabalOnboarding onEndOnboarding={handleOnEndedOnboarding} />
      </CreateCabalPageWrapper>
    );
  }

  if (step === STEPS.MENU) {
    return (
      <CreateCabalPageWrapper>
        <CreateCabalMenu onNextPage={handleOnEndedMenu} />
      </CreateCabalPageWrapper>
    );
  }

  if (step === STEPS.ADD_BOT) {
    return (
      <CreateCabalPageWrapper>
        <CreateCabalAddBot
          onNextPage={handleOnEndedAddBot}
          onPreviousPage={handleOnBackFromAddBot}
        />
      </CreateCabalPageWrapper>
    );
  }

  if ((step === STEPS.CREATE && groupMode) || isListNow) {
    return (
      <CreateCabalPageWrapper>
        <CreateCabalV2
          mode={isListNow ? "free" : "paid"}
          onCabalCreated={handleOnEndedCreateCabal}
          onPreviousPage={handleOnBackFromCreate}
          handleOnSkip={handleOnSkipCreateFreeGroup}
        />
      </CreateCabalPageWrapper>
    );
  }

  if (step === STEPS.SUCCESS && groupMode) {
    if (createdCabalId) {
      return (
        <CreateCabalPageWrapper>
          <CreateCabalSuccessV2 cabalId={createdCabalId} />
        </CreateCabalPageWrapper>
      );
    } else {
      return (
        <CreateCabalPageWrapper>
          <CreateCabalSuccessV2 />
        </CreateCabalPageWrapper>
      );
    }
  }

  return null;
};

const CreateCabalPageWrapper = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { search, state: navStates } = useLocation();
  const isFromExplorer = new URLSearchParams(search).get("from") === "explorer";

  return (
    <AuthCheckBlocker withCard>
      <FullPageWrapper>
        <Container>
          <Box paddingY={theme.spacing(2.5)}>
            <Button
              sx={{ padding: "0", cursor: "pointer" }}
              onClick={() => navigate(isFromExplorer ? ROUTES.explorer : ROUTES.portfolio)}>
              <BackArrow />
              <Typography variant="button-md" color="text.secondary">
                {isFromExplorer ? "Groups" : "Portfolio"}
              </Typography>
            </Button>
          </Box>
        </Container>
        <Container
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
          }}>
          {children}
        </Container>
      </FullPageWrapper>
    </AuthCheckBlocker>
  );
};
export { CreatePage };
