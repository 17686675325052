import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { DrawerHeader, StyledDrawer } from "./styled";
import { Box, IconButton, Typography } from "@mui/material";
import { RetroButton } from "components/RetroButton";
import { RetroCard } from "components/RetroCard";
import { useState } from "react";
import { ReactComponent as BackArrow } from "assets/icons/arrow-left.svg";
import { EmailCheckBlocker } from "components/Blockers/EmailCheckBlocker";
import { useEmailAuth } from "hooks/useEmailAuth";

export const Profile = () => {
  // const hasLoadedEmailRef = useRef(false);
  // const currentUserEmailLoadingRef = useRef(false);
  // const { getCurrentUserEmail, authIframeClient } = usePasskey();
  const { deleteApiKeys } = useEmailAuth();
  // const [isOpenRecoverPassKeyMenu, setIsOpenRecoverPassKeyMenu] = useState(false);
  // const [currentUserEmail, setCurrentUserEmail] = useState<boolean>(false);
  // const [isLoadingCurrentUserEmail, setIsLoadingCurrentUserEmail] = useState(false);
  const { tgUserName, logoutTgUserV2, checkIsFromMiniApp } = useUserStoreV2();
  const [isOpenEmailAuthUpdateDrawer, setIsOpenEmailAuthUpdateDrawer] = useState(false);
  const isFromMiniApp = checkIsFromMiniApp();

  const handleOpenEmailAuthUpdateDrawer = () => {
    return setIsOpenEmailAuthUpdateDrawer(true);
  };

  const handleCloseEmailAuthUpdateDrawer = () => {
    return setIsOpenEmailAuthUpdateDrawer(false);
  };

  const onClickLogout = async () => {
    await deleteApiKeys();
    logoutTgUserV2();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            width: "100%",
            gap: "12px",
          }}>
          {/* <StyledUserIcon src={tgPhotoUrl} /> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: "4px",
            }}>
            <Typography variant="button-md">{tgUserName}</Typography>
            {!isFromMiniApp ? (
              <Typography variant="pre-title-2" color="text.system-red" onClick={onClickLogout}>
                LOGOUT
              </Typography>
            ) : null}
          </Box>
        </Box>
        <Box>
          <RetroButton
            onClick={handleOpenEmailAuthUpdateDrawer}
            // variant={isLoadingCurrentUserEmail ? "disabled" : "white"}
            variant="white"
            // disabled={isLoadingCurrentUserEmail}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "8px",
                gap: "8px",
                whiteSpace: "nowrap",
              }}>
              <VerifiedUserIcon fontSize="small" />
            </Box>
          </RetroButton>
        </Box>
      </Box>
      <EamilAuthUpdateDrawer
        isOpen={isOpenEmailAuthUpdateDrawer}
        close={handleCloseEmailAuthUpdateDrawer}
      />
      {/* {!isLoadingCurrentUserEmail ? (
        <RecoverPassKeyMenu
          isOpen={isOpenRecoverPassKeyMenu}
          close={handleCloseRecoverPassKeyMenu}
          hasEmail={currentUserEmail}
          openDrawerHandlers={{
            init: handleOpenRecoverPassKeyInitDrawer,
            update: handleOpenRecoverPassKeyUpdateDrawer,
            recovery: handleOpenRecoverPassKeyRecoveryDrawer,
          }}
        />
      ) : null} */}
      {/* <RecoverPassKeyInitDrawer
        isOpen={isOpenRecoverPassKeyInitDrawer}
        close={handleCloseRecoverPassKeyInitDrawer}
      />
      <RecoverPassKeyUpdateDrawer
        isOpen={isOpenRecoverPassKeyUpdateDrawer}
        close={handleCloseRecoverPassKeyUpdateDrawer}
      />
      <RecoverPassKeyRecoveryDrawer
        isOpen={isOpenRecoverPassKeyRecoveryDrawer}
        close={handleCloseRecoverPassKeyRecoveryDrawer}
      /> */}
    </>
  );
};

const EamilAuthUpdateDrawer = ({ isOpen, close }: { isOpen: boolean; close: () => void }) => {
  return (
    <StyledDrawer anchor={"bottom"} open={isOpen} onClose={close}>
      <DrawerHeader>
        <IconButton sx={{ padding: "0", cursor: "pointer" }} onClick={close}>
          <BackArrow />
          <Typography variant="button-md" color="text.secondary">
            Portfolio
          </Typography>
        </IconButton>
      </DrawerHeader>
      <Box sx={{ padding: "0 16px", height: "100%", width: "100%" }}>
        <RetroCard>
          <EmailCheckBlocker variant="update" onVerified={close} />
        </RetroCard>
      </Box>
    </StyledDrawer>
  );
};

// const RecoverPassKeyMenu = ({
//   isOpen,
//   close,
//   hasEmail,
//   openDrawerHandlers,
// }: {
//   isOpen: boolean;
//   close: () => void;
//   hasEmail: boolean;
//   openDrawerHandlers: Record<string, () => void>;
// }) => {
//   const theme = useTheme();

//   return (
//     <DarkDrawer open={isOpen} anchor="bottom" onClose={() => {}} sx={{ zIndex: 200 }}>
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           justifyContent: "center",
//           alignItems: "center",
//           width: "100%",
//           paddingBottom: "32px",
//         }}>
//         <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center", width: "100%" }}>
//           <Box>
//             <CircleButton variant="white" size="md" onClick={close}>
//               <Close fontSize="small" />
//             </CircleButton>
//           </Box>
//         </Box>
//         <Box>
//           <RecoveryPassKeyLogo width="72px" height="72px" />
//         </Box>
//         <Box maxWidth="180px" textAlign="center">
//           <Typography variant="title-h1">PASSKEY RECOVERY</Typography>
//         </Box>
//         <Box maxWidth="270px" textAlign="center" marginY="8px">
//           <Typography variant="body-md" color={theme.palette.text.secondary}>
//             Avoid losing access to your wallet by setting up passkey recovery.
//           </Typography>
//         </Box>
//         {hasEmail ? (
//           <>
//             <Box marginTop={theme.spacing(2)} width="100%">
//               <RetroButton onClick={openDrawerHandlers.update}>
//                 <Typography
//                   variant="title-h3"
//                   color="text.brand"
//                   sx={{ padding: theme.spacing(1) }}>
//                   EDIT RECOVERY EMAIL
//                 </Typography>
//               </RetroButton>
//             </Box>
//             <Box marginTop={theme.spacing(2)} width="100%">
//               <RetroButton onClick={openDrawerHandlers.recovery}>
//                 <Typography
//                   variant="title-h3"
//                   color="text.brand"
//                   sx={{ padding: theme.spacing(1) }}>
//                   RECOVER YOUR PASSKEY
//                 </Typography>
//               </RetroButton>
//             </Box>
//           </>
//         ) : (
//           <Box marginTop={theme.spacing(2)} width="100%">
//             <RetroButton onClick={openDrawerHandlers.init}>
//               <Typography variant="title-h3" color="text.brand" sx={{ padding: theme.spacing(1) }}>
//                 SET UP RECOVERY EMAIL
//               </Typography>
//             </RetroButton>
//           </Box>
//         )}
//       </Box>
//     </DarkDrawer>
//   );
// };

// const RecoverPassKeyInitDrawer = ({ isOpen, close }: { isOpen: boolean; close: () => void }) => {
//   const [isEmailInputValid, setIsEmailInputValid] = useState(false);
//   const [isRecoverPassKeyLoading, setIsRecoverPassKeyLoading] = useState(false);
//   const { initUserEmailRecovery } = usePasskey();
//   const emailRef = useRef<HTMLInputElement>(null);
//   const recoverPassKeyLoadingRef = useRef(false);
//   const { showToast } = useToastStore();

//   const onEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     if (e.target.value) {
//       setIsEmailInputValid(true);
//     } else {
//       setIsEmailInputValid(false);
//     }
//   };

//   const handleRecoverPassKey = async () => {
//     if (emailRef.current === null || recoverPassKeyLoadingRef.current) {
//       return;
//     }

//     try {
//       recoverPassKeyLoadingRef.current = true;
//       setIsRecoverPassKeyLoading(true);
//       const response = await initUserEmailRecovery(emailRef.current.value);
//       console.log("Recover passkey response", response);
//       showToast({
//         message: `Recovery email Inited: ${emailRef.current.value}`,
//         variant: "success",
//       });
//     } catch (error) {
//       console.error("Error while recovering passkey", error);
//     } finally {
//       recoverPassKeyLoadingRef.current = false;
//       setIsRecoverPassKeyLoading(false);
//       close();
//     }
//   };

//   const handleOnClose = () => {
//     setIsEmailInputValid(false);
//     setIsRecoverPassKeyLoading(false);
//     emailRef.current!.value = "";
//     close();
//   };

//   return (
//     <StyledDrawer anchor={"bottom"} open={isOpen} onClose={close}>
//       <DrawerHeader>
//         <IconButton sx={{ padding: "0", cursor: "pointer" }} onClick={handleOnClose}>
//           <BackArrow />
//           <Typography variant="button-md" color="text.secondary">
//             Portfolio
//           </Typography>
//         </IconButton>
//       </DrawerHeader>
//       <RetroCard>
//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "center",
//             alignItems: "center",
//             height: "100%",
//             width: "100%",
//             maxWidth: "372px",
//             padding: "16px",
//             textAlign: "center",
//             marginX: "auto",
//           }}>
//           <Box>
//             <RecoveryPassKeyLogo />
//           </Box>
//           <Box sx={{ marginBottom: "16px" }}>
//             <Typography variant="title-h2">SET UP RECOVERY EMAIL</Typography>
//           </Box>
//           <Box
//             sx={{
//               textAlign: "start",
//               marginBottom: "24px",
//               width: "100%",
//             }}>
//             <Box sx={{ marginBottom: "4px" }}>
//               <Typography variant="body-md" color="text.secondary">
//                 Email Address
//               </Typography>
//             </Box>
//             <StyledInputV2
//               ref={emailRef}
//               onChange={onEmailInputChange}
//               sx={{ textAlign: "start" }}
//             />
//           </Box>
//           <Box
//             sx={{
//               position: "relative",
//               marginX: "auto",
//               height: "56px",
//               maxWidth: "372px",
//               width: "100%",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               marginBottom: "24px",
//             }}>
//             <RetroButton
//               disabled={!isEmailInputValid && isRecoverPassKeyLoading}
//               onClick={handleRecoverPassKey}
//               variant={isEmailInputValid && !isRecoverPassKeyLoading ? "primary" : "disabled"}>
//               <Box sx={{ padding: "12px" }}>
//                 <Typography
//                   variant="title-h3"
//                   color={
//                     isEmailInputValid && !isRecoverPassKeyLoading ? "text.brand" : "text.secondary"
//                   }>
//                   ADD EMAIL FOR RECOVERY
//                 </Typography>
//               </Box>
//             </RetroButton>
//           </Box>
//         </Box>
//       </RetroCard>
//     </StyledDrawer>
//   );
// };

// const RecoverPassKeyUpdateDrawer = ({ isOpen, close }: { isOpen: boolean; close: () => void }) => {
//   const [isEmailInputValid, setIsEmailInputValid] = useState(false);
//   const [isRecoverPassKeyLoading, setIsRecoverPassKeyLoading] = useState(false);
//   const { updateUserEmailRecovery } = usePasskey();
//   const emailRef = useRef<HTMLInputElement>(null);
//   const recoverPassKeyLoadingRef = useRef(false);
//   const { showToast } = useToastStore();

//   const onEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     if (e.target.value) {
//       setIsEmailInputValid(true);
//     } else {
//       setIsEmailInputValid(false);
//     }
//   };

//   const handleRecoverPassKey = async () => {
//     if (emailRef.current === null || recoverPassKeyLoadingRef.current) {
//       return;
//     }

//     try {
//       recoverPassKeyLoadingRef.current = true;
//       setIsRecoverPassKeyLoading(true);
//       const response = await updateUserEmailRecovery(emailRef.current.value);
//       console.log("Recover passkey response", response);
//       showToast({
//         message: `Recovery email Updated: ${emailRef.current.value}`,
//         variant: "success",
//       });
//     } catch (error) {
//       console.error("Error while recovering passkey", error);
//     } finally {
//       recoverPassKeyLoadingRef.current = false;
//       setIsRecoverPassKeyLoading(false);
//       close();
//     }
//   };

//   const handleOnClose = () => {
//     setIsEmailInputValid(false);
//     setIsRecoverPassKeyLoading(false);
//     emailRef.current!.value = "";
//     close();
//   };

//   return (
//     <StyledDrawer anchor={"bottom"} open={isOpen} onClose={close}>
//       <DrawerHeader>
//         <IconButton sx={{ padding: "0", cursor: "pointer" }} onClick={handleOnClose}>
//           <BackArrow />
//           <Typography variant="button-md" color="text.secondary">
//             Portfolio
//           </Typography>
//         </IconButton>
//       </DrawerHeader>
//       <RetroCard>
//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "center",
//             alignItems: "center",
//             height: "100%",
//             width: "100%",
//             maxWidth: "372px",
//             padding: "16px",
//             textAlign: "center",
//             marginX: "auto",
//           }}>
//           <Box>
//             <RecoveryPassKeyLogo />
//           </Box>
//           <Box sx={{ marginBottom: "16px" }}>
//             <Typography variant="title-h2">EDIT RECOVERY EMAIL</Typography>
//           </Box>
//           <Box
//             sx={{
//               textAlign: "start",
//               marginBottom: "24px",
//               width: "100%",
//             }}>
//             <Box sx={{ marginBottom: "4px" }}>
//               <Typography variant="body-md" color="text.secondary">
//                 Email Address
//               </Typography>
//             </Box>
//             <StyledInputV2
//               ref={emailRef}
//               onChange={onEmailInputChange}
//               sx={{ textAlign: "start" }}
//             />
//           </Box>
//           <Box
//             sx={{
//               position: "relative",
//               marginX: "auto",
//               height: "56px",
//               maxWidth: "372px",
//               width: "100%",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               marginBottom: "24px",
//             }}>
//             <RetroButton
//               disabled={!isEmailInputValid && isRecoverPassKeyLoading}
//               onClick={handleRecoverPassKey}
//               variant={isEmailInputValid && !isRecoverPassKeyLoading ? "primary" : "disabled"}>
//               <Box sx={{ padding: "12px" }}>
//                 <Typography
//                   variant="title-h3"
//                   color={
//                     isEmailInputValid && !isRecoverPassKeyLoading ? "text.brand" : "text.secondary"
//                   }>
//                   CONFIRM
//                 </Typography>
//               </Box>
//             </RetroButton>
//           </Box>
//         </Box>
//       </RetroCard>
//     </StyledDrawer>
//   );
// };

// const RecoverPassKeyRecoveryDrawer = ({
//   isOpen,
//   close,
// }: {
//   isOpen: boolean;
//   close: () => void;
// }) => {
//   const [credentialBundle, setCredentialBundle] = useState<string | null>(null);
//   const [passKeyName, setPassKeyName] = useState<string | null>(null);
//   const [isVerifyPassKeyValid, setIsVerifyPassKeyValid] = useState(false);

//   const [isEmailInputValid, setIsEmailInputValid] = useState(false);
//   const [isRecoverPassKeyLoading, setIsRecoverPassKeyLoading] = useState(false);
//   const [isEmailSent, setIsEmailSent] = useState(false);
//   const { initUserEmailRecovery, verifyCredentialBundle } = usePasskey();
//   const [email, setEmail] = useState<string | null>(null);
//   const recoverPassKeyLoadingRef = useRef(false);
//   const { showToast } = useToastStore();

//   useEffect(() => {
//     setIsVerifyPassKeyValid(!!credentialBundle || !!passKeyName);
//   }, [credentialBundle, passKeyName]);

//   useEffect(() => {
//     setIsEmailInputValid(!!email);
//   }, [email]);

//   const onEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     if (e.target.value) {
//       setEmail(e.target.value);
//     } else {
//       setEmail(null);
//     }
//   };

//   const onCredentialBundleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     if (e.target.value) {
//       setCredentialBundle(e.target.value);
//     } else {
//       setCredentialBundle(null);
//     }
//   };

//   const onPassKeyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     if (e.target.value) {
//       setPassKeyName(e.target.value);
//     } else {
//       setPassKeyName(null);
//     }
//   };

//   const handleRecoverPassKey = async () => {
//     if (email === null || recoverPassKeyLoadingRef.current) {
//       return;
//     }

//     try {
//       recoverPassKeyLoadingRef.current = true;
//       setIsRecoverPassKeyLoading(true);
//       const response = await initUserEmailRecovery(email);
//       console.log("Recover passkey response", response);
//     } catch (error) {
//       console.error("Error while recovering passkey", error);
//     } finally {
//       recoverPassKeyLoadingRef.current = false;
//       setIsRecoverPassKeyLoading(false);
//       handleEmailSent();
//     }
//   };

//   const handleEmailSent = async () => {
//     setIsEmailSent(true);
//   };

//   const handleClose = () => {
//     setCredentialBundle(null);
//     setPassKeyName(null);
//     setEmail(null);
//     setIsEmailSent(false);
//     setIsRecoverPassKeyLoading(false);
//     setIsVerifyPassKeyValid(false);
//     close();
//   };

//   const handleVerifyPassKey = async () => {
//     const response = await verifyCredentialBundle(passKeyName!, credentialBundle!);
//     showToast({
//       message: response.message,
//       variant: response.variant as "success" | "error" | "info",
//     });
//     handleClose();
//   };

//   return (
//     <>
//       <StyledDrawer anchor={"bottom"} open={isOpen} onClose={close}>
//         <DrawerHeader>
//           <IconButton sx={{ padding: "0", cursor: "pointer" }} onClick={handleClose}>
//             <BackArrow />
//             <Typography variant="button-md" color="text.secondary">
//               Portfolio
//             </Typography>
//           </IconButton>
//         </DrawerHeader>
//         <RetroCard>
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "center",
//               alignItems: "center",
//               height: "100%",
//               width: "100%",
//               maxWidth: "372px",
//               padding: "16px",
//               textAlign: "center",
//               marginX: "auto",
//             }}>
//             <Box>
//               <RecoveryPassKeyLogo />
//             </Box>
//             {isEmailSent ? (
//               <>
//                 <Box sx={{ marginBottom: "16px" }}>
//                   <Typography variant="title-h2">RECOVERY PASSKEY</Typography>
//                 </Box>
//                 <Box
//                   sx={{
//                     textAlign: "start",
//                     marginBottom: "24px",
//                     width: "100%",
//                   }}>
//                   <Box sx={{ marginBottom: "4px" }}>
//                     <Typography variant="body-md" color="text.secondary">
//                       Recover Passkey
//                     </Typography>
//                   </Box>
//                   <StyledInputV2
//                     key="credentialBundle"
//                     onChange={onCredentialBundleChange}
//                     sx={{ textAlign: "start" }}
//                   />
//                 </Box>
//                 <Box
//                   sx={{
//                     textAlign: "start",
//                     marginBottom: "24px",
//                     width: "100%",
//                   }}>
//                   <Box sx={{ marginBottom: "4px" }}>
//                     <Typography variant="body-md" color="text.secondary">
//                       Enter new Passkey name
//                     </Typography>
//                   </Box>
//                   <StyledInputV2
//                     key="passKeyName"
//                     onChange={onPassKeyNameChange}
//                     sx={{ textAlign: "start" }}
//                   />
//                   <Box textAlign="center">
//                     <Typography variant="body-sm" color="brand.primary">
//                       A recovery code is sent to {email}
//                     </Typography>
//                   </Box>
//                 </Box>
//                 <Box></Box>
//                 <Box
//                   sx={{
//                     position: "relative",
//                     marginX: "auto",
//                     height: "56px",
//                     maxWidth: "372px",
//                     width: "100%",
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     marginBottom: "24px",
//                   }}>
//                   <RetroButton
//                     disabled={!isVerifyPassKeyValid && isRecoverPassKeyLoading}
//                     onClick={handleVerifyPassKey}
//                     variant={
//                       isVerifyPassKeyValid && !isRecoverPassKeyLoading ? "primary" : "disabled"
//                     }>
//                     <Box sx={{ padding: "12px" }}>
//                       <Typography
//                         variant="title-h3"
//                         color={
//                           isVerifyPassKeyValid && !isRecoverPassKeyLoading
//                             ? "text.brand"
//                             : "text.secondary"
//                         }>
//                         VERIFY/ADD PASSKEY
//                       </Typography>
//                     </Box>
//                   </RetroButton>
//                 </Box>
//               </>
//             ) : (
//               <>
//                 <Box sx={{ marginBottom: "16px" }}>
//                   <Typography variant="title-h2">ENTER YOUR RECOVERY EMAIL</Typography>
//                 </Box>
//                 <Box
//                   sx={{
//                     textAlign: "start",
//                     marginBottom: "24px",
//                     width: "100%",
//                   }}>
//                   <Box sx={{ marginBottom: "4px" }}>
//                     <Typography variant="body-md" color="text.secondary">
//                       Email Address
//                     </Typography>
//                   </Box>
//                   <StyledInputV2
//                     key="recoveryEmail"
//                     onChange={onEmailInputChange}
//                     sx={{ textAlign: "start" }}
//                   />
//                 </Box>
//                 <Box
//                   sx={{
//                     position: "relative",
//                     marginX: "auto",
//                     height: "56px",
//                     maxWidth: "372px",
//                     width: "100%",
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     marginBottom: "24px",
//                   }}>
//                   <RetroButton
//                     disabled={!isEmailInputValid}
//                     onClick={handleRecoverPassKey}
//                     variant={isEmailInputValid ? "primary" : "disabled"}>
//                     <Box sx={{ padding: "12px" }}>
//                       <Typography
//                         variant="title-h3"
//                         color={isEmailInputValid ? "text.brand" : "text.secondary"}>
//                         CONFIRM
//                       </Typography>
//                     </Box>
//                   </RetroButton>
//                 </Box>
//               </>
//             )}
//           </Box>
//         </RetroCard>
//       </StyledDrawer>
//     </>
//   );
// };
